import React, { useContext, useEffect, useState } from 'react'
import NavbarCom from '../../Components/Navbar'
import { StateStore } from '../../Context/StateProvider'
import { useFetcher } from 'react-router-dom'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js/auto";
const Sales = () => {
    const { getOrders, ordersDb, } = useContext(StateStore)
    let [totalAmount, setTotalAmount] = useState()
    let currentDate = new Date()
    let [particularyearData, setParticularYearData] = useState()
    let [yearWiseData, setYearWiseData] = useState()
    let [monthwiseData, setMonthWiseData] = useState({})
    useEffect(() => {
        getOrders()
    }, [])
    function groupByMonth(orders) {
        return orders.reduce((acc, order) => {
            const date = new Date(order.orderedTime);
            const month = date.toLocaleString('default', { month: 'long' }); // Get the full month name
            if (!acc[month]) {
                acc[month] = [];
            }
            acc[month].push(order);
            return acc;
        }, {});
    }
    // Getting the Particular year data
    let getPartyearData = (year) => {
        setParticularYearData([...ordersDb].filter((x) => {
            if (new Date(x.orderedTime).getFullYear() == year) {
                return x;
            }
        }));
    }
    //setting the Orders from the Data base
    useEffect(() => {
        if (ordersDb) {
            setTotalAmount([...ordersDb].reduce((sum, obj) => sum + obj.amountPaid, 0))
            getPartyearData(currentDate.getFullYear())
        }
    }, [ordersDb])
    //geting the sales for the Particular year whole month
    useEffect(() => {
        if (particularyearData) {
            let data = groupByMonth(particularyearData)
            setMonthWiseData(data)
        }
    }, [particularyearData])
    //Chart Value for the months of particular year
    let [yearChart, setyearChart] = useState()
    useEffect(() => {
        console.log(Object.values(monthwiseData));
        let arrayKey = Object.keys(monthwiseData)
        let arryValue = Object.values(monthwiseData).map((x) => x.reduce((sum, obj) => sum + obj.amountPaid, 0))
        console.log(arryValue);
        setyearChart({
            labels: [arrayKey],
            datasets: [
                {
                    label: 'Sales',
                    data: arryValue,
                    fill: false,
                    backgroundColor: 'rgb(105,105,105)',
                    tension: 1,
                    barThickness: 8,
                }
            ],
        })
    }, [monthwiseData])
    return (
        <div>
            <NavbarCom />
            <div className='container'>
                {totalAmount && <div>
                    Total Sales : {totalAmount} Rs
                </div>}
                {/* Year Wise */}
                <div className='flex items-center'>
                    <p className='text-xl my-0 fw-semibold'>Sales in the Year  </p>
                    <select onChange={(e)=>getPartyearData(e.target.value)} name="" className='p-2 outline-none' id="">
                        <option value={currentDate.getFullYear()}>{currentDate.getFullYear()} </option>
                        <option value={currentDate.getFullYear()-1}>{currentDate.getFullYear()-1} </option>
                        <option value={currentDate.getFullYear()-2}>{currentDate.getFullYear()-2} </option>
                        <option value={currentDate.getFullYear()-3}>{currentDate.getFullYear()-3} </option>
                        <option value={currentDate.getFullYear()-4}>{currentDate.getFullYear()-4} </option>
                    </select>
                </div>
                {yearChart &&

                    <Bar className='w-full' data={yearChart}></Bar>

                }
            </div>
        </div>
    )
}

export default Sales