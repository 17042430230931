import React, { useContext, useEffect, useState } from 'react'
import NavbarCom from '../../Components/Navbar'
import { useNavigate } from 'react-router'
import axios from 'axios'
import { hostName } from '../../App'
import { StateStore } from '../../Context/StateProvider'
import SearchIcon from '../../Svg_Icons/SearchIcon'
const Customers = () => {
    let navigate = useNavigate()
    let { userDb } = useContext(StateStore)
    let [subcriptionUser, setsubcription] = useState()
    useEffect(() => {
        axios.get(`${hostName}/api/subcription`).then((response) => {
            setsubcription(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }, [])
    return (
        <div>
            <NavbarCom />
            <div className='container mx-auto '>
                {/* Filteration */}
                <section className="ms-auto my-3 flex gap-3 w-fit ">
                    <div className="ms-auto w-fit p-2 px-3  border-1 border-slate-400 rounded-full">
                        <input type="text" className=" outline-none " placeholder="Search" />
                        <button>
                            <SearchIcon />
                        </button>
                    </div>
                    <select name="" className="p-2 outline-none border-1 px-3 border-slate-400 rounded-full " id="">
                        <option value=""> Select</option>
                        <option value="AZ">Sort by A-Z</option>
                        <option value="ZA">Sort by Z-A</option>
                    </select>
                </section>

                {userDb &&
                    <div className='container mx-auto'>
                        <div className='table-responsive max-h-[70vh] overflow-y-flow '>
                            <table className="min-w-[800px] w-full table-striped">
                                <tr>
                                    <th className="text-center py-2 w-fit border-1">SI.No </th>
                                    <th className="text-center w-fit border-1">User Name </th>
                                    <th className="text-center w-fit border-1">Email</th>
                                    <th className="text-center w-fit border-1">Phone</th>
                                    <th className="text-center w-fit border-1">Chat</th>

                                </tr>

                                {userDb.map((x, index) => {
                                    return (
                                        <tr className={`items-center w-full justify-between m-0  ${index % 2 == 0 ? "bg-slate-50" : "bg-slate-100"}`}>
                                            <td onClick={() => navigate(`/customer/${x._id}`)} className="hover:bg-slate-200 cursor-pointer text-center border-1"> {index + 1} </td>
                                            <td className="text-center border-1"> {x.username} </td>
                                            <td className="text-center border-1"> {x.email} </td>
                                            <td className="text-center border-1"> {x.phone} </td>
                                            <td className="text-center border-1">
                                                <a target='_blank' href={`https://wa.me/+91${x.phone}`}>
                                                    <img className='mx-auto' width={30} src={require('../../Assest/whatsapplogo.png')} alt="Whatsapp" /> </a>
                                            </td>
                                        </tr>)
                                })
                                }
                            </table>
                        </div>


                    </div>}
                <h4 className='my-3'>Subcription on the Website </h4>
                {
                    subcriptionUser &&
                    <div className='table-responsive  '>
                        <table className="min-w-[320px] table-striped">
                            <tr>
                                <th className="text-center w-fit border-1">SI No </th>
                                <th className="text-center w-fit border-1">Phone </th>
                                <th className="text-center w-fit border-1">Chat </th>
                            </tr>
                            {
                                subcriptionUser.map((x, index) => (
                                    <tr>
                                        <td className="text-center border-1"> {index + 1}</td>
                                        <td className="text-center border-1"> {x.phone}</td>
                                        <td className="text-center border-1">
                                            <a target='_blank' href={`https://wa.me/+91${x.phone}`}>
                                                <img className='mx-auto' width={30} src={require('../../Assest/whatsapplogo.png')} alt="Whatsapp" /> </a>
                                        </td>

                                    </tr>
                                ))
                            }

                        </table>

                    </div>
                }
            </div>
        </div>
    )
}

export default Customers
