import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { hostName } from '../../App'
import { StateStore } from '../../Context/StateProvider'
import DustBin from '../../Svg_Icons/DustBin'
import { upload } from '@testing-library/user-event/dist/upload'
import LeftArrow from '../../Svg_Icons/LeftArrow'

const ParticularCategoryDesPage = () => {
    const { id } = useParams()
    let [categoryDescrptionObject, setCategoryDescriptionObject] = useState({
        category: null,
        desktopimages: null,
        mobileimages: null,
    })
    let { getCategory, categoryDB } = useContext(StateStore)
    let getdescription = () => {
        if (id) {
            axios.get(`${hostName}/api/categoryDescription/${id}`).then((response) => {
                setCategoryDescriptionObject(response.data)
                console.log(response.data);
            }).catch((error) => {
                console.log(error);
            })
        }
    }
    useEffect(() => {
        getdescription()
        getCategory()
    }, [id])
    let handleDeleteImage = (type, url) => {
        axios.put(`${hostName}/api/categoryDescriptionImage`, {
            id: id,
            type,
            url
        }).then((response) => {
            getdescription()
        }).catch((error) => {
            console.log(error);
        })
    }
    let uploadImage = (file, type) => {
        const formdata = new FormData()
        formdata.append('image', file)
        formdata.append('id', id)
        formdata.append('type', type)
        axios.put(`${hostName}/api/categoryDescriptionImageAdding`, formdata).then((response) => {
            getdescription()
        }).catch((error) => {
            console.log(error);
        })
    }
    let navigate=useNavigate()
    return (
        <div className='container mx-auto'>
            {
                categoryDescrptionObject && <>
                <button onClick={()=>navigate('/features/categorydescription/')} className='p-2 rounded bg-slate-950 text-white ms-auto flex'><LeftArrow/> </button>
                    <p>Product Description of the {categoryDescrptionObject.category && categoryDescrptionObject.category.category} items</p>
                    <div>
                        Destop Images
                        <article className='flex flex-wrap gap-3 '>
                            {
                                categoryDescrptionObject.desktopimages && categoryDescrptionObject.desktopimages.map((img) => (
                                    <div className='w-40 relative h-40'>
                                        <button onClick={() => handleDeleteImage('desktop', img)} className='text-red-600 top-1 right-1 absolute'><DustBin /> </button>
                                        <img className='w-40 h-40 object-contain ' src={img} alt="Image" />
                                    </div>
                                ))
                            }
                            <label htmlFor='desktop' className='w-40 cursor-pointer bg-slate-500 bg-opacity-25 h-40'>
                                <img src={require('../../Assest/addimage.jpg')} className='w-40 m-auto ' alt="Upload image" />
                                <input onChange={(e) => uploadImage(e.target.files[0], 'desktop')} id='desktop' type="file" className='hidden' />
                            </label>
                        </article>
                    </div>
                    <div>
                        Mobile Images
                        <article className='flex flex-wrap gap-3 '>
                            {
                                categoryDescrptionObject.mobileimages && categoryDescrptionObject.mobileimages.map((img) => (
                                    <div className='w-40 relative h-40'>
                                        <button onClick={() => handleDeleteImage('mobile', img)} className='text-red-600 top-1 right-1 absolute'><DustBin /> </button>
                                        <img className='w-40 h-40 object-contain ' src={img} alt="Image" />
                                    </div>
                                ))
                            }
                            <label htmlFor='mobile' className='w-40 cursor-pointer bg-slate-500 bg-opacity-25 h-40'>
                                <img src={require('../../Assest/addimage.jpg')} className='w-40 m-auto ' alt="Upload image" />
                                <input onChange={(e) => uploadImage(e.target.files[0], 'mobile')} id='mobile' type="file" className='hidden' />
                            </label>
                        </article>
                    </div>
                </>
            }

        </div>
    )
}

export default ParticularCategoryDesPage