import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { hostName } from '../../App'
import NavbarCom from '../../Components/Navbar'
import OrderList from '../OrderList/OrderList'
import { StateStore } from '../../Context/StateProvider'
import CartProductDetails from './CartProductDetails'

const UserDetails = () => {
    let { downloadImage, getOrders } = useContext(StateStore)
    const { id } = useParams()
    let [user, setUser] = useState()
    let [addressarry, setAddressArry] = useState()
    let navigate = useNavigate()
    let [cartList, setCartList] = useState()
    let [orderList, setOrderList] = useState()

    let [show, setshow] = useState(false)
    let [cartProduct, setCartProduct] = useState()
    let getData = async () => {
        try {
            let userResponse = await axios.get(`${hostName}/api/userId/${id}`)
            let addressResponse = await axios.get(`${hostName}/api/userAddress/${id}`)
            let orderResponse = await axios.get(`${hostName}/api/order/${id}`)
            let cartListresponse = await axios.get(`${hostName}/api/cart/${id}`)
            setCartList(cartListresponse.data)
            setUser(userResponse.data)
            setAddressArry(addressResponse.data)
            setOrderList(orderResponse.data)
            console.log(cartListresponse.data);
        } catch (error) {
            console.log(error);
        }
    }
    let [selectedOrders, setSelectedOrders] = useState([])
    let handleOrders = (obj) => {
        console.log(selectedOrders.some((x) => x._id == obj._id));
        if (selectedOrders.some((x) => x._id == obj._id)) {
            setSelectedOrders((prev) => prev.filter((y) => y._id != obj._id))
        }
        else {
            setSelectedOrders((prev) => [...prev, obj])
        }
    }
    useEffect(() => {
        getData()
    }, [])
    let handleDowload = (e) => {
        selectedOrders.forEach((x) => {
            const productDetails = [...x.productDetails]
            productDetails.forEach((y, index1) => {
                if (y.previewImage) {
                    downloadImage(y.previewImage, `${x._id}_PreviewImage_${index1 + 1}`)
                }
                if (y.uploadedImage) {
                    y.uploadedImage.forEach((z, index2) => downloadImage(z, `${x._id}_Uploaded_${index1 + 1}_${index2 + 1}`))
                }
            })
        })
        axios.put(`${hostName}/api/orderStatus`, {
            id: selectedOrders.map((x) => x._id)
        }).then((response) => {
            console.log(response.data);
            getData()
            setSelectedOrders([])
        }).catch((error) => {
            console.log(error);
        })

    }
    return (
        <div>
            <NavbarCom />
            {/* User */}
            {user && <div className='container mx-auto '>
                <h4 className='text-center'>User Details </h4>

                <p>Name : <span>{user.username} </span> </p>
                <p>Email : <span>{user.email} </span> </p>
                <p>Phone : <span>{user.phone} </span> </p>
                <div className='flex flex-wrap gap-2 '>
                    Address :
                    {
                        addressarry && addressarry.map((x) => (
                            <div className='w-[20rem] '>
                                <p className='m-0 fw-semibold'>{x.fullName}</p>
                                <address className='m-0'>
                                    {x.line1 + ", " + x.line2 + ", " + x.state + ", " + x.district + ", "}
                                    <span className='block'>{x.country + " - " + x.pincode}</span>
                                </address>
                                <p className='m-0'>Phone no : <span> {x.phone} </span> </p>
                                <p>Default : <span>{x.defaultStatus ? 'Yes' : 'No'} </span> </p>
                            </div>
                        ))
                    }
                </div>
                {
                    orderList && <div>
                        <div className='flex justify-between my-3'>
                            <h4 className='text-2xl fw-semibold'>Order</h4>
                            {selectedOrders.length > 0 && <button onClick={handleDowload} className='p-2 px-3 bg-slate-500 rounded text-white '>
                                Download </button>}
                        </div>
                        <div className='table-responsive h-[40vh] overflow-y-scroll  '>
                            <table className="min-w-[800px] w-full table-striped">
                                <tr>
                                    <th className="text-center py-2 w-fit border-1">SI.No </th>
                                    <th className="text-center w-fit border-1">Order Id </th>
                                    <th className="text-center w-fit border-1">Amount Paid </th>
                                    <th className="text-center w-fit border-1">Ordered Date</th>
                                    <th className="text-center w-fit border-1">Coupon Used</th>

                                    <th className="text-center w-fit border-1"> Status </th>
                                    <th className="text-center w-fit border-1"> Chat </th>
                                    <th className="text-center w-fit border-1"> select </th>
                                </tr>

                                {[...orderList].reverse().map((x, index) => {
                                    console.log(x);
                                    const data = new Date()
                                    // data
                                    return (
                                        <tr className={`items-center w-full justify-between m-0  ${index % 2 == 0 ? "bg-slate-50" : "bg-slate-100"}`}>
                                            <td className="text-center border-1"> {index + 1} </td>
                                            <td onClick={() => navigate(`/orders/${x._id}`)} className="text-center hover:bg-slate-200 cursor-pointer border-1"> {x.orderId} </td>
                                            <td className="text-center border-1"> {x.amountPaid} </td>
                                            <td className="text-center border-1">  {new Date(x.orderedTime).toLocaleString()} </td>
                                            <td className="text-center border-1"> {x.couponUsed ? x.couponUsed.code : '--'} </td>

                                            <td className="text-center border-1"> {x.status} </td>
                                            <td className="text-center border-1"> <a target='_blank' className='' href={`https://wa.me/+91${x.shippingAddress.phone}`} >
                                                <img width={30} src={require('../../Assest/whatsapplogo.png')} className='mx-auto ' alt="WhatsappLogo" />   </a> </td>
                                            <td className="text-center border-1">
                                                <button onClick={() => handleOrders(x)}>
                                                    {selectedOrders.some((y) => y._id == x._id) ? <img src={require('../../Assest/check.png')} width={20} alt="Checked" /> : <img width={20} src={require('../../Assest/square.png')} alt="square" />}
                                                </button>
                                            </td>

                                        </tr>)
                                })
                                }
                            </table>
                        </div>



                    </div>
                }
                {/* CartList */}
                {
                    cartList && <div className='my-4'>
                        <h4 className='my-3 '>Cart products </h4>
                        <div className='table-responsive max-h-[40vh] overflow-y-scroll  '>
                            <table className="min-w-[800px] w-full table-striped">
                                <tr className='bg-slate-500 text-white'>
                                    <th className="text-center py-2 w-fit border-1">SI.No </th>
                                    <th className="text-center w-fit border-1">Product Name </th>
                                    <th className="text-center w-fit border-1">Product details </th>
                                    <th className="text-center w-fit border-1">Chat </th>
                                </tr>

                                {[...cartList].reverse().map((x, index) => {
                                    return (
                                        <tr className={`items-center w-full justify-between m-0  ${index % 2 == 0 ? "bg-slate-50" : "bg-slate-100"}`}>
                                            <td className="text-center border-1"> {index + 1} </td>
                                            <td className="text-center border-1"> {x.productId.productName} </td>
                                            <td className="text-center border-1"> <button onClick={() => { setshow(true); setCartProduct(x) }} className=' '>click here </button></td>
                                            <td className="text-center border-1"> <a target='_blank' className='' href={`https://wa.me/+91${user.phone}`} >
                                                <img width={30} src={require('../../Assest/whatsapplogo.png')} className='mx-auto ' alt="WhatsappLogo" />   </a> </td>
                                        </tr>)
                                })
                                }
                            </table>
                        </div></div>
                }
            </div>}
            {show && <CartProductDetails show={show} setShow={setshow} product={cartProduct} />}

        </div>
    )
}

export default UserDetails