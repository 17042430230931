import React, { useContext, useEffect, useState } from "react";
import NavbarCom from "../../Components/Navbar";
import { useNavigate } from "react-router";
import axios from "axios";
import { hostName } from "../../App";
import { StateStore } from "../../Context/StateProvider";
import Loading from "../../Components/Loading";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import SearchIcon from "../../Svg_Icons/SearchIcon";

const Allproduct = () => {
  const { allProductDb, getProduct, setAllProduct, fetchdata, apiCalling, setApi } = useContext(StateStore);
  const navigate = useNavigate();
  let [confirmationshow, setconfirmationshow] = useState(false)
  let [delteId, setdeleteId] = useState()
  let [filterproduct, setFilterProduct] = useState([])
  useEffect(() => {
    window.scrollTo(0, 0);
    if (allProductDb) {
      setFilterProduct(allProductDb)
    }
  }, [allProductDb]);
  let deleteProduct = () => {
    // alert(delteId)
    axios.delete(`${hostName}/api/product/${delteId}`).then((response) => {
      console.log(response.data);
      // alert('deleted successfull')
      setconfirmationshow(false)
      toast.success('Product Has been deleted Successfully', {
        theme: 'colored',
        position: 'top-center',
        autoClose: 5000
      })
      fetchdata();

      // window.location.reload();
    }).catch((error) => {
      console.log(error);
      setconfirmationshow(false)
      toast.error('Error Aquired', {
        theme: 'colored',
        position: 'top-center',
        autoClose: 5000
      })
    })
  }
  let sortingValue = (value) => {
    if (value === 'AZ') {
      let sortArry = filterproduct.sort((a, b) => a.productName.localeCompare(b.productName));
      setFilterProduct([...sortArry]);
    }
    if (value === 'ZA') {
      let sortArry = filterproduct.sort((a, b) => b.productName.localeCompare(a.productName));
      setFilterProduct([...sortArry]);
    }
    if (value === 'all') {
      fetchdata()
      setFilterProduct([...allProductDb]);
    }
  }
  let [search, setSearch] = useState('')
  let searchProduct = () => {
    if (search != '') {
      let word = search.toLowerCase()
      let filterarray = [...allProductDb].filter((x) => x.productName.toLowerCase().indexOf(word) != -1
        || (x.sku && x.sku.toLowerCase().indexOf(word) != -1))
      setFilterProduct(filterarray)
    }
    else {
      setFilterProduct(allProductDb)
    }

  }
  let handlecopy = (obj) => {
    axios.post(`${hostName}/api/Copyproduct`, obj).then((response) => {
      console.log(response.data);
      getProduct()
    }).catch((error) => {
      console.log(error);
    })
  }
  return (
    <div>
      <ToastContainer />
      <NavbarCom />

      {allProductDb !== undefined && filterproduct != undefined && apiCalling ? (
        <div className="container mx-auto">
          <button
            onClick={() => navigate("/product/addproduct")}
            className="p-2 px-3 rounded bg-violet-600 text-white mx-4 flex items-center gap-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-plus-lg"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
              />
            </svg>{" "}
            Add Product
          </button>
          {/* search button */}
          <section className="ms-auto flex gap-3 w-fit ">
            <div className="ms-auto w-fit p-2 px-3  border-1 border-slate-400 rounded-full">
              <input onKeyUp={(e) => {
                if (e.key == 'Enter') {
                  searchProduct()
                }
              }} type="text" value={search} onChange={(e) => setSearch(e.target.value)} className=" outline-none "
                placeholder="Search by name" />
              <button className="" onClick={searchProduct} >
                <SearchIcon />
              </button>
            </div>
            <select name="" onChange={(e) => sortingValue(e.target.value)} className="p-2 outline-none border-1 px-3 border-slate-400 rounded-full " id="">
              <option value="all"> Select</option>
              <option value="AZ">Sort by A-Z</option>
              <option value="ZA">Sort by Z-A</option>
            </select>
          </section>
          {/* Product details */}
          <div className="table-responsive w-full max-h-[70vh] overflow-y-auto mx-auto border-1 container my-4 py-2 ">
            <table className="w-full  min-w-[800px] table-striped">
              <tr>
                <th className="text-center py-2 ">Product Name </th>
                <th className="text-center py-2 ">SKU </th>

                <th className="text-center">Product Variety</th>
                <th className="text-center">Price</th>
                <th className="text-center">Quantity</th>
                <th className="text-center">Category</th>
                <th className="text-center w-fit ">Action</th>
              </tr>

              {[...filterproduct].map((x, index) => {
                console.log(x);
                return (
                  <tr className={`items-center w-full justify-between m-0  ${index % 2 == 0 ? "bg-slate-50" : "bg-slate-100"}`}>
                    <td className="text-center w-[200px] p-2 ">{x.productName}  </td>
                    <td className="text-center"> {x.sku} </td>

                    <td className="text-center"> {x.vatriety&&x.vatriety.variety} </td>
                    <td className="text-center"><input type="number" className="outline-none w-20 removearrow text-center bg-transparent" value={x.price} /></td>
                    <td className="text-center"><input type="number" className="outline-none w-20 removearrow text-center bg-transparent" value={x.quantity} /></td>
                    <td className="text-center"> {x.category} </td>
                    <td className="text-center w-fit">
                      <button onClick={()=>{
                        const { _id,sku, ...newObject } = x;
                        handlecopy(newObject)}} className="p-2 px-3 bg-blue-600 mx-2 text-white  rounded"> C </button>

                      <button onClick={() => navigate(`/product/${x._id}`)} className="p-2 px-3 bg-slate-600 text-white  rounded">Edit</button>
                      <button onClick={() => { setconfirmationshow(true); setdeleteId(x._id) }} className="p-2 px-3 text-white bg-red-600 rounded mx-2">Delete</button> </td>

                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      ) : (
        <Loading />
      )}
      {confirmationshow && <Modal className="p-3 " centered onHide={() => setconfirmationshow(false)} show={confirmationshow}>
        <Modal.Title className="p-5">

          Do you want to delete the Product ?
        </Modal.Title>
        <Modal.Body>
          <div className="flex justify-around">
            <button onClick={() => setconfirmationshow(false)} className="p-2 px-3 rounded bg-slate-600 text-white">Cancel</button>
            <button onClick={deleteProduct} className="p-2 px-3 bg-red-600 text-white rounded">Delete</button>
          </div>
        </Modal.Body>

      </Modal>}

    </div>
  );
};

export default Allproduct;
