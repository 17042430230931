import React from 'react'
import { mobileFrame } from '../App'

const PreviewModel = (props) => {
    const { modelData, templateData } = props
    console.log(modelData);
    console.log(templateData);
    
    return (
        <div className='py-10 flex items-center justify-center'>
            <div className={`${mobileFrame} relative overflow-hidden border-4 border-slate-900 rounded-3xl `}>
                {
                    modelData && <>

                        <img style={{
                            position: 'absolute',
                            left: `${modelData.x}px`,
                            top: `${modelData.y}px`,
                            zIndex:10
                        }}  
                            width={modelData.width} className=' '
                            src={modelData.image} alt="Camera Image" />

                    </>
                }
                {
                    templateData && <>
                    {/* Symbols and Icons Images */}

                        {
                            templateData.symbolImageData.map((x, index) => {
                                return (
                                    <img id={index} style={{
                                        position: 'absolute',
                                        left: `${x.x}px`,
                                        top: `${x.y}px`
                                    }}
                                        width={x.width} className=' '
                                        src={x.image} alt="Uploaded Image" />
                                )
                            })
                        }
                        {/* User Text Area */}
                        {templateData.textData.map((x) => {
                            return (
                                <div style={{
                                    position: 'absolute',
                                    left: `${x.x}px`,
                                    top: `${x.y}px`,
                                    width: `${x.width}px`,
                                    height: `${x.height}px`

                                }} className={`border-1 flex items-center justify-center border-slate-950`}>
                                    <p className=' p-0 m-0'>Text Here </p>
                                </div>
                            )
                        })}
                    </>
                }
            </div>
        </div>
    )
}

export default PreviewModel
