import axios from 'axios'
import React, { createContext, useEffect, useState } from 'react'
import { hostName } from '../App'
import { useNavigate } from 'react-router'

export const StateStore = createContext(null)
const StateProvider = (props) => {
  const user = "jbsgbawbi"
  let navigate = useNavigate()
  const [allProductDb, setAllProduct] = useState([])
  const [brandDB, setbrand] = useState()
  const [userDb, setUserDb] = useState()
  const [ordersDb, setordersDB] = useState()
  const [varietyDB, setvariety] = useState()
  const [categoryDB, setCategory] = useState()
  const [apiCalling, setApi] = useState(false)
  const [modelDb, setModelData] = useState()
  let [videoDB, setVideoDb] = useState()

  const [bestselllerDB, setbestsellerDB] = useState()
  let getModelDb = () => {
    axios.get(`${hostName}/api/mobileModel`).then((response) => {
      setModelData(response.data)
    }).catch((error) => {
      console.log(error);
    })
  }
  const getCategory=async()=>{
    try {
      const category = await axios.get(`${hostName}/api/category`);
      setCategory(category.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  const getProduct=async()=>{
    try {
      const response = await axios.get(`${hostName}/api/product`);
      setAllProduct(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  async function fetchdata() {
    try {
      const response = await axios.get(`${hostName}/api/product`);
      const category = await axios.get(`${hostName}/api/category`);
      const brand = await axios.get(`${hostName}/api/brand`);
      const variety = await axios.get(`${hostName}/api/variety`);
      const customer = await axios.get(`${hostName}/api/alluser`);
      setUserDb(customer.data);
      setbrand(brand.data)
      setAllProduct(response.data);
      setCategory(category.data);
      setApi(true)
      setvariety(variety.data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  useEffect(() => {
    setApi(false)
    window.scrollTo(0, 0)
    fetchdata();
    getOrders();
    getModelDb()
    getBestSellers()
  }, []);
  let getVideodb=()=>{
    axios.get(`${hostName}/api/video`).then((response) => {
      setVideoDb(response.data)
      console.log(response.data);
  }).catch((error) => {
      console.log(error);
  })
  }
  let getOrders = () => {
    axios.get(`${hostName}/api/order`).then((response) => {
      setordersDB(response.data)
    }).catch((error) => {
      console.log(error);
    })
  }
  let logout = () => {
    sessionStorage.removeItem('admin')
    window.location.reload()
  }
  let [templatesDB, setTemplatesDB] = useState()
  let getTemplates = () => {
    axios.get(`${hostName}/api/template`).then((response) => {
      setTemplatesDB(response.data)
      console.log(response.data);
    }).catch((error) => {
      console.log(error);
    })
  }
  const downloadImage = (imageUrl,oid) => {
    const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1); // Extract filename from URL
    fetch(imageUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${oid}.jpg`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(error => {
        console.error('There was an error downloading the image:', error);
      });
  };
  let getBestSellers = () => {
    axios.get(`${hostName}/api/bestseller/true`).then((response) => {
      setbestsellerDB(response.data)
    }).catch((error) => {
      console.log(error);
    })
  }
  function getFormattedDateTime(currentDate) {
    var dayOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][currentDate.getDay()];
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var monthName = monthNames[currentDate.getMonth()];
    var day = currentDate.getDate();
    var year = currentDate.getFullYear();
    var hours = currentDate.getHours() > 12 ? currentDate.getHours() - 12 : currentDate.getHours();
    var minutes = currentDate.getMinutes();
    var ampm = currentDate.getHours() >= 12 ? 'pm' : 'am';
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var formattedDateTime = monthName + " " + day + " " + year + " " + dayOfWeek + ", " + hours + ":" + minutes + ampm;
    return formattedDateTime;
  }
  let [backgroundImage, setBackgroundImage] = useState()
  let getBackground = () => {
    axios.get(`${hostName}/api/background`).then((response) => {
      setBackgroundImage(response.data)
    }).catch((error) => {
      console.log(error);
    })
  }
  const adminStorage = {videoDB,setVideoDb,getVideodb, getCategory,getProduct, getFormattedDateTime, getOrders, backgroundImage,getBackground, ordersDb, downloadImage, bestselllerDB, setbestsellerDB, 
    getBestSellers, getModelDb, varietyDB, templatesDB, userDb, setTemplatesDB, getTemplates, user, brandDB, modelDb, logout, categoryDB, fetchdata, apiCalling, setApi, allProductDb, setAllProduct }
  return (
    <StateStore.Provider value={adminStorage}>
      {props.children}
    </StateStore.Provider>
  )
}

export default StateProvider