import React from 'react'
import { Modal } from 'react-bootstrap'

const CartProductDetails = (props) => {
    const { show, setShow, product } = props
    console.log(product);
    const downloadImage = (imageUrl) => {
        const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1); // Extract filename from URL
        fetch(imageUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(error => {
                console.error('There was an error downloading the image:', error);
            });
    };
    
    return (
        <Modal show={show} size='xl' className='poppins' centered onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                Details of the Order
            </Modal.Header>
            <Modal.Body>
                {product ? <div className='row container mx-auto'>
                    <section className='col-lg-6 border-e-slate-500 text-center border-e-2   ' >
                        <p className='text-2xl'>Privew Image : </p>
                        <div className='flex justify-between flex-wrap'>

                        {
                            product.previewImage && product.previewImage.map((img)=>(
                                <a href={img} download='image.png'>
                                <img width={200} src={img} className='mx-auto' alt="Priview Image" />
                                </a>
                            ))
                        }
                        </div>
                       
                    </section>
                    <section className='col-lg-6'>
                        <div className=''>
                            Uploaded Image : 
                            (click on the to download)
                             {
                                product.uploadedImage && product.uploadedImage.map((x)=>{
                                    return(
                                        <img onClick={()=>downloadImage(x)} width={90} className='cursor-pointer my-3' src={x} alt="Uplaoded image" />
                                    )
                                })
                             }   
                        </div>
                        <div>
                            {
                                product.textDetails && product.textDetails.map((x)=>(
                                    <div>
                                        
                                    </div>
                                ))
                            }
                        </div>

                    </section>

                </div> : <div>
                    Loading.....
                </div>}

            </Modal.Body>

        </Modal>
    )
}

export default CartProductDetails
