import React, { useContext, useEffect, useState } from 'react'
import { StateStore } from '../Context/StateProvider'
import PlusIcon from '../Svg_Icons/PlusIcon'
import axios from 'axios'
import { hostName } from '../App'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'

const BestSellersProducts = ({ type }) => {
    const { bestselllerDB, setbestsellerDB, getBestSellers } = useContext(StateStore)
    let navigate = useNavigate()
    const [nonbs, setnonBS] = useState([])
    const [id, setid] = useState()

    const removeBestseller = (id) => {
        axios.put(`${hostName}/api/bestseller/${id}`).then((response) => {
            getBestSellers()
            toast.error('Product has been removed from the BestSeller', {
                theme: 'colored',
                autoClose: 3000,
                position: 'top-center'
            })
        }).catch((error) => {
            console.log(error);
        })

    }
    useEffect(() => {
        getBestSellers()
    }, [])
    return (
        <div>
            <>
                <h4>Best Sellers </h4>
                <div className={` flex gap-3 ${bestselllerDB && bestselllerDB.length > 0 && 'justify-between'} flex-wrap `}>

                    {
                        bestselllerDB && [...bestselllerDB].map((x, index) => {
                            console.log(x);
                            return (
                                <div className={` w-[11rem] ${type == 'feature' && index > 3 ? "d-none " : ''} h-[16rem] sm:w-[12rem] sm:h-[15rem] border-1 relative rounded `} key={index}>
                                    {bestselllerDB.length > 5 && <button onClick={() => removeBestseller(x._id)} className={` absolute rotate-45 text-red-500 border-1 border-red-500 rounded-full top-2 right-2 `}>
                                        <PlusIcon />  </button>}
                                    <img src={x.mainImage} className='h-[12rem] object-contain mx-auto rounded' alt="Best Sellers" />
                                    <p className='text-center m-auto mb-0 '>{x.productName} </p>
                                </div>
                            )
                        })
                    }
                    {bestselllerDB && bestselllerDB.length > 4 &&
                        type == 'feature' &&
                        <button onClick={() => navigate('/features/bestseller')} className={` w-[11rem] flex items-center justify-center text-xl bg-slate-500 bg-opacity-30 relative border-1 shadow h-[11rem] sm:w-[13rem] sm:h-[13rem]`}>
                            See More

                        </button>}
                </div>
            </>
        </div>
    )
}

export default BestSellersProducts