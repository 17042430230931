import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { hostName } from '../App'
import PlusIcon from '../Svg_Icons/PlusIcon'
import { StateStore } from '../Context/StateProvider'
import { useNavigate } from 'react-router'

const CategoryDesCategory = () => {

    let [categorydesarry, setCategoryArray] = useState([])
    let { getCategory, categoryDB } = useContext(StateStore)

    let getAllCategoryDes = () => {
        axios.get(`${hostName}/api/categoryDescription`).then((response) => {
            setCategoryArray(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        getAllCategoryDes()
        getCategory()
    }, [])
    let navigate=useNavigate()
    return (
        <div>
            {
                categorydesarry && categorydesarry.map((obj, index) => {
                    console.log(obj);
                    return (
                        <section onClick={()=>navigate(`/features/categorydescription/${obj._id}`) } className='border-1 rounded w-fit'>
                            {obj.desktopimages&& <img loading='lazy' src={obj.desktopimages[0]} className='w-[15rem] ' alt="Main image" />}
                           <p className='text-center mb-0'> {obj.category.category}</p>
                        </section >
                    )
                })
            }

        </div >
    )
}

export default CategoryDesCategory