import React, { useContext, useState } from 'react'
import { Container, Nav, NavDropdown, Navbar, Offcanvas } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { StateStore } from '../Context/StateProvider'
import TemplateIcon from '../Svg_Icons/TemplateIcon'
import CartList from '../Svg_Icons/CartList'
import Shipment from '../Svg_Icons/Shipment'
import BellIcon from '../Svg_Icons/BellIcon'
import Notification from './Notification'
const NavbarCom = () => {
  let expand = false
  let { logout } = useContext(StateStore)
  let navigate = useNavigate()
  let [showSubNav, setSubNav] = useState(false)
  let [plus, setplus] = useState(false)
  let [setting, setSetting] = useState(false)
  let [show, setshow] = useState(false)
  let [notificationCount, setNotificationCount] = useState(0)
  return (
    <div className='bg-white sticky-top '>
      <Navbar key={expand} expand={expand} className="mb-3 min-h-[10vh] my-auto px-xl-3">
        <Container fluid>
          <div className='flex items-center gap-3'>

            <Navbar.Toggle className=' p-1 ' aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title className='px-3 cursor-pointer' onClick={() => navigate('/')} id={`offcanvasNavbarLabel-expand-${expand}`}>
                  DashBoard
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <button onClick={() => navigate('/')} className=' p-3 rounded hover:bg-slate-100  d-flex items-center gap-2'
                    href="#action1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door-fill" viewBox="0 0 16 16">
                      <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5" />
                    </svg> Dashboard</button>

                  <button onClick={() => { setSubNav(!showSubNav) }}
                    className={`d-flex hover:bg-slate-100  items-center rounded gap-2 p-3  ${showSubNav ? "bg-violet-600 hover:bg-violet-600 text-slate-50 " : ""} `}
                    href="#action1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class={`bi bi-shop`} viewBox="0 0 16 16">
                      <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.37 2.37 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0M1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5M4 15h3v-5H4zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zm3 0h-2v3h2z" />
                    </svg>Products</button>
                  <div className={`${showSubNav ? " d-block w-full" : "d-none"}  `}>
                    <button onClick={() => navigate('/product/')} className='bg-slate-50 w-full p-3  text-start hover:bg-slate-100 '>All Products</button>
                    <button onClick={() => navigate('/product/addproduct')} className='bg-slate-50 w-full p-3 text-start hover:bg-slate-100 '>Create Product</button>
                    <button onClick={() => navigate('/product/createModel')} className='bg-slate-50 w-full p-3 text-start hover:bg-slate-100 '>Create Model</button>
                    <button onClick={() => navigate('/product/variety')} className='bg-slate-50 w-full p-3 text-start hover:bg-slate-100 '>Create Variety</button>
                    {/* <button onClick={()=>navigate('/createCategory')} className='bg-slate-50 w-full p-3 text-start hover:bg-slate-100 '>Create Category</button>
                        <button onClick={()=>navigate('/createBrand')} className='bg-slate-50 w-full p-3 text-start hover:bg-slate-100 '>Create Brand</button> */}
                  </div>
                  <button onClick={() => navigate('/orders')}
                    className={`d-flex hover:bg-slate-100  items-center rounded gap-2 p-3 `}
                    href="#action1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart" viewBox="0 0 16 16">
                      <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                    </svg>Orders List</button>
                  {/* <button onClick={() => { navigate('/template'); }}
                    className={`d-flex hover:bg-slate-100  items-center rounded gap-2 p-3 `}
                    ><TemplateIcon/> Template</button> */}
                  <button onClick={() => navigate('/customer')}
                    className={`d-flex hover:bg-slate-100  items-center rounded gap-2 p-3 `}
                    href="#action1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                      <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                    </svg>Customers & Order</button>
                  <button onClick={() => { navigate('/createCategory'); }}
                    className={`d-flex hover:bg-slate-100  items-center rounded gap-2 p-3 `}
                    href="#action1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grid" viewBox="0 0 16 16">
                      <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z" />
                    </svg>Category</button>
                  <button onClick={() => { navigate('/features'); }}
                    className={`d-flex hover:bg-slate-100  items-center rounded gap-2 p-3 `}
                   >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stars" viewBox="0 0 16 16">
                      <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z" />
                    </svg> Features
                  </button>

                  <button onClick={() => { navigate('/createBrand'); }}
                    className={`d-flex hover:bg-slate-100  items-center rounded gap-2 p-3 `}
                    href="#action1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tag" viewBox="0 0 16 16">
                      <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0" />
                      <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1m0 5.586 7 7L13.586 9l-7-7H2z" />
                    </svg>Brand</button>
                  <button onClick={() => navigate('/cartList')} className={`d-flex hover:bg-slate-100  items-center rounded gap-2 p-3 `}>
                    <CartList />
                    Cart List
                  </button>
                  <button onClick={() => navigate('/shiprocket')}
                    className={`d-flex hover:bg-slate-100  items-center rounded gap-2 p-3 `}
                  ><Shipment /> ShipRocket
                  </button>
                  {/* <button
                    className={`d-flex hover:bg-slate-100  items-center rounded gap-2 p-3 `}
                    href="#action1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi  bi-person" viewBox="0 0 16 16">
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                    </svg>Edit Profile
                  </button> */}

                  <button onClick={() => {
                    logout()
                  }} className={`d-flex hover:bg-slate-100  items-center rounded gap-2 p-3 `}
                    href="#action1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z" />
                      <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
                    </svg>Log out</button>
                </Nav>

              </Offcanvas.Body>
            </Navbar.Offcanvas>

            {/* <div onClick={() => { setSetting(!setting); setplus(false) }} className='relative ' >
              <button className='d-none d-sm-block '>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
                  <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0" />
                  <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z" />
                </svg></button>
              <div className={`${setting ? "d-block" : "d-none"} position-absolute rounded-lg border-1 p-2 w-44 right-0 mt-3   `}>
                <button className=' p-2 hover:bg-slate-50 transition duration-300 hover:text-violet-600  w-full text-start rounded '>Edit Profile</button>
                <button className=' p-2 hover:bg-slate-50 transition duration-300 hover:text-violet-600  w-full text-start rounded '>Log out </button>
              </div>
            </div> */}

            <div className='relative ' >
              <button onClick={() => { setplus(!plus); setSetting(false) }} className='p-2 d-none d-sm-block border-1 rounded '>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                </svg></button>
              <div className={`${plus ? "d-block" : "d-none"} bg-white z-10 position-absolute rounded-lg border-1 p-2 w-44 left-0 m-1 `}>
                <button onClick={() => { navigate('/product/addproduct'); setplus(false) }} className=' p-2 hover:bg-slate-50 transition duration-300 hover:text-violet-600  w-full text-start rounded '>Add Product </button>
                <button onClick={() => { navigate('/product/createModel'); setplus(false) }} className=' p-2 hover:bg-slate-50 transition duration-300 hover:text-violet-600  w-full text-start rounded '>Add Model </button>

                <button onClick={() => { navigate('/createCategory'); setplus(false) }} className=' p-2 hover:bg-slate-50 transition duration-300 hover:text-violet-600  w-full text-start rounded '>Add Category </button>
                <button onClick={() => { navigate('/createBrand'); setplus(false) }} className=' p-2 hover:bg-slate-50 transition duration-300 hover:text-violet-600  w-full text-start rounded'>Add Brand </button>
              </div>
            </div>
            {/* Notification */}
            <button onClick={() => setshow(true)} className='p-2 relative border-1 rounded '>
              {notificationCount > 0 && <p className='badge absolute bg-red-500 -top-2 '>{notificationCount} </p>}
              <BellIcon size={20} />
            </button>
          </div>
          <Navbar.Brand onClick={() => navigate('/')} className='poppins cursor-pointer'>DashBoard</Navbar.Brand>

        </Container>

      </Navbar>
      <Notification setcount={setNotificationCount} show={show} setshow={setshow} />
    </div>
  )
}

export default NavbarCom