import React, { useEffect, useState } from 'react'
import NavbarCom from '../../Components/Navbar'
import BannerModel from './NewBanner'
import axios from 'axios'
import { hostName } from '../../App'
import { useNavigate } from 'react-router'
import BestSellersProducts from '../../Components/BestSellersProducts'
import BackgroundCom from '../../Components/BackgroundCom'
import VideoComponent from '../../Components/VideoComponent'
import CategoryDesCategory from '../../Components/CategoryDesCategory'

const AddingBanner = () => {
  let navigate = useNavigate()
  let [banner, setBanners] = useState()
  let [coupons, setcoupons] = useState()
  let getBanner = () => {
    axios.get(`${hostName}/api/banner`).then((response) => {
      // console.log(response.data);
      setBanners(response.data)
    }).catch((error) => {
      console.log(error);
    })
  }
  let getCoupons = () => {
    axios.get(`${hostName}/api/coupon`).then((response) => {
      console.log(response.data);
      setcoupons(response.data)
    }).catch((error) => {
      console.log(error);
    })
  }
  useEffect(() => {
    getBanner()
    getCoupons()
  }, [])
  return (
    <div className='container mx-auto poppins'>
      {/* Home banner */}
      <h4> Banners</h4>

      {/* Banner card */}
      <div className='flex flex-wrap gap-3 '>

        {banner && banner.map((x) => {
          return (

            <div onClick={() => navigate(`/features/banner/${x._id}`)} className='w-[10rem] h-[10rem] border-1 cursor-pointer my-2'>
              <img src={x.imageUrl} className='object-cover h-[8rem] ' alt="Banners" />
              <p className=' text-center'>
                {x.bannerName} page
              </p>
            </div>
          )
        })}
      </div>
      <button onClick={() => navigate('/features/banner/')} className='p-2 px-3 rounded bg-slate-500 text-white'>Add the Banner</button>
      <hr />
      {/* Coupons */}
      <div className='my-2'>
        <h4>Coupons</h4>
        {/* Banner card */}
        <div className='flex flex-wrap gap-3 '>
          {coupons && coupons.map((x) => {
            return (

              <div onClick={() => navigate(`/features/coupon/${x._id}`)} className='w-[10rem] rounded h-[10rem] relative border-1 cursor-pointer my-2'>
                <p className={`${x.activate ? 'bg-green-600' : 'bg-red-600'}  absolute top-3 right-3 w-3 h-3 rounded-full  `}></p>
                <img src={x.imageUrl} className='object-cover rounded-t h-[8rem] ' alt="Banners" />
                <p className=' text-center'>
                  {x.code}
                </p>
              </div>
            )
          })}
        </div>
        <button onClick={() => navigate('/features/coupon')} className='p-2 px-3 rounded bg-slate-500 text-white'>Add the Coupons</button>
        <hr />
      </div>
      {/* Best Sellers */}
      <div>
        <BestSellersProducts type='feature' />


        <button onClick={() => navigate('/features/bestseller')} className='p-2 px-3 my-3 rounded bg-slate-500 text-white'>
          Add the BestSeller</button>

        <hr />
      </div>

      <div>
        <h4>Video Upload </h4>

        <VideoComponent type='front' />
        <button className='p-2 px-3 my-3 rounded bg-slate-500 text-white'
          onClick={() => navigate('/features/videoUpload')} >
          Add the Video
        </button>
      </div>
      {/* Adding the description */}
      <hr />
      <section>
        <h4>Product Description based on the Category</h4> 
        <CategoryDesCategory/>
        <button className='p-2 px-3 my-3 rounded bg-slate-500 text-white'
          onClick={() => navigate('/features/categorydescription')} >
          Add the Images
        </button>
      </section>
       {/* Workflow */}
       <hr />
      <section>
        <h4>Create the Workflow </h4> 
        <CategoryDesCategory/>
        <button className='p-2 px-3 my-3 rounded bg-slate-500 text-white'
          onClick={() => navigate('/features/workflow')} >
          Make the Flow
        </button>
      </section>
    </div>
  )
}

export default AddingBanner