import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import PlusIcon from '../../Svg_Icons/PlusIcon'
import axios from 'axios'
import { hostName } from '../../App'
import { Modal } from 'react-bootstrap'
import { StateStore } from '../../Context/StateProvider'

const ViewTemplates = () => {
    let navigate = useNavigate()
    let [confirmationshow,setconfirmationshow]=useState(false)
    let {templatesDB, setTemplatesDB,getTemplates}=useContext(StateStore)
    useEffect(() => {
        getTemplates()
    }, [])
    return (
        <div>
            <button
                onClick={() => navigate("/layout")}
                className="p-2 px-3 rounded bg-violet-600 text-white mx-4 flex items-center gap-3"
            >  <PlusIcon />
                {" "}
                Create Template
            </button>
            {templatesDB != undefined && <div className="table-responsive w-full mx-auto border-1 container my-4 py-2 ">
                <table className=" w-full table-striped">
                    <tr>
                        <th className="text-center py-2 ">Template Name </th>
                        <th className="text-center">Template Id</th>
                        {/* <th className="text-center">Price</th> */}
                        {/* <th className="text-center">Quantity</th> */}
                        {/* <th className="text-center">Category</th> */}
                        <th className="text-center">Layout Name</th>
                        <th className="text-center w-fit ">Action</th>
                    </tr>

                    {templatesDB.map((x, index) => {
                        return (
                            <tr className={`items-center w-full justify-between m-0  ${index % 2 == 0 ? "bg-slate-50" : "bg-slate-100"}`}>
                                <td className="text-center"> {x.templateName} </td>
                                <td className="text-center"> {x._id} </td>
                                {/* <td className="text-center"><input type="number" className="outline-none w-20 removearrow text-center bg-transparent" value={x.price} /></td> */}
                                {/* <td className="text-center"><input type="number" className="outline-none w-20 removearrow text-center bg-transparent" value={x.quantity} /></td> */}
                                {/* <td className="text-center"> {x.category} </td> */}
                                <td className="text-center"> {x.layout} </td>

                                <td className="text-center w-fit">
                                    <button onClick={() => navigate(`/template/edit/${x._id}`)} className="p-2 px-3 bg-slate-600 text-white  rounded">Edit</button>
                                    {/* <button onClick={() => setconfirmationshow(true)} className="p-2 px-3 text-white bg-red-600 rounded mx-2">Delete</button>  */}
                                    </td>
                                <Modal className="p-3 " centered onHide={() => setconfirmationshow(false)} show={confirmationshow}>
                                    <Modal.Title className="p-5">

                                        Do you want to delete the Product ?
                                    </Modal.Title>
                                    <Modal.Body>
                                        <div className="flex justify-around">
                                            {/* <button onClick={() => setconfirmationshow(false)} className="p-2 px-3 rounded bg-slate-600 text-white">Cancel</button> */}
                                            {/* <button onClick={() => deleteProduct(x._id)} className="p-2 px-3 bg-red-600 text-white rounded">Delete</button> */}
                                        </div>
                                    </Modal.Body>

                                </Modal>
                            </tr>
                        );
                    })}
                </table>
            </div>}
        </div>
    )
}

export default ViewTemplates
