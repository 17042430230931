import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { hostName } from '../App'
import { useNavigate } from 'react-router'

const WorkflowCom = () => {
    let [allWorks, setAllworks] = useState()
    useEffect(() => {
        axios.get(`${hostName}/api/workflow`).then((response) => {
            setAllworks(response.data);
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }, [])
    let navigate=useNavigate()
    return (
        <div className='container'>
            {
                allWorks && allWorks.map((obj) => (
                    <div onClick={()=>navigate(`/features/workflow/${obj._id}`)} className='cursor-pointer'>
                       <h5 className=''> Show on {obj.category}</h5>
                        {obj.type_of_file=='image' && <img className={`${obj.category=='mobile'&& 'w-[400px]'}`} src={obj.fileUrl} alt='Files' />}
                        {obj.type_of_file=='video' && <video className={`${obj.category=='mobile'&& 'w-[400px]'}`} controls muted autoPlay src={obj.fileUrl} alt='Files' />}
                        <p className={`${obj.category=='mobile'&& 'w-[400px]'}`} >{obj.description} </p>
                        <hr />
                    </div>
                ))
            }
        </div>
    )
}

export default WorkflowCom