import React, { useContext, useState } from 'react'
import VideoComponent from '../../Components/VideoComponent'
import axios from 'axios'
import { hostName } from '../../App'
import { toast } from 'react-toastify'
import LeftArrow from '../../Svg_Icons/LeftArrow'
import { useNavigate } from 'react-router'
import { StateStore } from '../../Context/StateProvider'

const VideoUpload = () => {
    let { videoDB, setVideoDb, getVideodb, } = useContext(StateStore)

    let [videoObj, setVideoObj] = useState({
        name: '',
        video: null
    })
    let [loading, setloading] = useState(false)
    let handleSave = () => {
        console.log(videoObj.video);
        if (videoObj.video) {
            setloading(true)
            const formData = new FormData()
            formData.append('name', videoObj.name.trim())
            formData.append('video', videoObj.video)
            axios.post(`${hostName}/api/video`, formData).then((response) => {
                toast.success('video uploaded successfully')
                setVideoObj({
                    name: '',
                    video: null
                })
                document.getElementById('videoupload').value = ''
                setloading(false)
                getVideodb()
            }).catch((error) => {
                console.log(error);
                setloading(false)
                if (error.response.data.message) {
                    toast.error(error.response.data.message)
                    return
                }
                toast.error('Error aquired')
            })
        }
        else {
            toast.warning('Upload the video')
        }
    }
    let navigate=useNavigate()
    return (

        <div className='container mx-auto'>
            <button className='p-2 rounded bg-black my-2 ms-auto flex text-white ' onClick={()=>navigate('/features')} ><LeftArrow/> </button>
            <div>
                <h4>Upload the video here </h4>
                <section className='flex flex-wrap gap-3'>
                    <div>
                        Name :
                        <input value={videoObj.name} onChange={(e) => setVideoObj((prev) => ({
                            ...prev,
                            name: e.target.value
                        }))} type="text" placeholder='Enter the Name ' className='p-2 px-3 rounded outline-none bg-slate-50 mx-2' />
                    </div>
                    <div>
                        Video :
                        <input type="file" id='videoupload' onChange={(e) => setVideoObj((prev) => ({
                            ...prev,
                            video: e.target.files[0]
                        }))} className='p-2 px-3 rounded outline-none bg-slate-50 mx-2' />
                    </div>
                </section>
                <button onClick={handleSave} disabled={loading} className='flex ms-auto p-2 px-3 rounded bg-green-500 text-white  '>
                    {loading ? 'loading' : "Save"}
                </button>

            </div>

            <div className='my-3'>
                <h4>Already uploaded Video  </h4>
                <VideoComponent  />
            </div>

        </div>
    )
}

export default VideoUpload