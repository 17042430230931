import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { hostName } from '../../App'
import { toast } from 'react-toastify'
import LeftArrow from '../../Svg_Icons/LeftArrow'
import { Modal } from 'react-bootstrap'

const Coupon = () => {
    const { id } = useParams()
    let navigate = useNavigate()
    let [upload, setupload] = useState()
    let [show, setshow] = useState(false)
    let [couponobj, setCouponObj] = useState({
        discountPercent: null,
        description: null,
        amount: null,
        code: null,
        imageUrl: null,
        activate: null
    })
    const handleChange = (e) => {
        let { name, value } = e.target
        if (name == 'imageUrl') {
            value = e.target.files[0]
        }
        setCouponObj((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    const handleSave = () => {
        if (couponobj.imageUrl != null && couponobj.code != null && couponobj.description != null && couponobj.discountPercent != '' && couponobj.amount != null) {
            const formdata = new FormData()
            console.log(couponobj.imageUrl);
            formdata.append('image', couponobj.imageUrl)
            formdata.append('code', couponobj.code)
            formdata.append('description', couponobj.description)
            formdata.append('discountPercent', couponobj.discountPercent)
            formdata.append('amount', couponobj.amount)
            formdata.append('activate',couponobj.activate)
            axios.post(`${hostName}/api/coupon`, formdata).then((response) => {
                console.log(response.data);
                toast.success('Coupon has been added', {
                    position: 'top-center',
                    theme: 'colored',
                    autoClose: 3000
                })
                setCouponObj({
                    discountPercent: '',
                    description: '',
                    amount: '',
                    code: '',
                    imageUrl: null
                })
            }).catch((error) => {
                console.log(error)
                toast.error(error.response.data.message, {
                    position: 'top-center',
                    theme: 'colored',
                    autoClose: 3000
                })
            })
        }
        else {
            toast.warning('Enter all the Required feilds', {
                autoClose: 3000,
                position: 'top-center',
                theme: 'colored'
            })
        }
    }
    useEffect(() => {
        if (id) {
            axios.get(`${hostName}/api/coupon/${id}`).then((response) => {
                console.log(response.data);
                setCouponObj(response.data)
            }).catch((error) => {
                console.log(error);
            })
        }
    }, [id])
    let handleUploadFile = () => {
        if (upload) {
            const formdata = new FormData()
            formdata.append('image', upload)
            axios.put(`${hostName}/api/couponImage/${id}`, formdata).then((response) => {
                setCouponObj(response.data)
                setshow(false)
                setupload(null)
                setCouponObj(response.data)
                toast.success('Coupon image has been updated', {
                    position: 'top-center',
                    theme: 'colored',
                    autoClose: 3000
                })
            }).catch((error) => {
                console.log(error);
            })
        }
        else {
            document.getElementById('modelerror').innerHTML = "* Upload the Image before upload"
        }
    }
    let handleUpdate = () => {
        // alert('hellow')
        axios.put(`${hostName}/api/coupon`, { ...couponobj, id: id }).then((response) => {
            setCouponObj(response.data)
            toast.success('Coupon has been updated', {
                position: 'top-center',
                theme: 'colored',
                autoClose: 3000
            })
        }).catch((error) => {
            toast.error(error.response.data, {
                position: 'top-center',
                autoClose: 3000
            })
            console.log(error);
        })
    }
    let handleDelete = () => {
        axios.delete(`${hostName}/api/coupon/${id}`).then((response) => {
            toast.success(response.data.message, {
                position: 'top-center', autoClose: 3000, theme: 'colored'
            })
            navigate('/features/coupon')
            setCouponObj({
                discountPercent: '',
                description: '',
                amount: '',
                code: '',
                imageUrl: null
            })
        }).catch((error) => {
            toast.error(error.response.data.message, {
                position: 'top-center', autoClose: 3000, theme: 'colored'
            })
        })
    }
    let [confirmation, setconfirmation] = useState(false)
    return (
        <div className='p-3 poppins'>
            <button onClick={() => navigate('/features')} className='p-2 bg-slate-900 text-white rounded'>
                <LeftArrow />
            </button>
            <p className='text-lg '> Customize the Coupon</p>
            <div className='flex flex-wrap gap-4 justify-between'>
                <div>
                    Coupon code  :<span className='text-red-600'>*</span>
                    <input value={couponobj.code} name='code' onChange={handleChange} type="text" style={{ backgroundColor: "#f6f6f6" }} className='p-3 mx-2 my-2 px-3 border-0  rounded outline-none' />
                </div>

                <div>
                    Condition Amount :<span className='text-red-600'>*</span>
                    <input value={couponobj.amount} name='amount' onChange={handleChange} type="number" style={{ backgroundColor: "#f6f6f6" }} className='p-3 mx-2 my-2 px-3 border-0  rounded outline-none' />
                </div>
                <div>
                    Discount Percentage :<span className='text-red-600'>*</span>
                    <input value={couponobj.discountPercent} name='discountPercent' onChange={handleChange} type="number" style={{ backgroundColor: "#f6f6f6" }} className='p-3 mx-2 my-2 px-3 border-0  rounded outline-none' />
                </div>
                {!id && <div>
                    Image :<span className='text-red-600'>*</span>
                    <input name='imageUrl' onChange={handleChange} type="file" style={{ backgroundColor: "#f6f6f6" }} className='p-3 mx-2 my-2 px-3 border-0  rounded outline-none' />
                </div>}
                <div className='flex items-center'>
                    Description :<span className='text-red-600'>*</span>
                    <textarea value={couponobj.description} name='description' cols={30} onChange={handleChange} type="text" style={{ backgroundColor: "#f6f6f6" }} className='p-3 mx-2 my-2 px-3 border-0  rounded outline-none' />
                </div>
                <div className='flex items-center me-auto gap-3'>
                    Active :
                    <div onClick={() => {
                        if (couponobj.activate == false)
                            setCouponObj((prev) => ({
                                ...prev,
                                activate: true
                            }))
                        else
                            setCouponObj((prev) => ({
                                ...prev,
                                activate: false
                            }))
                    }} className={` rounded-full ${couponobj.activate ? "bg-green-300" : 'bg-red-300'}  transition duration-700 relative w-[60px] h-[30px] border-1 `}>
                            <button name='status'
                                className={` rounded-full absolute w-[26px] h-[26px] top-[1.1px] transition duration-700 ${couponobj.activate ? "translate-x-[1.1px] " : ' translate-x-[30px] '} bg-slate-100 `}>
                            </button>
                        </div>
                </div>

            </div>
            {id && <img onClick={() => setshow(true)} src={couponobj.imageUrl} className='my-2 cursor-pointer hover:scale-105 transition duration-500' width={260} alt="couponImage" />}

            <div className='flex my-2 justify-end'>

                {!id && <button onClick={handleSave} className='p-2 px-3 bg-slate-500 text-white rounded'>Save Coupon</button>}
                {id && <button onClick={handleUpdate} className='p-2 px-3 bg-slate-500 text-white rounded mx-3'>Update</button>}
                {id && <button onClick={() => setconfirmation(true)} className='p-2 px-3 bg-red-500 text-white rounded'>Delete</button>}
            </div>

            <hr />

            {show &&
                <Modal show={show} centered onHide={() => setshow(false)}  >
                    <Modal.Header className='' closeButton>
                        Image changes
                    </Modal.Header>
                    <Modal.Body>
                        upload image: <input type="file" onChange={(e) => setupload(e.target.files[0])} className='my-2' />
                        <p className='text-red-600 h-[30px] ' id='modelerror' ></p>
                        <button onClick={handleUploadFile} className='p-2 px-3 bg-slate-500 text-white rounded ms-auto flex'>Upload </button>
                    </Modal.Body>
                </Modal>
            }
            {
                confirmation && <Modal show={confirmation} onHide={() => setconfirmation(false)} centered >
                    <Modal.Header closeButton>
                        Confirmation
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure ?
                        <div className='flex justify-end'>

                            <button onClick={handleDelete} className='p-2 px-3 bg-red-600 text-white rounded mx-2'>Delete</button>
                            <button onClick={()=>setconfirmation(false)} className='p-2 px-3 bg-slate-500 text-white rounded'> Cancel </button>
                        </div>
                    </Modal.Body>

                </Modal>
            }

        </div>
    )
}

export default Coupon