import React, { useContext, useEffect } from 'react'
import { StateStore } from '../Context/StateProvider'
import PlusIcon from '../Svg_Icons/PlusIcon'
import axios from 'axios'
import { hostName } from '../App'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'

const BackgroundCom = ({ type }) => {
    const { backgroundImage, getBackground } = useContext(StateStore)
    let navigate = useNavigate()
    useEffect(() => {
        getBackground()
    }, [])
    let deletePhoto = (id) => {
        axios.delete(`${hostName}/api/background/${id}`).then((response) => {
            toast.success(response.data, {
                theme: 'colored',
                autoClose: 3000,
                position: 'top-center'
            })
            getBackground()
        }).catch((error) => {
            toast.error(error.response.data, {
                theme: 'colored',
                autoClose: 3000,
                position: 'top-center'
            })
        })
    }
    return (
        <>
            <div className={`my-2 flex flex-wrap gap-3  `}>
                {
                    backgroundImage && backgroundImage.map((x, index) => {
                        return (
                            <div key={index} className={`${index > 3 && type == 'feature' ? 'd-none' : ''} w-[11rem] relative border-1 shadow h-[11rem] sm:w-[13rem] sm:h-[13rem]`}>
                                <button onClick={() => deletePhoto(x._id)} className='m-0 rounded-full p-1 text-red-500 border-1 border-red-500 rotate-45 w-fit absolute top-2 right-2 '> <PlusIcon /> </button>
                                <img src={x.imageUrl} className='w-full h-full object-contain ' alt="Image" />
                            </div>
                        )
                    })
                }
                {backgroundImage && backgroundImage.length > 4 &&
                    type == 'feature' &&
                    <button onClick={() => navigate('/features/background')} className={` w-[11rem] flex items-center justify-center text-xl bg-slate-500 bg-opacity-30 relative border-1 shadow h-[11rem] sm:w-[13rem] sm:h-[13rem]`}>
                        See More

                    </button>}
            </div>
        </>
    )
}

export default BackgroundCom