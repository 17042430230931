import React from 'react'
import NavbarCom from '../../Components/Navbar'
import { Route, Routes, useNavigate } from 'react-router'
import AddingBanner from './AddingBanner'
import BannerModel from './NewBanner'
import Coupon from './Coupon'
import BestSellers from './BestSellers'
import BackgroundUploads from './BackgroundUploads'
import LeftArrow from '../../Svg_Icons/LeftArrow'
import VideoUpload from './VideoUpload'
import CategoryDescription from './CategoryDescription'
import ParticularCategoryDesPage from './ParticularCategoryDesPage'
import WorkflowAddingpage from './WorkflowAddingpage'
import WorkflowParticularpage from './WorkflowParticularpage'

const Extras = () => {
  let navigate = useNavigate()
  return (
    <div>
      <NavbarCom />
      <Routes>
        <Route element={<AddingBanner />} path='/*' />

        <Route path='/coupon/:id?' element={<Coupon />} />
        <Route path='/banner/:id?' element={<BannerModel />} />
        <Route path='/bestseller' element={<BestSellers />} />
        <Route path='/background' element={<BackgroundUploads />} />
        <Route path='/videoUpload' element={<VideoUpload />} />
        <Route path='/workflow/*' element={<WorkflowAddingpage />} />
        <Route path='/workflow/:id' element={<WorkflowParticularpage />} />


        <Route path='/categorydescription/*' element={<CategoryDescription />} />
        <Route path='/categorydescription/:id' element={<ParticularCategoryDesPage />} />


      </Routes>
    </div>
  )
}

export default Extras