import React from 'react'
import { useNavigate } from 'react-router'
import BannerIcon from '../Svg_Icons/BannerIcon'
import FeaturesIcon from '../Svg_Icons/FeaturesIcon'

const Features = () => {
    let navigate=useNavigate()
  return (
    <div className='p-2 poppins'>
         <div className='rounded-xl poppins col-md-8 h-fit  bg-white p-3'>
                <h5>Features </h5>
                <div className='flex justify-between gap-2 flex-wrap'>
                    {/* Card */}
                    <div onClick={()=>navigate('/features/banner')} className='bg-violet-50 rounded-2xl hover:scale-105 transition duration-500 cursor-pointer flex flex-col justify-around p-4 w-[11rem] h-[14rem] sm:h-[250px] sm:w-[13rem] '>
                        <div className='rounded-full w-fit text-white p-2 mb-3 bg-violet-400  '>
                            <BannerIcon/>   
                        </div>
                        <p className='block'>Banners customizations </p>
                    </div>
                    {/* Product list */}
                    <div onClick={() => navigate('/features/coupon')} className='bg-cyan-50 cursor-pointer hover:scale-105 transition duration-500 rounded-2xl flex flex-col justify-around p-4 w-[11rem] h-[14rem] sm:h-[250px] sm:w-[13rem] '>
                        <div className='rounded-full w-fit text-white p-2 mb-3 bg-cyan-600  '>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-archive-fill" viewBox="0 0 16 16">
                                <path d="M12.643 15C13.979 15 15 13.845 15 12.5V5H1v7.5C1 13.845 2.021 15 3.357 15zM5.5 7h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1M.8 1a.8.8 0 0 0-.8.8V3a.8.8 0 0 0 .8.8h14.4A.8.8 0 0 0 16 3V1.8a.8.8 0 0 0-.8-.8z" />
                            </svg>
                        </div>
                        <p className='block'> Coupons </p>
                    </div>
                    <div onClick={() => navigate('/features')} className='bg-fuchsia-50 flex flex-col cursor-pointer hover:scale-105 transition duration-500 justify-around rounded-2xl p-4 w-[11rem] h-[14rem] sm:h-[250px] sm:w-[13rem] '>
                        <div className='rounded-full w-fit text-white p-2 mb-3 bg-fuchsia-600  '>
                            <FeaturesIcon /> </div>
                        <p className='block'>All Features</p>
                    </div>
                    <div onClick={() => navigate('/features')} className='bg-red-50 flex flex-col justify-around cursor-pointer hover:scale-105 transition duration-500 rounded-2xl p-4 w-[11rem] h-[14rem] sm:h-[250px] sm:w-[13rem]  '>
                        <div className='rounded-full w-fit text-white p-2 mb-3 bg-red-400 '>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-body-text" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M0 .5A.5.5 0 0 1 .5 0h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 0 .5m0 2A.5.5 0 0 1 .5 2h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m9 0a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-9 2A.5.5 0 0 1 .5 4h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m5 0a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m7 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m-12 2A.5.5 0 0 1 .5 6h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m8 0a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-8 2A.5.5 0 0 1 .5 8h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m7 0a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-7 2a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5" />
                            </svg>
                        </div>
                        <p className='block'> Best Sellers </p>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Features