import React, { useEffect, useState } from 'react'
import NavbarCom from '../../Components/Navbar'
import PlusIcon from '../../Svg_Icons/PlusIcon'
import {Modal} from 'react-bootstrap'
import axios from 'axios'
import { hostName } from '../../App'
import { toast } from 'react-toastify'
const ClipPath = (props) => {
    const {product,setProduct,id,show,setShowPath,clipId,index}=props
    let [clipathImage, setClippathImage] = useState({
        image: null,
        url: null,
        width: 320
    })
    const [backgroundImage,setBackgroundImage]=useState()
    let [textAlignments, setTextAlignments] = useState([])
    let [imageAlignments, setImageAlignments] = useState([])
    let getParticularProduct = () => {
        axios.get(`${hostName}/api/productID?id=${id}`).then((response) => {
            console.log(response.data);
            setProduct(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
       if(id) { getParticularProduct()}
    }, [])
    const handleClipPath = (e) => {
        let { value, name, files } = e.target
        let file = files[0]
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                console.log(reader.result);
                setClippathImage((prev) => ({
                    ...prev,
                    url: reader.result
                }))
            };
            reader.readAsDataURL(file);
        }
        setClippathImage((prev) => ({
            ...prev,
            image: files[0],
        }))
    }
    const createDiv = (type) => {
        if(textAlignments.length==3 && type=='text'){
            toast.error('Text Position already reached limit 3',{
                autoClose:5000,
                theme:'colored',
                position:'top-center'
            })
            return
        }
        if(imageAlignments.length==4&&type=='image' ){
            toast.error('Image Position already reached limit 4',{
                autoClose:5000,
                theme:'colored',
                position:'top-center'
            })
            return
        }
        const newDiv = {
            id: type == 'image' ? imageAlignments.length + 1 : textAlignments.length + 1,
            x: 90,
            y: type == 'image' ? 500 : 300,
            height: type == 'image' ? 140 : 70, // Random height between 50 and 250
            width: 150, // Random width between 50 and 250
        };
        // Add the new div to the array
        if (type == 'text') {
            setTextAlignments([...textAlignments, newDiv]);
        }
        if (type == 'image') {
            setImageAlignments([...imageAlignments, newDiv]);
        }

    }
    const handleTextAlginment = (e, index, type) => {
        let { value, name } = e.target
        if (type == 'text') {
            setTextAlignments((prev) => {
                const updatedArray = [...prev]; // Create a copy of the array
                updatedArray[index] = { ...updatedArray[index], [name]: value }; // Update the specific property
                return updatedArray; // Return the updated array
            });
        }
        if (type == 'image') {
            setImageAlignments((prev) => {
                const updatedArray = [...prev]; // Create a copy of the array
                updatedArray[index] = { ...updatedArray[index], [name]: value }; // Update the specific property
                return updatedArray; // Return the updated array
            });
        }
    }

    let handleSave = () => {
        const formData = new FormData()
        formData.append('image', clipathImage.image)
        if (textAlignments.length > 0) {
            console.log(typeof textAlignments);
            // for (let i = 0; i < textAlignments.length; i++) {
            //     formData.append('textAlignments', JSON.stringify(textAlignments[i]))
            // }
            formData.append('textAlignments',JSON.stringify(textAlignments))
        }
        if (imageAlignments.length > 0) {
            // for (let i = 0; i < imageAlignments.length; i++) {
            //     formData.append('userUploadAlignments', JSON.stringify(imageAlignments[i]))
            // }
            formData.append('userUploadAlignments',JSON.stringify(imageAlignments))
        }
        axios.post(`${hostName}/api/clipath`, formData).then((response) => {
            console.log(response.data);
            setProduct((prev)=>{
                let arry= [...prev.clipPaths]
                arry[index] = response.data._id 
                console.log(arry);
                return {
                    ...prev,
                    clipPaths:arry
                }
            })
            setShowPath(false)
            setTextAlignments([])
            setImageAlignments([])
            setClippathImage({
                image: null,
                url: null,
                width: 320
            })

        }).catch((error) => {
            console.log(error);
        })
    }
    let handleUpdate =()=>{
        if(clipathImage.image instanceof File){
            const formData=new FormData()
            formData.append('image', clipathImage.image)
            if (textAlignments.length > 0) {
                // for (let i = 0; i < textAlignments.length; i++) {
                //     formData.append('textAlignments', JSON.stringify(textAlignments[i]))
                // }
                formData.append('textAlignments', JSON.stringify(textAlignments))
            }
            if (imageAlignments.length > 0) {
                // for (let i = 0; i < imageAlignments.length; i++) {
                //     formData.append('userUploadAlignments', JSON.stringify(imageAlignments[i]))
                // }
                formData.append('userUploadAlignments', JSON.stringify(imageAlignments))

            }
            formData.append('id',clipId);
            console.log(clipId);
            console.log(clipathImage.image);
            axios.put(`${hostName}/api/clipath`,formData).then((response)=>{
                setShowPath(false)
                console.log(response.data);
                
            //    toast.success('Updated successfully',{
            //     position:'top-center',
            //     theme:'colored',
            //     autoClose:3000
            //     // autoClose:3000
            //    })
            }).catch((error)=>{
                alert(error)
                console.log(error);
            })

        }
        else{
            axios.put(`${hostName}/api/clipathNoImage`,{
                id:clipId,
                textAlignments:textAlignments,
                userUploadAlignments:imageAlignments
            }).then((response)=>{
                console.log(response.data);
                setShowPath(false)
                
            //    toast.success('Updated successfully',{
            //     position:'top-center',
            //     theme:'colored',
            //     autoClose:3000
            //    })
            }).catch((error)=>{
                console.log(error);
            })
        }
         
    }
    useEffect(()=>{

        if(clipId){
            console.log(clipId);
            axios.get(`${hostName}/api/clipath/${clipId}`).then((response)=>{
                setImageAlignments(response.data.userUploadAlignments)
                setTextAlignments(response.data.textAlignments)
                setClippathImage((prev)=>({
                    ...prev,
                    url:response.data.clipImage
                }))
            })
        }
    },[clipId])
    return (
        <Modal show={show} onHide={()=>setShowPath(false)} centered size='xl' >
            <div className='poppins py-3'>
                <button onClick={()=>setShowPath(false)} className='rotate-45 flex ms-auto mx-3 p-2 border-1 rounded-full '><PlusIcon/> </button>
                {/* <NavbarCom /> */}
                <h4 className='text-center'>{clipId!=undefined ? 'Clipath Updation' :"Clipath Upload "}</h4>
                <div className='row container mx-auto min-h-[80vh] my-2 '>
                    <section className='col-lg-6 border-1 min-h-[600px] p-3 h-fit rounded flex'>
                        {/* Container for the Editing */}
                        <div id='mobileContainer' className={`relative w-[${clipathImage.width}px] min-h-[400px] m-auto`}>
                            {clipathImage.url &&
                                <img src={clipathImage.url} width={clipathImage.width} alt="Image" />}
                            {textAlignments.map((div) => (
                                <div
                                    key={div.id}
                                    style={{
                                        height: `${div.height}px`,
                                        width: `${div.width}px`,
                                        position: 'absolute',
                                        left: `${div.x}px`,
                                        top: `${div.y}px`,
                                        border: '1px solid black',
                                    }}
                                >
                                    Text {div.id}
                                </div>))}
                            {imageAlignments.map((div) => (
                                <div
                                    key={div.id}
                                    style={{
                                        height: `${div.height}px`,
                                        width: `${div.width}px`,
                                        position: 'absolute',
                                        left: `${div.x}px`,
                                        top: `${div.y}px`,
                                        border: '1px solid black',
                                    }}
                                >
                                    Upload {div.id}
                                </div>))}
                        </div>
                    </section>
                    {/* Content */}
                    <section className='col-lg-6 p-3'>
                        <div className='border-1  border-slate-950 rounded ms-auto w-full  h-fit p-3'>
                            Clip path Image Upload : <span className='text-red-600'>* </span>
                            <input type="file" onChange={handleClipPath} className=' my-3  block' />
                            {/* <div>
                        Width : <input type="number" value={clipathImage.width} onChange={(e)=>setClippathImage((prev)=>({...prev,width:e.target.value}))} className='outline-none border-1 p-1 w-32 ' />
                    </div> */}
                        </div>
                        {/* Text adding the Layout */}
                        {clipathImage.url && <><div className='border-1  border-slate-950 rounded ms-auto w-full h-fit p-3 my-3'>
                            Text alignMent :
                            <button onClick={() => createDiv('text')} className='flex border-1 rounded-full p-2 ms-auto'><PlusIcon /></button>
                            <div className='flex flex-wrap gap-3 justify-between px-3'>
                                {
                                    textAlignments && textAlignments.map((x, index) => {
                                        return (
                                            <div id={index} className='border-1 relative p-2 w-fit border-slate-950 rounded my-2'>
                                                Text Adjustment {x.id}
                                                <button onClick={() => {
                                                    setTextAlignments((prev) => prev.filter((obj,index1) =>index1 != index))
                                                }} className='absolute right-5 bottom-5 border-red-500 text-red-500 rotate-45 p-2 border-1 rounded-full  '><PlusIcon /> </button>
                                                <div className='my-2'>
                                                    Width :
                                                    <input type="number" name='width' onChange={(e) => handleTextAlginment(e, index, "text")} className='border-1 p-1 outline-none w-32 mx-2' value={x.width} />
                                                </div>
                                                <div className='my-2'>
                                                    Height :
                                                    <input type="number" name='height' onChange={(e) => handleTextAlginment(e, index, "text")} className='border-1 p-1 outline-none w-32  mx-2' value={x.height} />
                                                </div>
                                                <div className='my-2'>
                                                    X :
                                                    <input type="number" name='x' onChange={(e) => handleTextAlginment(e, index, "text")} className='border-1 p-1 outline-none w-32  mx-2' value={x.x} />
                                                </div>
                                                <div className='my-2'>
                                                    Y :
                                                    <input type="number" name='y' onChange={(e) => handleTextAlginment(e, index, "text")} className='border-1 p-1 outline-none w-32  mx-2' value={x.y} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }</div>
                        </div>
                            {/* User Upload Images */}
                            <div className='border-1  border-slate-950 rounded ms-auto w-full h-fit p-3 my-3'>
                                User Upload Image Layout
                                <button onClick={() => createDiv('image')} className='flex border-1 rounded-full p-2 ms-auto'><PlusIcon /></button>
                                <div className='flex gap-3 flex-wrap '>
                                    {
                                        imageAlignments && imageAlignments.map((x, index) => {
                                            console.log(x);
                                            return (
                                                <div id={index} className='border-1 relative p-2 w-fit border-slate-950 rounded my-2'>
                                                    Image Adjustment {x.id}
                                                    <button onClick={() => {
                                                        setImageAlignments((prev) => prev.filter((obj,index1) =>index1 != index))
                                                    }} className='absolute right-5 bottom-5 border-red-500 text-red-500 rotate-45 p-2 border-1 rounded-full  '>
                                                        <PlusIcon /> </button>
                                                    <div className='my-2'>
                                                        Width :
                                                        <input type="number" name='width' onChange={(e) => handleTextAlginment(e, index, "image")} className='border-1 p-1 outline-none w-32 mx-2' value={x.width} />
                                                    </div>
                                                    <div className='my-2'>
                                                        Height :
                                                        <input type="number" name='height' onChange={(e) => handleTextAlginment(e, index, "image")} className='border-1 p-1 outline-none w-32  mx-2' value={x.height} />
                                                    </div>
                                                    <div className='my-2'>
                                                        X :
                                                        <input type="number" name='x' onChange={(e) => handleTextAlginment(e, index, "image")} className='border-1 p-1 outline-none w-32  mx-2' value={x.x} />
                                                    </div>
                                                    <div className='my-2'>
                                                        Y :
                                                        <input type="number" name='y' onChange={(e) => handleTextAlginment(e, index, "image")} className='border-1 p-1 outline-none w-32  mx-2' value={x.y} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <button onClick={handleSave} className={`p-2 px-3 border-1 ${clipId?'d-none':''} rounded bg-slate-600 text-white my-3`}>Save Clipath </button>
                            <button onClick={handleUpdate} className={`p-2 px-3 border-1  ${!clipId?'d-none':''} rounded bg-slate-600 text-white my-3`}>Update Clipath </button>

                        </>}
                    </section>
                </div>

            </div>
        </Modal>
    )
}

export default ClipPath
