import React, { useContext, useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { StateStore } from '../../Context/StateProvider'
import axios from 'axios'
import { hostName } from '../../App'
import { toast } from 'react-toastify'
import LeftArrow from '../../Svg_Icons/LeftArrow'
import { useNavigate, useParams } from 'react-router'

const BannerModel = (props) => {
    let navigate = useNavigate()
    let { id } = useParams()
    const { allProductDb, categoryDB } = useContext(StateStore)
    const [bannerobj, setBanner] = useState({
        imageUrl: null,
        category: '',
        bannerName: '',
        productId: '',
        heading: '',
        text: '',
        mobileImageurl:null,
        headingclr: '#090200',
        textClr: '#090200'
    })
    let [errorMessage, setErrorMessage] = useState()
    const [imageUrl, setImageurl] = useState()
    const [mobileBanner, setMobileBanner] = useState()
    const handleChange = (e) => {
        let { name, value, files } = e.target
        if (name == 'image') {
            let file = files[0]
            const reader = new FileReader();
            reader.onload = () => {
                setImageurl(reader.result)
            };
            reader.readAsDataURL(file);
            setBanner((prev) => ({
                ...prev,
                imageUrl: file
            }))

        }
        if (name == 'image2') {
            let file = files[0]
            const reader = new FileReader();
            reader.onload = () => {
                setMobileBanner(reader.result)
            };
            reader.readAsDataURL(file);
            setBanner((prev) => ({
                ...prev,
                mobileImageurl: file
            }))

        }
        else {
            setBanner((prev) => ({
                ...prev,
                [name]: value
            }))
        }
    }
    useEffect(() => {
        if (id) {
            axios.get(`${hostName}/api/banner/${id}`).then((response) => {
                console.log(response.data);
                setBanner(response.data)
                setImageurl(response.data.imageUrl)
                setMobileBanner(response.data.mobileImageurl)

            }).catch((error) => {
                console.log(error);
            })
        }
    }, [id])
    let handleSave = () => {

        if (bannerobj.imageUrl != null && bannerobj.bannerName != '') {
            const formdata = new FormData()
            formdata.append(`desktop`, bannerobj.imageUrl)
           if(bannerobj.mobileImageurl instanceof File ){ formdata.append(`mobile`, bannerobj.mobileImageurl)}

            formdata.append('bannerName', bannerobj.bannerName)
            if (bannerobj.bannerName == 'Home') {
                formdata.append('heading', bannerobj.heading)
                formdata.append('headingclr', bannerobj.headingclr)
                formdata.append('text', bannerobj.text)
                formdata.append('textClr', bannerobj.textClr)
                // formdata.append('productId', bannerobj.productId)
                formdata.append('category', bannerobj.category)
            }
            setErrorMessage('')
            axios.post(`${hostName}/api/bannermulti`, formdata).then((response) => {
                console.log(response.data);
                toast.success('Banner has been added', {
                    position: 'top-center',
                    autoClose: 3000,
                    theme: 'colored'
                })
                setBanner({
                    imageUrl: null,
                    bannerName: '',
                    productId: '',
                    heading: '',
                    text: '',
                    headingclr: '#090200',
                    textClr: '#090200'
                })
                setImageurl('')
                setMobileBanner('')
            }).catch((error) => {
                console.log(error);
            })
        }
        else {
            setErrorMessage('* Enter the required fields')
        }
    }
    let handleUpdate = () => {
        const formdata = new FormData()
        if (bannerobj.imageUrl instanceof File || bannerobj.mobileImageurl instanceof File ) {
            if(bannerobj.imageUrl instanceof File ) 
                {formdata.append('desktop', bannerobj.imageUrl); alert("Desktop")}
            if(bannerobj.mobileImageurl instanceof File ){ formdata.append('mobile', bannerobj.mobileImageurl);alert('mobile')}
            formdata.append('bannerName', bannerobj.bannerName)
            if (bannerobj.bannerName == 'Home') {
                formdata.append('heading', bannerobj.heading)
                formdata.append('headingclr', bannerobj.headingclr)
                formdata.append('text', bannerobj.text)
                formdata.append('textClr', bannerobj.textClr)
                // formdata.append('productId', bannerobj.productId)
                formdata.append('category', bannerobj.category)

            }
            axios.put(`${hostName}/api/banner/${id}`, formdata).then((response) => {
                // console.log(response.data);
                toast.success('Banner updated successfully', {
                    position: 'top-center',
                    theme: 'colored',
                    autoClose: 3000
                })
            }).catch((error) => {
                console.log(error);
            })
        }
        else {
            axios.put(`${hostName}/api/bannerWithoutImage/${id}`, bannerobj).then((response) => {
                // console.log(response.data)
                toast.success('Banner has been updated', {
                    position: 'top-center',
                    autoClose: 3000,
                    theme: 'colored'
                })
            }).catch((error) => {
                console.log(error);
            })
        }
    }
    let handleDelete = () => {
        axios.delete(`${hostName}/api/banner/${id}`).then((response) => {
            toast.success('Banner has been deleted', {
                position: 'top-center',
                theme: "colored",
                autoClose: 3000
            })
            navigate('/features')
        }).catch((error) => {
            console.log(error);
        })
    }
    return (
        <div className='container mx-auto'>
            <button onClick={() => navigate('/features')} className='p-2 bg-slate-900 text-white rounded '>
                <LeftArrow />
            </button>
            <h5>
                Add the New Home Banner
            </h5>
            <div className='p-3'>
                {/* To add the Banner */}
                <div className=' flex flex-wrap gap-4 justify-between items-center'>
                    {/* Image upload */}
                    <div>
                        Image for the Banner : <span className='text-red-500'>*</span>
                        <input type="file" name='image' onChange={handleChange} className='mx-2' accept='image/*' />
                    </div>
                    <div>
                        Image for the mobile Banner : <span className='text-red-500'>*</span>
                        <input type="file" name='image2' onChange={handleChange} className='mx-2' accept='image/*' />
                    </div>
                    {/* Banner Name */}
                    <div className='my-2'>
                        Page : <span className='text-red-600'>*</span>
                        <select value={bannerobj.bannerName} name="bannerName" onChange={handleChange} className='outline-none mx-2 ' id="">
                            <option value="">Select </option>
                            <option value="Home">Home page</option>
                            <option value="Shop">Shopping page</option>
                            <option value="Cart">Cart page</option>
                            <option value="Wishlist">Wishlist page</option>
                        </select>
                    </div>
                    {/* Add the Product */}
                    {bannerobj.bannerName == 'Home' && <div className='my-2'>
                        Category for the Banner :
                        <select name="category" className='outline-none p-2 px-3' onChange={handleChange} value={bannerobj.category} id="">
                            <option value="">Select</option>
                            {
                                categoryDB && [...categoryDB].reverse().map((x) => {
                                    console.log(x);
                                    return (
                                        <option value={x.category}>{x.category} </option>
                                    )
                                })
                            }
                        </select>
                    </div>}
                    {/* Title*/}
                    {bannerobj.bannerName == 'Home' && <div className='my-2'>
                        Title :
                        <input type="text" value={bannerobj.heading} className='outline-none border-1 mx-2 rounded  p-1' name='heading' onChange={handleChange} />
                    </div>}
                    {/* Text */}
                    {bannerobj.bannerName == 'Home' && <div className='my-2'>
                        Text :
                        <input type="text" value={bannerobj.text} name='text' className='outline-none border-1 mx-2 rounded  p-1' onChange={handleChange} />
                    </div>}
                    {bannerobj.bannerName == 'Home' && <div className='flex items-center gap-3'>
                        Heading Color:
                        <input type="color" value={bannerobj.headingclr} name='headingclr' onChange={handleChange} />
                    </div>
                    }
                    {bannerobj.bannerName == 'Home' && <div className='flex items-center gap-3'>
                        Text Color:
                        <input type="color" value={bannerobj.textClr} name='textClr' onChange={handleChange} />
                    </div>
                    }
                </div>
                <p className='text-red-600 h-[30px] '>{errorMessage} </p>
                {!id && <button onClick={handleSave} className='p-2 px-3 rounded bg-slate-500 text-white ms-auto flex my-2'>
                    Save Banner </button>}

                {id && <button onClick={handleUpdate} className='p-2 px-3 rounded bg-slate-500 text-white ms-auto flex my-2'>Update Banner </button>}
                {id && <button onClick={handleDelete} className='p-2 px-3 rounded bg-red-500 text-white ms-auto flex my-2'>Delete Banner </button>}

            </div>
            {/* Preview */}
            {<div>
                <h4>Privew of the  Desktop Banner</h4>
                <div className='relative'>
                    {imageUrl && <img src={imageUrl} alt='Banner' className=' object-cover w-full h-[60vh] ' />}
                    {bannerobj.bannerName == 'Home' && <Row className="h-[60vh] top-0 absolute container mx-auto ">
                        {/* Text content */}
                        <Col lg={6} className="fade-in-up  flex flex-col ">

                            <div className="my-auto h-fit mx-auto">
                                <h2 style={{ color: `${bannerobj.headingclr}` }} className="fw-bold display-2 poppins lg:pe-10">{bannerobj.heading && bannerobj.heading} </h2>
                                <p style={{ color: `${bannerobj.textClr}` }} className="text-xl">{bannerobj.text && bannerobj.text} </p>
                                <button className="text-white bg-violet-700 hover:bg-slate-900 transition duration-500 p-3 px-5 rounded-s-full rounded-e-full">
                                    Shop now
                                </button>
                            </div>
                        </Col>
                        

                    </Row>}
                </div>

            </div>}
            {mobileBanner&& <div className='my-3'>
                <h4>Privew of the  Mobile Banner</h4>
                <div className='relative w-[400px] '>
                    {mobileBanner && <img src={mobileBanner} alt='Banner' className='w-[400px] object-cover h-[60vh] ' />}
                    {bannerobj.bannerName == 'Home' && 
                    <Row className="h-[60vh] top-0 absolute container mx-auto ">
                        {/* Text content */}
                        <Col  className="fade-in-up py-5 flex flex-col ">

                            <div className="mt-auto h-fit mx-auto">
                                <h6 style={{ color: `${bannerobj.headingclr}` }} 
                                className="fw-bold text-4xl poppins ">
                                    {bannerobj.heading && bannerobj.heading} </h6>
                                <p style={{ color: `${bannerobj.textClr}` }} className="text-xl">{bannerobj.text && bannerobj.text} </p>
                                <button className="text-white bg-violet-700 hover:bg-slate-900 transition duration-500 p-3 px-5 rounded-s-full rounded-e-full">
                                    Shop now
                                </button>
                            </div>
                        </Col>
                       

                    </Row>}
                </div>

            </div>}

        </div>
    )
}

export default BannerModel