import React, { useEffect, useState } from 'react'
import NavbarCom from '../../Components/Navbar'
import axios from 'axios'
import { hostName } from '../../App'
import CartProductDetails from './CartProductDetails'
import SearchIcon from '../../Svg_Icons/SearchIcon'

const CartListPage = () => {
  let [cartListData, setCartData] = useState()
  let [filterCart, setfilterCart] = useState()
  let [show, setshow] = useState(false)
  let [cartProduct, setCartProduct] = useState()
  useEffect(() => {
    axios.get(`${hostName}/api/cart`).then((response) => {
      setCartData(response.data)
      setfilterCart(response.data)
      console.log(response.data);
    }).catch((error) => {
      console.log(error);
    })
  }, [])
  let [search, setSearch] = useState('')
  let searchCart = () => {
    let word = search.toLowerCase()
    if (search != '') {
      // alert(search)
      setfilterCart([...cartListData].filter((x) =>x.userId&& (x.userId.username.toLowerCase().indexOf(word) != -1 || x.userId.phone.toString().toLowerCase().indexOf(word) != -1)))
    }
    else
      setfilterCart(cartListData)
  }
  return (
    <div>
      <NavbarCom />
      <div className='container mx-auto'>
        {/* Filteration */}
        <section className="ms-auto my-3 flex gap-3 w-fit ">
          <div className="ms-auto w-fit p-2 px-3  border-1 border-slate-400 rounded-full">
            <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} onKeyUp={(e) => {
              if (e.key == 'Enter')
                searchCart()
            }} className=" outline-none " placeholder="Search by name and phone" />
            <button onClick={searchCart}>
              <SearchIcon />
            </button>
          </div>
          <select name="" className="p-2 outline-none border-1 px-3 border-slate-400 rounded-full " id="">
            <option value=""> Select</option>
            <option value="AZ">Sort by A-Z</option>
            <option value="ZA">Sort by Z-A</option>
          </select>
        </section>

        {filterCart &&
          <div className='container mx-auto'>
            <h4 className=''>Cart Listed Product</h4>
            <div className='table-responsive  '>
              <table className="min-w-[800px] w-full table-striped">
                <tr className='bg-slate-500 text-white'>
                  <th className="text-center py-2 w-fit border-1">SI.No </th>
                  <th className="text-center w-fit border-1">User Id</th>
                  <th className="text-center w-fit border-1">User Name </th>
                  <th className="text-center w-fit border-1">Contact Number </th>
                  <th className="text-center w-fit border-1">Product Name </th>
                  <th className="text-center w-fit border-1">Product details </th>
                  <th className="text-center w-fit border-1">Chat </th>




                </tr>

                {[...filterCart].reverse().map((x, index) => {
                  console.log(x);
                  return (
                    <tr className={`items-center w-full justify-between m-0  ${index % 2 == 0 ? "bg-slate-50" : "bg-slate-100"}`}>
                      <td className="text-center border-1"> {index + 1} </td>
                      <td className="text-center border-1"> {x.userId && x.userId._id} </td>
                      <td className="text-center border-1"> {x.userId && x.userId.username} </td>
                      <td className="text-center border-1"> {x.userId && x.userId.phone} </td>
                      <td className="text-center border-1"> {x.productId && x.productId.productName} </td>
                      <td className="text-center border-1"> <button onClick={() => { setshow(true); setCartProduct(x) }} className=' '>click here </button></td>
                      <td className="text-center border-1"> <a target='_blank' className='' href={`https://wa.me/+91${x.userId && x.userId.phone}`} >
                        <img width={30} src={require('../../Assest/whatsapplogo.png')} className='mx-auto ' alt="WhatsappLogo" />   </a> </td>
                    </tr>)
                })
                }
              </table>
            </div>
          </div>}

        {show && <CartProductDetails show={show} setShow={setshow} product={cartProduct} />}

      </div>
    </div>
  )
}

export default CartListPage
