import React, { useEffect, useState } from 'react'
import NavbarCom from '../../Components/Navbar'
import { hostName, mobileFrame } from '../../App'
import DustBin from '../../Svg_Icons/DustBin'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'

const Layout = (props) => {
    let { data } = props
    let [obj, setobj] = useState(data)
    let [templateName, settemplate] = useState('')
    const initialImageDataState = {
        image: null,
        width: 120,
        x: 40,
        y: 120
    };

    const initialBackgroundImageState = {
        image: null,
        x: 0,
    };

    const initialTextDataState = {
        width: 310,
        height: 40,
        x: 0,
        y: 450
    };
    const handleReset = () => {
        setBgBoolean(false);
        setBackgroundImage(initialBackgroundImageState);
        setTextData(initialTextDataState);
        setImageUrl(null);
        setErrorMessage('');
    };
    const [backgroundColor, setBackgroundcolor] = useState('#bcb8b8')
    const [imageData, setimageData] = useState(initialImageDataState);
    const [bgBoolean, setBgBoolean] = useState(false)

    const [backgroundImage, setBackgroundImage] = useState(initialBackgroundImageState)
    const [textData, setTextData] = useState(initialTextDataState)
    const [imageURL, setImageUrl] = useState()
    const [backgroundImageUrl, setBackgroundImageurl] = useState()
    const [errorMessage, setErrorMessage] = useState()
    const handleImage = (e) => {
        const file = e.target.files[0];
        if (file.size <= 1024 * 1024) {
            setimageData((prev) => ({
                ...prev,
                image: file
            }))
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    setImageUrl(reader.result);
                };
                reader.readAsDataURL(file);
            }
            setErrorMessage('')

        } else {
            e.target.value = ''
            setErrorMessage(`* Upload a photo less than 1MB, Uploaded file is ${(file.size / (1024 * 1024)).toFixed(2)}MB`)
        }
    }
    const handleImage2 = (e) => {
        const file = e.target.files[0];
        if (file.size <= 1024 * 1024) {
            setBackgroundImage((prev) => ({
                ...prev,
                image: file
            }))
                if (file) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        setBackgroundImageurl(reader.result);
                    };
                    reader.readAsDataURL(file);
                }
            setErrorMessage('')
        } else {
            e.target.value = ''
            setErrorMessage(`* Upload a photo less than 1MB, Uploaded file is ${(file.size / (1024 * 1024)).toFixed(2)}MB`)
        }
    }
    const handleInput = (e) => {
        let { value, name } = e.target;
        if (name == 'width' || name == 'x' || name == 'y') { value = Number(value) < 0 ? 0 : Number(value); }// Convert value to number and ensure it's not negative
        setimageData((prev) => ({
            ...prev,
            [name]: value
        }));
    };
    const handleInput3 = (e) => {
        let { value, name } = e.target;
        if (name == 'width' || name == 'x' || name == 'y') { value = Number(value) < Number(value) ? 0 : Number(value); }// Convert value to number and ensure it's not negative
        setBackgroundImage((prev) => ({
            ...prev,
            [name]: value
        }));

    }
    const handleInput2 = (e) => {
        let { value, name } = e.target;
        if (name == 'width' || name == 'height' || name == 'x' || name == 'y') {
            value = Number(value) < 0 ? 0 : Number(value);
            if (name == 'width' && value > 320)
                value = 320

        }// Convert value to number and ensure it's not negative
        setTextData((prev) => ({
            ...prev,
            [name]: value
        }));
    }
    let [updateImageHandler, setUpdateImageHandler] = useState(null)
    let handleSave = (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('symbolImage', imageData.image)
        formData.append('templateName', templateName);
        formData.append('backgroundImage', bgBoolean);
        formData.append('backgroundColor', !bgBoolean);
        formData.append('textData', JSON.stringify(textData));
        formData.append('symbolImageData', JSON.stringify(imageData));
        axios.post(`${hostName}/api/template`, formData).then((response) => {
            // console.log(response.data);
            toast.success("Template has been Saved", {
                theme: 'colored',
                autoClose: 5000,
                position: 'top-center'
            })
            handleReset()
        }).catch((error) => {
            // console.log(error);
            toast.error(error.response.data.message, {
                theme: 'colored',
                autoClose: 5000,
                position: 'top-center'
            })
            // setErrorMessage(error.response.data.message)
        })
    }
    useEffect(() => {
        //   console.log(obj);
        if (obj != undefined) {
            setBgBoolean(obj.backgroundImage)
            setimageData(obj.symbolImageData[0])
            setImageUrl(obj.symbolImageData[0].image)
            setTextData(obj.textData[0])
            settemplate(obj.templateName)
        }
    }, [])
    let updateTemplate = () => {
        axios.put(`${hostName}/api/template`, {
            id: obj._id,
            templateName: templateName,
            backgroundColor: !bgBoolean,
            backgroundImage: bgBoolean,
            symbolImageData: [imageData],
            textData: [textData]
        }).then((response) => {
            toast.success('Template updated successfully', {
                autoClose: 5000,
                theme: 'colored',
                position: 'top-center'
            })
            setobj(response.data)
            // console.log(response.data);
        }).catch((error) => {
            // console.log(error);
            toast.error('Error aquired', {
                autoClose: 5000,
                theme: 'colored',
                position: 'top-center'
            })
        })
    }
    let uploadImage = (index, e) => {
        let formData = new FormData()
        formData.append('symbolImage', e.target.files[0])
        formData.append('index', index);
        formData.append('id', obj._id)
        formData.append('url', obj.symbolImageData[0].image)
        axios.put(`${hostName}/api/symbolImage`, formData).then((response) => {
            // console.log(response.data);
            // setImageUrl(response.data)
            setimageData(response.data)
            setImageUrl(response.data.image)
            toast.success('Image has been updated', {
                position: 'top-center',
                theme: 'colored',
                autoClose: 5000
            })
        }).catch((error) => {
            // console.log(error);
            toast.error('Image not updated , Error has been acquired', {
                position: 'top-center',
                theme: 'colored',
                autoClose: 5000
            })
        })

    }
    return (
        <div>
            <ToastContainer />
            {obj == undefined && <NavbarCom />}
            {

            }
            <div className='row container mx-auto '>
                <h4 className='font-serif text-center '>{obj ? `${obj.templateName}` : " Layout 1 "}</h4>
                <div>
                    <h4>Description : </h4>
                    <ul>
                        <li>Background color : If you give the Background Color user can able to change the background color in the UI </li>
                        <li>Background Image : If you enable the background Image , user can able to change the Background image in the UI , If its disabled they are not able to do it. </li>
                        <li>Symbol Image : Its a Image you want to upload in the template , which can't be removed by the User. </li>
                        <li>Text Alignment : Here text alignment has been to done where user can write the Text from UI.</li>
                    </ul>
                    <hr />
                </div>
                <section className='col-lg-6 flex'>

                    {/* Image mobile Frame positioning */}
                    <div style={{ backgroundColor: `${backgroundColor}` }} 
                    className={`${mobileFrame} 
                    relative border-3 border-slate-900 overflow-hidden rounded-2xl m-auto`}>
                        {backgroundImageUrl &&
                            <img src={backgroundImageUrl}
                                style={{
                                    position: 'relative',
                                    left: `${backgroundImage.x}px`,
                                    right: `${backgroundImage.y}px`,
                                }} width={backgroundImage.width}
                                alt="BackGround Image" className='h-full min-w-fit ' />}

                        {imageURL && <img style={{
                            position: 'absolute',
                            left: `${imageData.x}px`,
                            top: `${imageData.y}px`
                        }}
                            width={imageData.width} className=' '
                            src={imageURL} alt="Uploaded Image" />}

                        <div style={{
                            position: 'absolute',
                            left: `${textData.x}px`,
                            top: `${textData.y}px`,
                            width: `${textData.width}px`,
                            height: `${textData.height}px`

                        }} className={`border-1 flex items-center justify-center border-slate-950`}>
                            <p className=' p-0 m-0'>Text Here </p>
                        </div>
                    </div>
                </section>
                <section className='col-lg-6'>
                    <p className='text-red-600 h-[30px]'>{errorMessage} </p>
                    <div className='my-3'>
                        Template name :
                        <input type="text" className='p-1 w-44 outline-none mx-2 bg-slate-50 rounded' onChange={(e) => settemplate(e.target.value)} value={templateName} name='templateName' />
                    </div>
                    {/* Background color for the image */}
                    <div className='flex flex-wrap gap-3 justify-between'>
                        <div className='flex items-center gap-3'>
                            Choose a Background Color :
                            <input type="color" value={backgroundColor} onChange={(e) => setBackgroundcolor(e.target.value)} name="" id="" />
                        </div>
                        {obj == undefined ?
                            <button onClick={handleSave} className='p-2 px-3 bg-slate-700 text-white rounded my-2'>Save Template </button>
                            :
                            <button onClick={updateTemplate} className='p-2 px-3 bg-slate-700 text-white rounded my-2'>Update Template </button>}
                    </div>
                    {/* Background Image */}
                    <div className='flex items-center my-2 gap-3'>
                        Background Image option :
                        <input onChange={() => setBgBoolean(true)} type="radio" id='enable' className='m-0' name='a' />
                        <label htmlFor="enable">Enable</label>
                        <input checked={!bgBoolean} onChange={() => setBgBoolean(false)} type="radio" id='disable' name='a' />
                        <label htmlFor="disable">Disable</label>
                        {
                            bgBoolean && "hellow"
                        }
                    </div>
                    <div className='flex flex-wrap gap-3'>
                        {/* Background Image AlignMent and width with x and y axis */}
                        {/* <article className=' border-1 rounded w-fit p-3 my-4'>
                            <p className='fw-bold'>Background Image Details</p>
                            <div>
                                Image Upload :
                                <input type="file" id='bgimg'  onChange={handleImage2} className='mx-2' />
                            </div>
                            <div className='my-2'>
                                X :
                                <input type="number" name='x' onChange={handleInput3} value={backgroundImage.x} className='p-2 w-32 mx-2 bg-slate-50 outline-none rounded ' />
                            </div>
                            <button onClick={() => {
                                setBackgroundImageurl(null)
                                setBackgroundImage({
                                    image: null,
                                    x: 0,
                                })
                                document.getElementById('bgimg').value=''
                            }} className={`p-2 px-3 ms-auto flex ${backgroundImageUrl?'':'d-none'} hover:text-red-600`}> 
                                <DustBin/>
                            </button>
                        </article> */}
                        {/* Image AlignMent and width with x and y axis */}
                        <article className=' border-1 rounded w-fit p-3 my-4'>
                            <p className='fw-bold'>Symbol Image Details</p>
                            <div>
                                {obj != undefined ? "Change Image" : "Image Upload :"}
                                <input type="file" onChange={(e) => {
                                    if (obj != undefined) {
                                        setUpdateImageHandler(e.target.files[0])
                                        uploadImage(0, e)
                                    }
                                    else {
                                        handleImage(e)
                                    }
                                }} className='mx-2' />
                            </div>
                            <div className='my-2'>
                                Width :
                                <input type="number" name='width' onChange={handleInput} value={imageData.width} className='p-2 w-32 mx-2 bg-slate-50 outline-none rounded ' />
                            </div>
                            <div className='my-2'>
                                X :
                                <input type="number" name='x' onChange={handleInput} value={imageData.x} className='p-2 w-32 mx-2 bg-slate-50 outline-none rounded ' />
                            </div>
                            <div className='my-2'>
                                Y :
                                <input type="number" name='y' onChange={handleInput} value={imageData.y} className='p-2 w-32 mx-2 bg-slate-50 outline-none rounded ' />
                            </div>
                        </article>
                        {/* Text Alignment */}
                        <article className=' border-1 rounded w-fit p-3 my-4'>
                            <p className='fw-bold'>Text Alignment</p>
                            <div className='my-2'>
                                Width :
                                <input type="number" name='width' onChange={handleInput2} value={textData.width} className='p-2 w-32 mx-2 bg-slate-50 outline-none rounded ' />
                            </div>
                            <div className='my-2'>
                                Height :
                                <input type="number" name='height' onChange={handleInput2} value={textData.height} className='p-2 w-32 mx-2 bg-slate-50 outline-none rounded ' />
                            </div>
                            <div className='my-2'>
                                X :
                                <input type="number" name='x' onChange={handleInput2} value={textData.x} className='p-2 w-32 mx-2 bg-slate-50 outline-none rounded ' />
                            </div>
                            <div className='my-2'>
                                Y :
                                <input type="number" name='y' onChange={handleInput2} value={textData.y} className='p-2 w-32 mx-2 bg-slate-50 outline-none rounded ' />
                            </div>
                        </article>


                    </div>

                </section>

            </div>
        </div>
    )
}

export default Layout
