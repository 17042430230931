import React, { useEffect, useState } from 'react'
import NavbarCom from '../../Components/Navbar'
import axios from 'axios'
import { hostName } from '../../App'
import { toast } from 'react-toastify'

const ShipRocket = () => {
    const [allShipRocketobj, setallShipRocketobj] = useState([])
    const [shipRocketobj, setShiprocketobj] = useState({
        pickupLocation: '',
        email: '',
        password: '',
        length: null,
        breadth: null,
        height: null,
        weight: null,
        status: false
    })
    const [selectedId, setSelectedId] = useState()
    const handleChanges = (e) => {
        let { value, name } = e.target
        if (name == 'status' && shipRocketobj.status == false)
            value = true
        if (name == 'status' && shipRocketobj.status == true)
            value = false
        setShiprocketobj((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let getShipRocket = () => {
        axios.get(`${hostName}/api/allshipRouter`).then((response) => {
            setallShipRocketobj(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }
    let handleSave = () => {
        console.log(shipRocketobj);
        axios.post(`${hostName}/api/shipRouter`, shipRocketobj).then((response) => {
            console.log(response.data);
            getShipRocket()
            toast.success('Shiprocket values added', {
                autoClose: 2000,
                position: 'top-center'
            })
            setShiprocketobj({
                pickupLocation: '',
                email: '',
                password: '',
                length: null,
                breadth: null,
                height: null,
                weight: null,
                status: false
            })
        }).catch((error) => {
            console.log(error);
        })
    }
    let handleUpdate = () => {
        axios.put(`${hostName}/api/shipRouter`, {
            ...shipRocketobj,
            id: selectedId
        }).then((response) => {
            toast.success('Credentials updated successfully', {
                position: 'top-center',
                autoClose: 3000
            })
            getShipRocket()
            setSelectedId(null)
            setShiprocketobj({
                pickupLocation: '',
                email: '',
                password: '',
                length: null,
                breadth: null,
                height: null,
                weight: null,
                status: false
            })

        }).catch((error) => {
            toast.error('Error aquired', {
                position: 'top-center',
                autoClose: 3000
            })
        })
    }
    useEffect(() => {
        getShipRocket()
    }, [])
    return (
        <div>
            <NavbarCom />
            <h4 className='text-center'>ShipRocket Details</h4>
            <div className='container mx-auto'>
                {/* Declaration */}
                <p>Description : All the details should be the details from the ShipRocket (API user details). Here address is the active address from the Shiprocket u have to enter it.  </p>
                {/* INput details */}
                <div className='flex flex-wrap gap-2 justify-between my-4'>
                    <div className='w-[400px] '>
                        Email <span id='emailerror' className='text-red-600'>* </span>
                        <input name='email' value={shipRocketobj.email} onChange={handleChanges} type="text"
                            placeholder='Email created in ShipRocket'
                            style={{ backgroundColor: "#f6f6f6" }} className='p-3 block w-full my-2 px-3 border-0  rounded outline-none' />
                    </div>
                    <div className='w-[400px] '>
                        Password <span id='passworderror' className='text-red-600'>* </span>
                        <input name='password' value={shipRocketobj.password} onChange={handleChanges} type="text"
                            placeholder='Password created in ShipRocket'
                            style={{ backgroundColor: "#f6f6f6" }} className='p-3 block w-full my-2 px-3 border-0  rounded outline-none' />
                    </div>
                    <div className='w-[400px] '>
                        Pickup address : <span id='addresserro' className='text-red-600'>* </span>
                        <input name='pickupLocation' value={shipRocketobj.pickupLocation} onChange={handleChanges} type="text"
                            placeholder='Active Pickup address name'
                            style={{ backgroundColor: "#f6f6f6" }} className='p-3 block w-full my-2 px-3 border-0  rounded outline-none' />
                    </div>
                    {/* Length */}
                    <div className='w-[400px] '>
                        Length: <span id='lengtherror' className='text-red-600'>* </span>
                        <input name='length' value={shipRocketobj.length} onChange={handleChanges} type="text"
                            placeholder='Enter the lenght in CM'
                            style={{ backgroundColor: "#f6f6f6" }} className='p-3 block w-full my-2 px-3 border-0  rounded outline-none' />
                    </div>
                    {/* hieght */}
                    <div className='w-[400px] '>
                        Hieght <span id='heighterror' className='text-red-600'>* </span>
                        <input name='height' value={shipRocketobj.height} onChange={handleChanges} type="text"
                            placeholder='Enter the hieght in CM '
                            style={{ backgroundColor: "#f6f6f6" }} className='p-3 block w-full my-2 px-3 border-0  rounded outline-none' />
                    </div>
                    {/* breadth */}
                    <div className='w-[400px] '>
                        Breadth <span id='breadtherror' className='text-red-600'>* </span>
                        <input name='breadth' value={shipRocketobj.breadth} onChange={handleChanges} type="text"
                            placeholder='Enter the Breadth in CM'
                            style={{ backgroundColor: "#f6f6f6" }} className='p-3 block w-full my-2 px-3 border-0  rounded outline-none' />
                    </div>
                    {/* weight */}
                    <div className='w-[400px] '>
                        Weight <span id='weighterror' className='text-red-600'>* </span>
                        <input name='weight' value={shipRocketobj.weight} onChange={handleChanges} type="text"
                            placeholder='Enter the weight in KG'
                            style={{ backgroundColor: "#f6f6f6" }} className='p-3 block w-full my-2 px-3 border-0  rounded outline-none' />
                    </div>
                    {/* Active */}
                    <div className='w-[400px] flex gap-2 items-center '>
                        Active status :
                        <div onClick={() => {
                            setShiprocketobj((prev) => ({
                                ...prev,
                                status: prev.status ? false : true
                            }))
                        }}
                            className={` rounded-full ${shipRocketobj.status ? "bg-green-300" : 'bg-red-300'}  transition duration-700 relative w-[60px] h-[30px] border-1 `}>
                            <button name='status'
                                className={` rounded-full absolute w-[26px] h-[26px] top-[1.1px] transition duration-700 ${shipRocketobj.status ? "translate-x-[1.1px] " : ' translate-x-[30px] '} bg-slate-100 `}>
                            </button>

                        </div>
                    </div>
                </div>
                {/* Save buttons and the Update buttons */}
                <div>
                    {!selectedId && <button onClick={handleSave} className='p-2 ms-auto flex px-3 rounded bg-blue-500 text-white'>
                        Save
                    </button>}
                    {selectedId && <button onClick={handleUpdate} className='p-2 px-3 ms-auto flex rounded bg-blue-500 text-white'>
                        Update
                    </button>
                    }
                </div>
                {/* Previously added account */}
                <p className='text-xl fw-semibold '>Previously added Cradentials </p>
                <div className='flex flex-wrap gap-3  '>
                    {/* Cards */}
                    {allShipRocketobj && allShipRocketobj.map((x, index) => {
                        return (
                            <div onClick={() => {
                                if (selectedId != x._id) {
                                    setSelectedId(x._id);
                                    setShiprocketobj(x)
                                }
                                else {
                                    setSelectedId(null)
                                    setShiprocketobj({
                                        pickupLocation: '',
                                        email: '',
                                        password: '',
                                        length: 1,
                                        breadth: 1,
                                        height: 1,
                                        weight: 1,
                                        status: false
                                    })
                                }
                            }} className={`${selectedId == x._id ? 'bg-blue-50 border-blue-400 ' : " "} w-[20rem] p-3 border-1 rounded-2xl cursor-pointer`}>
                                <p>Email : <span> {x.email}</span></p>
                                <p>Password : <span> {x.password}</span></p>
                                <p>Pickup Location name : <span> {x.pickupLocation}</span></p>
                                <p>Status : <span> {x.status ? 'active' : 'Not active'}</span> </p>
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        </div>
    )
}

export default ShipRocket