import React, { useContext, useEffect, useState } from 'react'
import LeftArrow from '../../Svg_Icons/LeftArrow'
import { useNavigate } from 'react-router'
import { StateStore } from '../../Context/StateProvider'
import axios from 'axios'
import { hostName } from '../../App'
import { toast } from 'react-toastify'
import CategoryDesCategory from '../../Components/CategoryDesCategory'

const CategoryDescription = () => {
    let navigate = useNavigate()
    let [categoryDescrptionObject, setCategoryDescriptionObject] = useState({
        category: null,
        desktopimages: null,
        mobileimages: null,
    })
    let { getCategory, categoryDB } = useContext(StateStore)
    useEffect(() => {
        getCategory()
    }, [])
    let handleChanges = (e) => {
        let { files, value, name } = e.target
        if (name == 'desktopimages') {
            setCategoryDescriptionObject((prev) => ({
                ...prev,
                desktopimages: files
            }))
        }
        if (name == 'mobileimages') {
            setCategoryDescriptionObject((prev) => ({
                ...prev,
                mobileimages: files
            }))
        }
        if (name == 'category') {
            setCategoryDescriptionObject((prev) => ({
                ...prev,
                category: value
            }))
        }
    }
    let handleSave=()=>{
        if(categoryDescrptionObject.category!=''&&categoryDescrptionObject.desktopimages!=null){
            const formdata=new FormData()
            formdata.append('category',categoryDescrptionObject.category)
            if(categoryDescrptionObject.desktopimages){
                for (let index = 0; index < categoryDescrptionObject.desktopimages.length; index++) {
                    const element = categoryDescrptionObject.desktopimages[index];
                    formdata.append('desktop',element)
                }
            }
            if(categoryDescrptionObject.mobileimages){
                for (let index = 0; index < categoryDescrptionObject.mobileimages.length; index++) {
                    const element = categoryDescrptionObject.mobileimages[index];
                    formdata.append('mobile',element)
                }
            }
            axios.post(`${hostName}/api/categoryDescription`,formdata).then((res)=>{
                console.log(res.data);
                toast.success('Category Image is uploaded')
            }).catch((error)=>{
                console.log(error);
                toast.error('Error acquired')
            })
        }
    }
    return (
        <div className='container mx-auto'>
            <button onClick={() => navigate('/features')} className='p-2 rounded bg-black text-white my-2 ms-auto flex '><LeftArrow /> </button>
            <main className='flex flex-wrap gap-3 items-center justify-between'>
                <div>
                    Category <span className='text-red-600'>* </span>
                    <select name="category" className='p-2 outline-none bg-slate-50' value={categoryDescrptionObject.category} onChange={handleChanges} id="">
                        <option value="">Select</option>
                        {
                            categoryDB && categoryDB.map((obj) => (
                                <option value={obj._id}>{obj.category} </option>
                            ))
                        }
                    </select>
                </div>
                <div>
                    Choose Desktop description <span className='text-red-600'>*</span>
                    <input type="file" name='desktopimages' className='mx-2' onChange={handleChanges} multiple  />
                </div>
                <div>
                    Choose mobile description <span className='text-red-600'>*</span>
                    <input type="file" name='mobileimages' className='mx-2' onChange={handleChanges} multiple  />
                </div>
                <button onClick={handleSave} className='p-2 px-3 bg-slate-500 text-white rounded ms-auto'>
                    Save
                </button>

            </main>
            <CategoryDesCategory/>
        </div>
    )
}

export default CategoryDescription