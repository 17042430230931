import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { hostName } from '../App'
import { StateStore } from '../Context/StateProvider'
import Loading from './Loading'
import { toast } from 'react-toastify'

const EditCBModal = (props) => {
    let { fetchdata, getCategory, categoryDB } = useContext(StateStore)
    let [upload, setUpload] = useState(true)
    let { show, setshow, pid, type } = props
    let [editObjct, setEditObject] = useState({
        _id: null,
        brand: null,
        category: null,
        variety: null,
        image: null,
    })
    let handlechange = (e) => {
        let { name, value } = e.target
        setEditObject((prev) => (
            {
                ...prev,
                [name]: value
            }))
    }
    let [error, seterror] = useState()
    let [image, setimage] = useState()
    let deleteBrand = () => {
        axios.delete(`${hostName}/api/${type != undefined && type == 'variety' ? 'variety' : 'brand'}/${pid._id}`).then((response) => {
            alert(response.data.message)
            setshow(false)
            fetchdata()
        }).catch((error) => {
            console.log(error);
        })
    }
    let uploadBrand = () => {
        console.log(image);
        if (image!=null) {
            const formadata = new FormData()
            formadata.append('image', image)
            formadata.append('id', pid._id)
            formadata.append('brand', editObjct.brand)
            formadata.append('category', editObjct.category)
            formadata.append('variety', editObjct.variety)

            axios.put(`${hostName}/api/${type != undefined && type == 'variety' ? 'variety' : 'brand'}`, formadata).then((response) => {
                console.log(response);
                toast.success('Value has been updated successfully')
                setshow(false);
                fetchdata()
            }).catch((error) => console.log(error))
        }
        else{
            axios.put(`${hostName}/api/ChangeObject?type=${type != undefined && type == 'variety' ? 'variety' : 'brand'}`,editObjct).then((response)=>{
                console.log(response);
                toast.success('Value has updated successfully')
                setshow(false);
                fetchdata()
            }).catch((error)=>console.log(error))
        }
    }
    useEffect(() => {
        if (pid) {
            setEditObject(pid)
            console.log(pid);
        }
    }, [pid])
    return (
        <div>
            {
                pid != undefined ? <>
                    <Modal show={show} centered className='p-4' onHide={() => setshow(false)}>
                        <Modal.Header className=''>
                            Edit the {type != undefined && type == 'variety' ? 'Variety' : 'Brand'} Image
                            <img onClick={() => setshow(false)} src={require('../Assest/plus.png')} alt="plus" className='rotate-45 ms-auto cursor-pointer' width={20} />
                        </Modal.Header>
                        <Modal.Body>
                            <div className='my-3'>
                                Name :
                                <input onChange={handlechange} type="text" name={type != undefined && type == 'variety' ? 'variety' : 'brand'} className='p-2 px-3 mx-2 rounded outline-none bg-slate-50 '
                                    value={type != undefined && type == 'variety' ? editObjct.variety : editObjct.brand} />
                            </div>
                            <div className='my-3'>
                                Category :
                                <select onChange={handlechange} name='category' type="text" className='p-2 px-3 mx-2 rounded outline-none bg-slate-50 '
                                    value={editObjct.category}  >
                                    {
                                        categoryDB && categoryDB.map((x) => (
                                            <option value={x.category}>{x.category} </option>
                                        ))
                                    }
                                </select>
                            </div>
                            Upload the Image :
                            <input onChange={(e) => {
                                if (e.target.files[0].size > 500 * 1024) {
                                    seterror("Upload the Smaller file")
                                    e.target.value = ''
                                }
                                else {
                                    setUpload(false)
                                    seterror('')
                                    setimage(e.target.files[0])
                                }
                            }} type="file" className='bg-slate-50 p-2 px-3 ' />
                            <p className='h-[30px] text-center text-red-600 '>{error} </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button onClick={uploadBrand} className='bg-slate-600 p-2 px-3 rounded text-white'>Upload</button>
                            <button className='bg-red-500 p-2 px-3 rounded text-white' onClick={deleteBrand}>Delete</button>
                        </Modal.Footer>
                    </Modal>
                </> : ""
            }
        </div>
    )
}

export default EditCBModal
