import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { hostName } from '../../App'
import { toast } from 'react-toastify'

const WorkflowParticularpage = () => {
    let { id } = useParams()
    let [workflowObj, setWorkflowobj] = useState({
        _id: '',
        fileUrl: null,
        description: '',
        type_of_file: 'image',
        category: 'desktop',
    })
    let handleChange = (e,) => {
        let { name, value, files } = e.target
        if (name == 'fileUrl') {
            console.log(files[0]);
            value = files[0]
            console.log(value);
        }
        setWorkflowobj((prev) => ({
            ...prev,
            [name]: value
        }))
        axios.put(`${hostName}/api/workflow/${id}`, {
            [name]:value
        }).then((response) => {
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        if (id) {
            axios.get(`${hostName}/api/workflow/${id}`).then((response) => {
                setWorkflowobj(response.data);
                console.log(response.data);
            }).catch((error) => {
                console.log(error);
            })
        }
    }, [id])
    let navigate = useNavigate()
    let handleDelete=()=>{
        axios.delete(`${hostName}/api/workflow/${id}`).then((response)=>{
            toast.success('Deleted successfully')
            navigate(`/features/workflow`)
        }).catch((error)=>{
            console.log(error);
        })
    }
    return (
        <div>
            <button onClick={() => navigate(`/features/workflow`)} className='p-2 ms-auto flex my-2 px-3 me-3 rounded bg-black text-white'>back </button>
            {
                workflowObj &&
                <main className='container'>
                    <h6> Display on {workflowObj.category}</h6>
                    <div className='my-2'>
                        Mobile or Desktop :
                        <select name="category" onChange={handleChange} className='p-2 outline-none  bg-slate-50 mx-2 rounded' value={workflowObj.category} id="">
                            <option value="desktop">Desktop</option>
                            <option value="mobile">Mobile</option>
                        </select>
                    </div>
                    <div className='my-2'>
                        Image or Video :
                        <select name="type_of_file" onChange={handleChange} className='p-2 outline-none  bg-slate-50 mx-2 rounded' value={workflowObj.type_of_file} id="">
                            <option value="image">Image</option>
                            <option value="video">Video</option>
                        </select>
                    </div>
                    <div>
                        Upload the Image or video:
                        <input type="file" name='fileUrl' onChange={(e)=>{
                            const fomdata=new FormData()
                            fomdata.append('image',e.target.files[0])
                            axios.put(`${hostName}/api/uploadvideoflow/${id}`,fomdata).then((response)=>{
                                setWorkflowobj(response.data)
                            }).catch((error)=>console.log(error))
                        }} className='p-2 outline-none bg-slate-50 mx-2 rounded' />
                    </div>
                    {
                        workflowObj.type_of_file == 'image' && <img className={` ${workflowObj.category == 'mobile' && 'w-[400px]'} `} src={workflowObj.fileUrl} alt="Image" />
                    }
                    {
                        workflowObj.type_of_file == 'video' && <video controls muted autoPlay className={` ${workflowObj.category == 'mobile' && 'w-[400px]'} `} src={workflowObj.fileUrl} alt="Image" />
                    }
                    Description :
                    <textarea name="description" onChange={handleChange} className='outline-none p-2 border-2 rounded w-full my-2' value={workflowObj.description} id=""></textarea>
                    <button onClick={handleDelete} className='p-2 px-3 bg-red-600 rounded text-white'>Delete </button>

                </main>
            }

        </div>
    )
}

export default WorkflowParticularpage