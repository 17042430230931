import React, { useState } from 'react'
import { Navbar } from 'react-bootstrap'
import NavbarCom from '../Components/Navbar'
import ShortCutReport from '../Components/ShortCutReport'
import Features from '../Components/Features'
import axios from 'axios'
import { hostName } from '../App'

const Dashboard = (e) => {

  return (
    <div className='min-h-[100vh] bg-slate-50 '>
      <NavbarCom />
      {/* Shortcut keys */}
      <ShortCutReport />
      <Features />

    </div>
  )
}

export default Dashboard