import axios from 'axios'
import React, { useState } from 'react'
import { hostName } from '../../App'
import { toast } from 'react-toastify'
import WorkflowCom from '../../Components/WorkflowCom'

const WorkflowAddingpage = () => {
    let [workflowObj, setWorkflowobj] = useState({
        fileUrl: null,
        description: '',
        type_of_file: 'image',
        category: 'desktop',
    })
    let handleChange = (e,) => {
        let { name, value, files } = e.target
        if (name == 'fileUrl') {
            console.log(files[0]);
            value = files[0]
            console.log(value);
        }
        setWorkflowobj((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let [loading,setloading]=useState(false)
    let handleSave = () => {
        const formdata = new FormData()
        if (workflowObj.category != '' && workflowObj.fileUrl != null,
            workflowObj.description != '' && workflowObj.type_of_file != ''
        ) {
            setloading(true)
            console.log(workflowObj.fileUrl);
            formdata.append('image',workflowObj.fileUrl)
            formdata.append('category',workflowObj.category)
            formdata.append('description',workflowObj.description)
            formdata.append('type_of_file',workflowObj.type_of_file)
            axios.post(`${hostName}/api/workflow`, formdata).then((response) => {
                toast.success('Adding in the page')
                setWorkflowobj({
                    fileUrl: null,
                    description: '',
                    type_of_file: 'image',
                    category: 'desktop',
                })
                window.location.reload()
                setloading(false)
            }).catch((error) => {
                console.log(error);
                setloading(false)
            })
        }
        else{
            toast.warning('Fill all the Inputs')
        }
    }
    return (
        <div>
            <main className='container'>
                <h5>Make the Work flow with the Description </h5>
                <article className='flex gap-3 flex-wrap items-center my-3 '>
                    <div>
                        Mobile or Desktop :
                        <select name="category" onChange={handleChange} className='p-2 outline-none  bg-slate-50 mx-2 rounded' value={workflowObj.category} id="">
                            <option value="desktop">Desktop</option>
                            <option value="mobile">Mobile</option>
                        </select>
                    </div>
                    <div>
                        Image or Video :
                        <select name="type_of_file" onChange={handleChange} className='p-2 outline-none  bg-slate-50 mx-2 rounded' value={workflowObj.type_of_file} id="">
                            <option value="image">Image</option>
                            <option value="video">Video</option>
                        </select>
                    </div>
                    <div>
                        Upload the Image or video :
                        <input type="file" name='fileUrl' onChange={handleChange} className='p-2 outline-none bg-slate-50 mx-2 rounded' />
                    </div>
                    <div className='flex items-start w-full  sm:w-[400px] '>
                        Description
                        <textarea name="description" onChange={handleChange} className='w-full outline-none border-2 p-2 rounded mx-2' value={workflowObj.description} id=""></textarea>

                    </div>
                </article>
                <button onClick={handleSave} disabled={loading} className='p-2 px-3 bg-blue-600 text-white rounded ms-auto flex'> {loading ? "Loading...":"add"} </button>
            </main>
            <WorkflowCom/>
        </div>
    )
}

export default WorkflowAddingpage