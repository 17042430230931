import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import LeftArrow from '../../Svg_Icons/LeftArrow'
import axios from 'axios'
import { hostName } from '../../App'
import { toast } from 'react-toastify'
import { StateStore } from '../../Context/StateProvider'
import BackgroundCom from '../../Components/BackgroundCom'

const BackgroundUploads = () => {
    let navigate = useNavigate()
    let { backgroundImage, getBackground } = useContext(StateStore)
    let [file, setfile] = useState()
    let handleSubmit = () => {
        let formdata = new FormData()
        formdata.append('image', file)
        axios.post(`${hostName}/api/background`, formdata).then((response) => {
            toast.success('Image has been uploaded', {
                position: 'top-center',
                autoClose: 3000,
                theme: 'colored'
            })
            document.getElementById('file').value = ''
            getBackground()
        }).catch((error) => {
            toast.error(error, {
                position: 'top-center',
                autoClose: 3000,
                theme: 'colored'
            })
        })
    }
    return (
        <div className='p-3 '>
            <button onClick={() => navigate('/features')} className='p-2 rounded bg-black text-white my-2 ms-auto flex '>
                <LeftArrow />
            </button>
            <h4>Upload the Image  </h4>
            <div className='my-2 '>
                <input id='file' onChange={(e) => setfile(e.target.files[0])} type="file" />
                <button onClick={handleSubmit} className='p-2 px-3 rounded bg-slate-500 text-white '>Upload </button>
            </div>
            <hr />
            <h4 className='my-4'>Background Images </h4>
            <BackgroundCom />





        </div>
    )
}

export default BackgroundUploads