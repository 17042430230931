import axios from 'axios'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import VerificationEmailModel from '../Components/VerificationEmail'
import { hostName } from '../App'
import { toast } from 'react-toastify'

const AdminLogin = () => {
    let navigate = useNavigate()
    let [email, setemail] = useState("")
    let [show, setshow] = useState(false)
    let [showpassword, setshowpassword] = useState(false)
    let [forgotModal, setForgotmodal] = useState(false)
    let [password, setpassword] = useState("")
    let [otp, setotp] = useState()
    let [EnterOtp, setEnterotp] = useState()
    let [showmodal, setshowmodal] = useState(false)
    let [showadminregister, setshowadmin] = useState(false)
    let [adminSignup, setAdminSignUp] = useState({
        adminUser: "",
        email: "",
        phone: '',
        password: ""
    })
    let handlechangeAdmin = (e) => {
        setAdminSignUp((prev) => ({
            ...prev,
            [e.target.name]: e.target.value

        }))
    }
    let Login = (e) => {
        e.preventDefault()
        axios.get(`${hostName}/api/admin?emailNum=${email}&password=${password}`).then((response) => 
            {
                document.getElementById('errorlogin').innerHTML = ''
                sessionStorage.setItem('admin', JSON.stringify(response.data))
                window.location.reload()
                navigate('/')
            })
            .catch((error) => {
                document.getElementById('errorlogin').innerHTML = error.response.data
                console.log(error.response.data);
            })
    }
    let optcheck = (e) => {
        e.preventDefault()
        if (adminSignup.adminUser && adminSignup.email && adminSignup.password && adminSignup.phone) {
            document.getElementById('errorRegister').innerHTML = ""
            axios.post(`${hostName}/api/adminCheck`).then((response) => {
                alert(response.data.message)
                setotp(response.data.otp)
                console.log(response.data.otp);
                setshowmodal(true)
            }).catch((error) => {
                console.log(error);
            })
        }
        else
            document.getElementById('errorRegister').innerHTML = "Enter all the inputs"

    }
    let Register = (e) => {
        e.preventDefault()
        if (otp == EnterOtp) {
            axios.post(`${hostName}/api/admin`, adminSignup).then((response) => {
                alert("Account has been registered")
                sessionStorage.setItem('admin', JSON.stringify(response.data))
                console.log(response.data);
                window.location.reload()
                navigate('/')
            }).catch((err) => {
                console.log(err);
            })
        }
    }
    return (
        <div className='flex poppins h-[100vh]'>
            <div className={`col-12 col-md-8 ${showadminregister ? "d-none" : ""} col-lg-6 col-xl-5 shadow border-1 p-3 my-auto text-center mx-auto rounded`} >
                <h3>Admin Login</h3>
                <form onSubmit={Login} action="">

                    <div className='my-3'>
                        <p className='text-start'>Admin mail or phone <sup className='text-red-500 text-lg'>*</sup></p>
                        <input onChange={(e) => setemail(e.target.value)} type="text" placeholder='Enter the Mail or the phone'
                            className='p-2 px-3 w-full outline-none rounded border-1  ' />
                    </div>
                    <div className='my-3'>
                        <p className='text-start'>Password <sup className='text-red-500 text-lg'>*</sup></p>
                        <div className='flex items-center border-1 rounded pe-4 '>
                            <input onChange={(e) => setpassword(e.target.value)} type={`${showpassword ? 'text' : 'password'}`} placeholder='Enter the password'
                                className='p-2 rounded border-1 w-full border-none outline-none' />
                            {/* To show password */}
                            <button type='button' onClick={() => {
                                setshowpassword(true); setTimeout(() => {
                                    setshowpassword(false)
                                }, 2000);
                            }} className={`${showpassword ? 'd-none' : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`${showpassword ? 'd-none' : ''} bi bi-eye`} viewBox="0 0 16 16">
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                </svg>
                            </button>
                            {/* To hide the password */}
                            <button type='button' onClick={() => setshowpassword(false)} className={`${showpassword ? '' : 'd-none'}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                    <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                </svg>
                            </button>


                        </div>
                    </div>
                    <p id='errorlogin' className='h-[30px] text-red-700 '></p>
                    <div className='flex justify-between my-3 '>
                        <button type='button' onClick={() => setshowadmin(true)}
                            className='text-blue-600 text-decoration-underline'>Sign up</button>
                        <button type='button' className='text-blue-600 text-decoration-underline' onClick={() => setshow(true)} >Forgot password? </button>
                    </div>
                    <button type='submit' className='px-3 p-2 bg-red-500 text-white rounded'>Log in</button>


                </form>
            </div>
            {/* Registeration form */}

            <div className={`col-md-8 ${showadminregister ? "" : "d-none"} col-lg-6 col-xl-5 shadow border-1 p-3 my-auto text-center mx-auto rounded`} >
                <h3>Admin SignUp</h3>
                <form onSubmit={optcheck} action="">
                    <div className='my-3'>
                        <p className='text-start'>Admin Name  <sup className='text-red-500 text-lg'>*</sup></p>
                        <input name='adminUser' onChange={(e) => handlechangeAdmin(e)} type="text" placeholder='Enter the Name'
                            className='p-2 px-3 w-full outline-none rounded border-1  ' />
                    </div>
                    <div className='my-3'>
                        <p className='text-start'>Phone  <sup className='text-red-500 text-lg'>*</sup></p>
                        <input name='phone' onChange={(e) => handlechangeAdmin(e)} type="text" placeholder='Enter the phone'
                            className='p-2 px-3 outline-none w-full rounded border-1  ' />
                    </div>
                    <div className='my-3'>
                        <p className='text-start'>Admin mail  <sup className='text-red-500 text-lg'>*</sup></p>
                        <input name='email' onChange={(e) => handlechangeAdmin(e)} type="email" placeholder='Enter the Mail or the phone'
                            className='p-2 px-3 outline-none w-full rounded border-1  ' />
                    </div>
                    <div className='my-3'>
                        <p className='text-start'>Password <sup className='text-red-500 text-lg'>*</sup></p>

                        {/* Password input */}
                        <div className='flex items-center border-1 rounded pe-4 '>
                            <input name='password' onChange={(e) => handlechangeAdmin(e)} type={`${showpassword ? 'text' : 'password'}`} placeholder='Enter the password'
                                className='p-2 rounded border-1 w-full border-none outline-none' />
                            {/* To show password */}
                            <button type='button' onClick={() => {
                                setshowpassword(true); setTimeout(() => {
                                    setshowpassword(false)
                                }, 2000);
                            }} className={`${showpassword ? 'd-none' : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`${showpassword ? 'd-none' : ''} bi bi-eye`} viewBox="0 0 16 16">
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                </svg>
                            </button>
                            {/* To hide the password */}
                            <button type='button' onClick={() => setshowpassword(false)} 
                            className={`${showpassword ? '' : 'd-none'}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                    <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                </svg>
                            </button>


                        </div>
                    </div>
                    <p id='errorRegister' className='h-[30px] text-red-700 '></p>
                    <div className='flex justify-end my-3 '>
                        <button className='text-blue-700 text-decoration-underline ' onClick={() => setshowadmin(false)} >Log in page</button>

                    </div>
                    <button type='submit' className='px-3 p-2 bg-red-500 text-white rounded'>Register</button>
                </form>
            </div>
            {/* OTP model */}

            <Modal
                show={showmodal}
                onHide={() => setshowmodal(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className=''
            >
                <Modal.Header closeButton>
                    Admin registeration otp
                </Modal.Header>
                <Modal.Body className=''>

                    <h4 className='text-center'>Enter the OTP</h4>
                    <input onChange={(e) => setEnterotp(e.target.value)} placeholder='Enter the OTP' type="email"
                        className='rouned-full mt-4 bg-slate-100 w-fit text-center mx-auto flex rounded-full p-3 outline-violet-600 border-0 ' />
                    <p className='h-[30px] text-center mt-3 text-red-500' id="errorOTP" ></p>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-around'>
                    <button onClick={optcheck} className='px-3 bg-blue-500 text-white p-2 rounded'>Regenerate</button>
                    <button onClick={Register} className='px-3 bg-green-500 text-white p-2 rounded'>Register</button>
                </Modal.Footer>
            </Modal>
            <VerificationEmailModel who="admin" show={show} onHide={() => setshow(false)} />

        </div >
    )
}

export default AdminLogin