import React, { useContext, useState } from "react";
import NavbarCom from "../Components/Navbar";
import { useNavigate } from "react-router";
import axios from "axios";
import { hostName } from "../App";
import { StateStore } from "../Context/StateProvider";
import Loading from "../Components/Loading";
import PlusIcon from "../Svg_Icons/PlusIcon";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const CreateCategory = () => {
  let navigate = useNavigate();
  let [showModel, setShowModel] = useState()
  let { categoryDB, setApi, fetchdata, apiCalling,getCategory,getProduct,} = useContext(StateStore)
  let [categoryObj, setObj] = useState({
    category: null,
    image: null
  })
  let [categoryobjEdit, setCategoryEdit] = useState({
    category: null,
    image: null,
    _id: null
  })
  let handlechange = (e) => {
    let { name, value } = e.target
    setObj((prev) => {
      if (name == 'image')
        return { ...prev, [name]: e.target.files[0] }
      return { ...prev, [name]: value }
    })
  }
  let handlechange2 = (e) => {
    let { name, value } = e.target
    setCategoryEdit((prev) => {
      if (name == 'image')
        return { ...prev, [name]: e.target.files[0] }
      return { ...prev, [name]: value }
    })
  }
  let addCategory = () => {
    let formdata = new FormData()
    formdata.append("category", categoryObj.category)
    formdata.append("image", categoryObj.image)
    if (categoryObj.category != null && categoryObj.image != null) {
      setApi(false)
      document.getElementById('errormessage').innerHTML = ""
      axios.post(`${hostName}/api/category`, formdata).then((response) => {
        console.log(response.data);
        // fetchdata()
        setApi(true)
        getCategory()
        return
      }).catch((error) => {
        console.log(error);
        return
      })
    }
    document.getElementById('errormessage').innerHTML = "Enter the Fields"
  }
  let updateCategory = () => {
    if (typeof categoryobjEdit.image == 'object') {
      // alert('file')
      const formData = new FormData()
      formData.append('image', categoryobjEdit.image)
      formData.append('category', categoryobjEdit.category)
      formData.append('id', categoryobjEdit._id)
      axios.put(`${hostName}/api/category`, formData).then((response) => {
        toast.success('Category Updated successfully', {
          position: 'top-center',
          autoClose: 3000,
          theme: 'colored'
        })
        setShowModel(false)
        fetchdata()

      }).catch((error) => {
        toast.error(error.response.data, {
          position: 'top-center',
          autoClose: 3000,
          theme: 'colored'
        })
      })
    }
    else {
      axios.put(`${hostName}/api/categoryName`, categoryobjEdit).then((response) => {
        toast.success('Category Updated successfully', {
          position: 'top-center',
          autoClose: 3000,
          theme: 'colored'
        })
        setShowModel(false)
        fetchdata()
      }).catch((error) => {
        toast.error(error.response.data, {
          position: 'top-center',
          autoClose: 3000,
          theme: 'colored'
        })
      })
    }
  }
  let deleteCategory = () => {
    axios.delete(`${hostName}/api/category/${categoryobjEdit._id}`).then((response) => {
      toast.error('Category Deleted successfully', {
        position: 'top-center',
        autoClose: 3000,
        theme: 'colored'
      })
      setShowModel(false)
      fetchdata()
    }).catch((error) => {
      toast.error(error.response.data, {
        position: 'top-center',
        autoClose: 3000,
        theme: 'colored'
      })
    })
  }
  return (
    <div className="poppins min-h-[100vh] bg-slate-100">
      {
        apiCalling ? <>
          <NavbarCom />
          <button
            onClick={() => navigate("/createBrand")}
            className="p-2 px-3 rounded bg-violet-600 text-white mx-4 flex items-center gap-3"
          > <PlusIcon />
            {" "}
            Create Brand
          </button>

          {/* Adding the Category */}
          <div className="container my-4 p-4 bg-white rounded-xl">
            <h4 className="fw-light p-3">Create Category</h4>

            <div className="flex flex-wrap my-3 justify-around">
              <div>
                Category Name :
                <input
                  name="category"
                  onChange={handlechange}
                  required
                  type="text"
                  placeholder="Enter the Category name"
                  className="rounded text-sm bg-slate-50 outline-none px-3 block my-2 p-3 "
                />
              </div>
              <div>
                Category logo :
                <input
                  type="file"
                  name="image"
                  required
                  onChange={handlechange}
                  placeholder="Enter the Category name"
                  className="rounded text-sm bg-slate-50 outline-none px-3 block my-2 p-3 "
                />
              </div>
            </div>
            <p className="h-[30px] text-red-500 text-center" id="errormessage"></p>
            <button onClick={addCategory} className="ms-auto flex p-2 px-3 bg-green-500 text-white rounded">
              Add
            </button>
          </div>
          {/* Viewing card   */}
          {/* List of category */}

          <div className="container bg-white mx-auto rounded-xl p-3">
            <h4 className="fw-light p-3">List of Categories</h4>
            <div className="flex mx-auto flex-wrap gap-3 ">
              {/* Card */}
              {categoryDB != undefined && categoryDB.length > 0 ?
                categoryDB.map((x) => {
                  return (
                    <div onClick={() => { setCategoryEdit(x); setShowModel(true) }} className="w-[8rem] hover:scale-[1.05] transition duration-500 cursor-pointer mx-3 border-slate-700 rounded  border-1  ">
                      <img
                        className="object-cover rounded-t w-[8rem] h-[8rem] "
                        src={x.image}
                        alt="Logo"
                      />
                      <p className="text-center my-2">{x.category}</p>
                    </div>
                  )
                }) :
                <div className='w-full'>
                  <p className='text-center'>List is empty, add it</p>
                </div>
              }

            </div>
          </div>
        </> : <Loading />
      }
      {
        showModel && <Modal centered show={showModel} onHide={() => setShowModel(false)} >
          <Modal.Header closeButton>
            Update the Category
          </Modal.Header>
          <Modal.Body>
            <div>
              Name :
              <input type="text" value={categoryobjEdit.category} className="p-2 outline-none bg-slate-50 mx-2 rounded"
                onChange={handlechange2} name="category" />
            </div>
            <div>
              Image :
              <input type="file" className="p-2 my-4 outline-none bg-slate-50 mx-2 rounded"
                onChange={handlechange2} name="image" />
            </div>

          </Modal.Body>
          <Modal.Footer>
            <button onClick={updateCategory} className="p-2 px-3 bg-blue-500 text-white rounded hover:bg-blue-600">Update </button>
            <button onClick={deleteCategory} className="p-2 px-3 bg-red-500 text-white rounded hover:bg-red-600">Delete </button>
          </Modal.Footer>
        </Modal>
      }


    </div>
  );
};

export default CreateCategory;
