import React from 'react'
import { Route, Routes } from 'react-router'
import Allproduct from './Products/Allproduct'
import EditProduct from './Products/EditProduct'
import AddProduct from './Products/CreateProduct'
import CreateVariety from './CreateVariety'
import CreateBrand from './CreateBrand'
import ModelCreation from './Products/ModelCreation'
import CreateModel from './Products/CreateModel'

const Product = () => {
  return (
    <div>
        <Routes>
            <Route path='/' element={<Allproduct/>}/>
            <Route path='/addproduct' element={<AddProduct/>}/>
            <Route path='/variety' element={<CreateVariety/>}/>
            <Route path='/addmodel' element={<ModelCreation/>}/>
            <Route path='/createModel' element={<CreateModel/>}/>
            <Route path='/:id' element={<EditProduct/>}/>
        </Routes>
    </div>
  )
}

export default Product