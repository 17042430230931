
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import AdminLogin from './Pages/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboard from './Pages/Dashboard';
import Protect from './Pages/Protect';
import Product from './Pages/Product';
import CreateBrand from './Pages/CreateBrand';
import CreateCategory from './Pages/CreateCategory';
import StateProvider from './Context/StateProvider';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './Pages/Layouts/Layout'
import Template from './Pages/Template/Template';
import { ToastContainer } from 'react-toastify';
import Customers from './Pages/Customer/Customers';
import OrderList from './Pages/OrderList/OrderList';
import ClipPath from './Pages/Layouts/ClipPath';
import CartListPage from './Pages/Customer/CartListPage';
import ParticularOrder from './Pages/OrderList/ParticularOrder';
import AddingBanner from './Pages/Extras/AddingBanner';
import Extras from './Pages/Extras/Extras';
import Coupon from './Pages/Extras/Coupon';
import UserDetails from './Pages/Customer/UserDetails';
import ShipRocket from './Pages/Extras/ShipRocket';
import Sales from './Pages/OrderList/Sales';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js/auto";




export const mobileFrame = "w-[320px] h-[650px] ";

export const hostName='https://backendapi.printlele.com'
// export const hostName='http://localhost:3020'
function App() {
  
  return (
    <>
      <BrowserRouter>
      <StateProvider>
        <ToastContainer autoClose={3000} theme='colored' position='top-center' />
        <Routes>
          <Route path='/*' element={<Protect Child={Dashboard}/>}/>
          <Route path='/product/*' element={<Protect Child={Product}/>}/>
          <Route path='/createBrand' element={<Protect Child={CreateBrand}/>}/>
          <Route path='/createCategory' element={<Protect Child={CreateCategory}/>}/>
          <Route path='/layout' element={<Protect Child={Layout}/>}/>
          <Route path='/template/*' element={<Protect Child={Template}/>}/>
          <Route path='/customer' element={<Protect Child={Customers}/>}/>
          <Route path='/customer/:id' element={<Protect Child={UserDetails}/>}/>
          <Route path='/sales' element={<Protect Child={Sales}/>}/>

          <Route path='/orders/' element={<Protect Child={OrderList}/>}/>
          <Route path='/orders/:id' element={<Protect Child={ParticularOrder}/>}/>
          <Route path='/clipath' element={<ClipPath/>}/>
          <Route path='/cartList' element={<Protect Child={CartListPage}/>}/>
          <Route path='/features/*' element={<Protect Child={Extras}/>}/>
          <Route path='/shiprocket' element={<Protect Child={ShipRocket}/>}/>



          <Route />
        </Routes>
        </StateProvider>
      </BrowserRouter>

    </>
  );
}

export default App;
