import React, { useContext, useEffect, useState } from 'react'
import NavbarCom from '../../Components/Navbar'
import axios from 'axios'
import { hostName } from '../../App'
import { StateStore } from '../../Context/StateProvider'
import { ToastContainer, toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import SearchIcon from '../../Svg_Icons/SearchIcon'

const CreateModel = () => {
    const { brandDB, modelDb, getModelDb } = useContext(StateStore)
    let [filterArray, setFilterArray] = useState([])
    const [modelObj, setModelObj] = useState({
        brandName: '',
        modelName: '',
        newStatus: false
    })
    const [editingObj, setEditingObj] = useState({
        brandName: '',
        modelName: '',
        newStatus: null,
        _id: null
    })
    const [modelEditing, setModelEditing] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const handleChange = (e) => {
        let { name, value } = e.target
        if (name == 'newStatus' && modelObj.newStatus == false) {
            value = true
        }
        else if (name == 'newStatus' && modelObj.newStatus == true) {
            value = false
        }
        setModelObj((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    const handleChange2 = (e) => {
        let { name, value } = e.target
        if (name == 'newStatus' && !editingObj.newStatus) {
            value = true
        }
        else if (name == 'newStatus' && editingObj.newStatus) {
            value = false
        }
        setEditingObj((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    const handleSave = () => {
        if (modelObj.brandName!='' && modelObj.modelName!='') {
            setErrorMessage('')
            axios.post(`${hostName}/api/mobileModel`, modelObj).then((response) => {
                console.log(response.data);
                setModelObj((prev)=>(
                    {
                        ...prev,
                        modelName:''
                    }
                ))
                toast.success('Model has successFully added', {
                    theme: 'colored',
                    position: 'top-center',
                    autoClose: 5000
                })
                getModelDb()
            }).catch((error) => {
                console.log(error);
            })
        }
        else {
            setErrorMessage("* Fill the Required fields")
        }
    }
    let handleUpdate = () => {
        axios.put(`${hostName}/api/modelName`, editingObj).then((response) => {
            console.log(response.data);
            toast.success('Modal updated successfully', {
                position: 'top-center',
                autoClose: 3000,
                theme: 'colored'
            })
            getModelDb()
            setModelEditing(false)
        }).catch((error) => {
            toast.error(error.response.data.message, {
                position: 'top-center',
                autoClose: 5000,
                theme: 'colored'
            })
        })
    }
    let handleDelete = () => {
        axios.delete(`${hostName}/api/modelName/${editingObj._id}`,).then((response) => {
            console.log(response.data);
            toast.success(response.data.message, {
                position: 'top-center',
                autoClose: 5000,
                theme: 'colored'
            })
            getModelDb()
            setModelEditing(false)

        }).catch((error) => {
            toast.error(error.response.data.message, {
                position: 'top-center',
                autoClose: 5000,
                theme: 'colored'
            })
        })
    }
    useEffect(() => {
        if (modelDb) {
            setFilterArray(modelDb)
        }
    }, [modelDb])
    let [search, setsearch] = useState('')
    let sortArray = (value) => {
        if (value == 'AZ') {
            setFilterArray([...modelDb].sort((a, b) => {
                if (a.brandName+a.modelName < b.brandName+b.modelName) return -1;
                if (a.brandName+a.modelName > b.brandName+b.modelName) return 1;
                return 0;
            }
            ))
        }
        else if (value == 'ZA') {
            setFilterArray([...modelDb].sort((a, b) => {
                if (a.brandName+a.modelName > b.brandName+b.modelName) return -1;
                if (a.brandName+a.modelName < b.brandName+b.modelName) return 1;
                return 0;
            }))

        }
        else {
            setFilterArray(modelDb)
        }
    }
    let searchModel = () => {
        let word = search.toLowerCase()
        if (search != '') {
            let searcharry = [...modelDb].filter((x) => x.brandName.toLowerCase().indexOf(word) != -1 || x.modelName.toLowerCase().indexOf(word) != -1)
            setFilterArray(searcharry)
        }
        else
            setFilterArray(modelDb)
    }
    return (
        <div className='poppins'>
            <NavbarCom />
            <ToastContainer />
            <div className='container mx-auto'>
                <h4 className='text-center'>Create a Model Name </h4>
                <section className='flex flex-wrap justify-between items-center p-3 border-1 rounded'>
                    <div className=''>
                        Choose the Brand : <span className='text-red-600'> * </span>
                        <select value={modelObj.brandName} onChange={handleChange} name="brandName" className='outline-none mx-2 border-1  rounded p-1' id="">
                            <option value="">Select</option>
                            {
                                brandDB && brandDB.map((x) => {
                                    return <option value={x.brand}>{x.brand} </option>
                                })
                            }
                        </select>
                    </div>
                    <div className=' my-3'>
                        Enter the Model Name : <span className='text-red-600'>*</span>
                        <input value={modelObj.modelName} onChange={handleChange} onKeyDown={(e)=>{
                            if(e.key=='Enter'){
                                handleSave()
                            }
                        }}
                         name="modelName" type="text" className='outline-none p-2 border-1 rounded  mx-2' />
                    </div>
                    <div className='flex items-center gap-2'>
                        New Model :
                        <div className={` rounded-full ${modelObj.newStatus ? "bg-green-300" : 'bg-red-300'}  transition duration-700 relative w-[60px] h-[30px] border-1 `}>
                            <button name='newStatus' onClick={handleChange}
                                className={` rounded-full absolute w-[26px] h-[26px] top-[1.1px] transition duration-700 ${modelObj.newStatus ? "translate-x-[1.1px] " : ' translate-x-[30px] '} bg-slate-100 `}>
                            </button>

                        </div>


                    </div>
                    <p className='text-red-600 h-[30px] w-[200px] '>{errorMessage} </p>
                    <button onClick={handleSave} type='submit' className='p-2 px-3 bg-green-600 rounded text-white'>Save </button>
                </section>
            </div>
            {/* Already Added model */}
            <div className='container mx-auto my-3'>
                {/* Filteration */}
                <section className="ms-auto flex flex-wrap gap-3 w-fit ">
                    <div className="ms-auto flex w-fit p-2 px-3  border-1 border-slate-400 rounded-full">
                        <input type="text" onKeyUp={(e) => {
                            if (e.key == 'Enter')
                                searchModel()
                        }} value={search} onChange={(e) => setsearch(e.target.value)} className=" outline-none " placeholder="Search" />
                        <button onClick={searchModel}>
                            <SearchIcon />
                        </button>
                    </div>
                    <select name="" onChange={(e) => sortArray(e.target.value)} className="p-2 ms-auto outline-none border-1 px-3 border-slate-400 rounded-full " id="">
                        <option value=""> Select</option>
                        <option value="AZ">Sort by A-Z</option>
                        <option value="ZA">Sort by Z-A</option>
                    </select>
                </section>

                <h4 className='px-2 my-2'>Model Already added </h4>

                <div className='flex flex-wrap gap-3 my-2'>
                    {
                        filterArray && filterArray.length > 0 && filterArray.map((x) => {
                            console.log(x);
                            return (
                                <div className='bg-slate-50 relative p-2 px-3 rounded-full w-fit'>
                                    <button className={`absolute top-2 right-2  ${x.newStatus ? 'bg-green-400' : 'd-none'} w-[10px] h-[10px] rounded-full `}> </button>
                                    <button onClick={() => { setEditingObj(x); setModelEditing(true) }} className='m-0'>{x.brandName} {x.modelName} </button>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
            {modelEditing && <Modal centered show={modelEditing} onHide={() => setModelEditing(false)}>
                <Modal.Header className='' closeButton>
                    Make a Changes
                </Modal.Header>
                <Modal.Body>
                    <div className=''>
                        Choose the Brand : <span className='text-red-600'> * </span>
                        <select value={editingObj.brandName} onChange={handleChange2} name="brandName" className='outline-none mx-2 border-1  rounded p-1' id="">
                            <option value="">Select</option>
                            {
                                brandDB && brandDB.map((x) => {
                                    return <option value={x.brand}>{x.brand} </option>
                                })
                            }
                        </select>
                    </div>
                    <div className=' my-3'>
                        Enter the Model Name : <span className='text-red-600'>*</span>
                        <input value={editingObj.modelName} onChange={handleChange2} name="modelName" type="text" className='outline-none p-2 border-1 rounded  mx-2' />
                    </div>
                    <div className='flex items-center gap-2'>
                        New Model :
                        <div className={` rounded-full ${editingObj.newStatus ? "bg-green-300" : 'bg-red-300'}  transition duration-700 relative w-[60px] h-[30px] border-1 `}>
                            <button name='newStatus' onClick={handleChange2}
                                className={` rounded-full absolute w-[26px] h-[26px] top-[1.1px] transition duration-700 ${editingObj.newStatus ? "translate-x-[1.1px] " : ' translate-x-[30px] '} bg-slate-100 `}>
                            </button>
                        </div>
                    </div>
                    <p className='text-red-600 h-[30px] w-[200px] '>{errorMessage} </p>
                    <button onClick={handleUpdate} className='p-2 px-3 mx-2 bg-green-600 rounded text-white'>Update </button>
                    <button onClick={handleDelete} className='p-2 px-3 bg-red-600 rounded text-white'>Delete </button>



                </Modal.Body>

            </Modal>}

        </div>
    )
}

export default CreateModel
