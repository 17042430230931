import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { hostName } from '../App'
import { ToastContainer, toast } from 'react-toastify'

const MainImageModal = (props) => {

  let { show, type, setshow, pid, url, fn } = props
  let [image, setimage] = useState()
  let [bool, setbool] = useState(true)
  let clean = () => {
    setbool(true)
    setimage(null)
    fn(); //function to call the product with id so that it will reload and send the current data
    setshow(false);
  }
  let changeSubImage = () => {
    const formData = new FormData()
    formData.append('mainImage', image)
    formData.append('url', url)
    axios.put(`${hostName}/api/productImage/${pid}`, formData).then((response) => {
      console.log(response.data);
      toast.success('Image has been changed', {
        theme: 'colored',
        position: 'top-center',
        autoClose: 5000

      });
      clean();
    }).catch((error) => {
      console.log(error);
      toast.error('Something went wrong', {
        theme: 'colored',
        position: 'top-center',
        autoClose: 5000
      });
    })
  }
  let addSubImage = () => {
    const formData = new FormData()
    formData.append('mainImage', image)
    axios.put(`${hostName}/api/addSubImage/${pid}`, formData).then((response) => {
      console.log(response.data);
      toast.success('Image has been added', {
        theme: 'colored',
        position: 'top-center',
        autoClose: 5000
      });
      clean();
    }).catch((error) => {
      console.log(error);
      toast.error('Something went wrong', {
        theme: 'colored',
        position: 'top-center',
        autoClose: 5000
      });
    })
  }
  let ChangeMainImage = () => {
    const formData = new FormData()
    formData.append('mainImage', image)
    formData.append('url', url)
    axios.put(`${hostName}/api/mainImage/${pid}`, formData).then((response) => {
      console.log(response.data);
      toast.success('Image has been changed', {
        theme: 'colored',
        position: 'top-center',
        autoClose: 5000
      });
      clean();
    }).catch((error) => {
      console.log(error);
      toast.error('Something went wrong', {
        theme: 'colored',
        position: 'top-center',
        autoClose: 5000
      });
    });
  }
  let deleteSubImage = () => {
    const formData = new FormData()
    formData.append('mainImage', image)
    formData.append('url', url)
    console.log({ url: url });
    axios.delete(`${hostName}/api/DeleteSub/${pid}?url=${url}`).then((response) => {
      clean();
      toast.success('Image has been deleted', {
        theme: 'colored',
        position: 'top-center',
        autoClose: 5000
      });
      console.log("okok")
    }).catch((error) => {
      console.log(error);
      toast.error('Something went wrong', {
        theme: 'colored',
        position: 'top-center',
        autoClose: 5000
      });
    })
  }
  let deleteVideo = () => {
    const formData = new FormData()
    formData.append('mainImage', image)
    formData.append('url', url)
    console.log({ url: url });
    axios.delete(`${hostName}/api/video/${pid}?url=${url}`).then((response) => {
      clean();
      toast.success('Video has been deleted', {
        theme: 'colored',
        position: 'top-center',
        autoClose: 5000
      });
      console.log("okok")
    }).catch((error) => {
      console.log(error);
      toast.error('Something went wrong', {
        theme: 'colored',
        position: 'top-center',
        autoClose: 5000
      });
    })
  }
  let handleNewVideo = (e) => {
    let formdata = new FormData()
    formdata.append('single', image)
    formdata.append('url', url)
    axios.put(`${hostName}/api/video/${pid}`, formdata).then((response) => {
      toast.success('Video has been uploaded successfully', {
        position: 'top-center',
        autoClose: 3000
      })
      clean()
    }).catch((error) => {
      console.log(error);
    })
  }
  return (
    <div>
      <ToastContainer />

      <Modal show={show} onHide={() => { setbool(true); setimage(null); setshow(false) }} >
        <Modal.Header>
          <p> Upload new Image </p>
        </Modal.Header>
        <input onChange={(e) => {
          let { value, files } = e.target
          if (e.target.files[0].size > 10 * 1024 * 1024) {
            e.preventDefault();
            document.getElementById('mainimageerror1').innerHTML = "*selected photo Exceededs 10MB "
            e.target.value = ''
            setimage(false)
            value = null
          }
          else {
            document.getElementById('mainimageerror1').innerHTML = ""
            setimage(e.target.files[0]);
          }
          setbool(false)
        }} type="file" className='w-fit mx-auto my-5 cursor-pointer ' />
        <p className='h-[30px] text-red-500 text-center' id='mainimageerror1'></p>
        <Modal.Footer>
          <button onClick={() => { setbool(true); setimage(null); setshow(false) }} className='p-2 px-3 bg-red-500 text-white rounded'>Close</button>
          {type != 'vi' && <button disabled={bool && image != null} onClick={() => {
            if (type == "MI")
              ChangeMainImage()
            else if (type == 'SI')
              changeSubImage()
            else
              addSubImage()
          }} className='p-2 px-3 bg-slate-500 text-white rounded'>Upload</button>}
          {type == 'vi' &&
            <button disabled={image == null}
              onClick={handleNewVideo} className='p-2 px-3 bg-slate-500 text-white rounded'>
              Upload </button>}
          <button onClick={deleteSubImage} className={`p-2 px-3 bg-red-500 text-white rounded ${type == 'SI' && bool ? ' ' : 'd-none'}`}>Delete</button>
          <button onClick={deleteVideo} className={`p-2 px-3 bg-red-500 text-white rounded ${type == 'vi' && bool ? ' ' : 'd-none'}`}>Delete Video</button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default MainImageModal
