import React from 'react'
import { Route, Routes } from 'react-router'
import ViewTemplates from './ViewTemplates'
import NavbarCom from '../../Components/Navbar'
import EditTemplate from './EditTemplate'
import Layout from '../Layouts/Layout'

const Template = () => {
  return (
    <div>
        <NavbarCom/>
        <Routes>
            <Route path='/*' element={<ViewTemplates/>} />
            <Route path='/edit/:id' element={<EditTemplate/>}/>
        </Routes>

      
    </div>
  )
}

export default Template
