import React, { useContext, useEffect, useState } from 'react'
import NavbarCom from '../../Components/Navbar'
import { StateStore } from '../../Context/StateProvider'
import { useNavigate } from 'react-router'
import SearchIcon from '../../Svg_Icons/SearchIcon'
import axios from 'axios'
import { hostName } from '../../App'

const OrderList = () => {
    let { userDb, downloadImage, getOrders, ordersDb } = useContext(StateStore)
    let [selectedOrders, setSelectedOrders] = useState([])
    let [filterOrder, setFilterOrder] = useState()
    let handleOrders = (obj) => {
        console.log(selectedOrders.some((x) => x._id == obj._id));
        if (selectedOrders.some((x) => x._id == obj._id)) {
            setSelectedOrders((prev) => prev.filter((y) => y._id != obj._id))
        }
        else {
            setSelectedOrders((prev) => [...prev, obj])
        }

    }
    let navigate = useNavigate()
    useEffect(() => {
        getOrders()

    }, [])
    useEffect(() => {
        if (ordersDb) {
            setFilterOrder(ordersDb)
        }
    }, [ordersDb])

    let handleDowload = (e) => {
        selectedOrders.forEach((x) => {
            console.log(x);
            const productDetails = [...x.productDetails]
            productDetails.forEach((y, index1) => {
                if (y.previewImage) {
                    y.previewImage.forEach((z,index2)=>
                    downloadImage(z, `${x.orderId.slice(6)}_${y.productId&&y.productId.productName}_PreviewImage_${index1 + 1}_${index2+1}`))
                }
                if (y.uploadedImage) {
                    y.uploadedImage.forEach((z, index2) => downloadImage(z, `${x.orderId.slice(6)}_${y.productId&&y.productId.productName}_Uploaded_${index1 + 1}_${index2 + 1}`))
                }
            })
        })
        axios.put(`${hostName}/api/orderStatus`, {
            id: selectedOrders.map((x) => x._id),
            status:'process'
        }).then((response) => {
            console.log(response.data);
            getOrders()
            setSelectedOrders([])
        }).catch((error) => {
            console.log(error);
        })

    }

    // Search
    let [searchWord, setSearchWord] = useState('')
    let searchOrder = () => {
        if (searchWord != '') {
            let filterdata = ordersDb.filter((x) => x.orderId.toLowerCase().indexOf(searchWord.toLowerCase()) != -1 ||
             (x.payment_Id&& x.payment_Id.toLowerCase().indexOf(searchWord.toLowerCase()) != -1) || 
             (x.shippingAddress && x.shippingAddress.fullName.toLowerCase().indexOf(searchWord.toLowerCase()) != -1) )
            setFilterOrder(filterdata)
            console.log(filterdata);
        }
        else
            setFilterOrder(ordersDb)
    }
    let handleStatusChange = (value, id) => {
        axios.put(`${hostName}/api/orderStatus`, {
            uid: id,
            status:value
        }).then((response) => {
            console.log(response.data);
            getOrders()
            setSelectedOrders([])
        }).catch((error) => {
            console.log(error);
        })
    }
    return (
        <div>
            <NavbarCom />
            <div className='mx-auto container'>

                {/* Filteration */}
                <section className="ms-auto flex gap-3 w-fit ">
                    <div className="ms-auto w-fit p-2 px-3  border-1 border-slate-400 rounded-full">
                        <input onKeyUp={(e) => {
                            if (e.key == "Enter") {
                                searchOrder()
                            }
                        }}
                            type="text" value={searchWord} onChange={(e) => setSearchWord(e.target.value)}
                            className=" outline-none " placeholder="Search by Order Id , payment Id , Name" />
                        <button onClick={handleOrders} className=''>
                            <SearchIcon />
                        </button>
                    </div>
                    {/* <select name="" className="p-2 outline-none border-1 px-3 border-slate-400 rounded-full " id="">
                        <option value=""> Select</option>
                        <option value="AZ">Sort by A-Z</option>
                        <option value="ZA">Sort by Z-A</option>
                    </select> */}
                </section>
                {ordersDb && filterOrder &&
                    <div className='container    mx-auto'>
                        <div className='flex justify-between my-3'>
                            <h4 className='text-2xl fw-semibold'>Order</h4>
                            {selectedOrders.length > 0 && <button onClick={handleDowload} className='p-2 px-3 bg-slate-500 rounded text-white '>
                                Download </button>}
                        </div>
                        <div className='table-responsive h-[70vh] overflow-y-scroll '>
                            <table className="min-w-[1000px] w-full table-striped">
                                <tr className=''>
                                    <th className="text-center py-2 w-fit border-1">SI.No </th>
                                    <th className="text-center  w-fit border-1">Order Id </th>
                                    <th className="text-center w-fit border-1">Billing Name  </th>
                                    <th className="text-center w-fit border-1">Billing Number  </th>
                                    <th className="text-center w-fit border-1">Payment Id </th>
                                    <th className="text-center w-fit border-1">Shipment Id </th>
                                    <th className="text-center w-fit border-1">Amount Paid </th>
                                    <th className="text-center w-fit border-1">Ordered Date</th>
                                    <th className="text-center w-fit border-1">Coupon Used</th>
                                    <th className="text-center w-fit border-1"> Status </th>
                                    <th className="text-center w-fit border-1"> Chat </th>
                                    <th className="text-center w-fit border-1"> select </th>
                                </tr>

                                {[...filterOrder].reverse().map((x, index) => {
                                    console.log(x);
                                    return (
                                        <tr className={`items-center w-full justify-between m-0  ${index % 2 == 0 ? "bg-slate-50" : "bg-slate-100"}`}>
                                            <td className="text-center border-1"> {index + 1} </td>
                                            <td onClick={() => navigate(`/orders/${x._id}`)}
                                                className="text-center hover:bg-slate-200 cursor-pointer border-1"> {x.orderId} </td>
                                            <td className="text-center border-1"> {x.shippingAddress.fullName} </td>
                                            <td className="text-center border-1"> {x.shippingAddress.phone} </td>

                                            <td className="text-center border-1"> {x.payment_Id} </td>
                                            <td className="text-center border-1">{x.shipment_Id} </td>
                                            <td className="text-center border-1"> {x.amountPaid} </td>
                                            <td className="text-center border-1">  {new Date(x.orderedTime).toLocaleString()} </td>
                                            <td className="text-center border-1"> {x.couponUsed ? x.couponUsed.code : '--'} </td>
                                            <td className="text-center border-1">
                                                {/* {x.status}  */}
                                                <select onChange={(e) => handleStatusChange(e.target.value, x._id)} className='bg-transparent outline-none ' name="" value={x.status} id="">
                                                    {
                                                        [
                                                            'order placed',
                                                            'process',
                                                            'shipped',
                                                            'out for delivery',
                                                            'delivered'
                                                        ].map((x) => {
                                                            return (
                                                                <option value={x}>{x}</option>
                                                            )
                                                        })
                                                    }
                                                    {/* <option value='order placed'>order placed </option>
                                                <option value='process'>process </option>
                                                <option value='shipped'>shipped </option>
                                                <option value='out for delivery'>out for delivery </option>
                                                <option value='delivered'> delivered </option> */}


                                                </select>
                                            </td>
                                            <td className="text-center border-1"> <a target='_blank' className='' href={`https://wa.me/+91${x.shippingAddress.phone}`} >
                                                <img width={30} src={require('../../Assest/whatsapplogo.png')} className='mx-auto ' alt="WhatsappLogo" />   </a> </td>
                                            <td className="text-center border-1">
                                                <button onClick={() => handleOrders(x)}>
                                                    {selectedOrders.some((y) => y._id == x._id) ? <img src={require('../../Assest/check.png')} width={20} alt="Checked" /> : <img width={20} src={require('../../Assest/square.png')} alt="square" />}
                                                </button>
                                            </td>
                                        </tr>)
                                })
                                }
                            </table>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default OrderList
