import React, { useContext, useEffect, useState } from 'react'
import NavbarCom from '../../Components/Navbar'
import { StateStore } from '../../Context/StateProvider'
import axios from 'axios'
import { hostName } from '../../App'
import { useNavigate, useParams } from 'react-router'
import Loading from '../../Components/Loading'
import MainImageModal from '../../Components/MainImageModal'
import { ToastContainer, toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import PreviewModel from '../../Components/PreviewModel'
import ClipPath from '../Layouts/ClipPath'
import PlusIcon from '../../Svg_Icons/PlusIcon'
import DustBin from '../../Svg_Icons/DustBin'

const EditProduct = () => {
    const { id } = useParams()
    let [editMainImagemodal, setMainImagemodal] = useState(false)
    let { brandDB, categoryDB, varietyDB } = useContext(StateStore)
    let [clipId, setClipId] = useState()
    let [url, seturl] = useState()
    let [showClipath, setShowPath] = useState()
    let [name, setname] = useState()
    let [templateobj, setTemplateobj] = useState()

    let [changes, setchanges] = useState(false)
    //get a particular product
    let getParticularProduct = () => {
        axios.get(`${hostName}/api/productID?id=${id}`).then((response) => {
            console.log(response.data);
            setProduct(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        getParticularProduct()
    }, [])
    let { templatesDB, setTemplatesDB, modelDb, fetchdata, getTemplates, videoDB, setVideoDb, getVideodb, } = useContext(StateStore)
    useEffect(() => {
        getTemplates()
        getVideodb()
    }, [])
    let admin = JSON.parse(sessionStorage.getItem('admin'))
    const [product, setProduct] = useState({
        productName: "",
        price: 0,
        quantity: 0,
        description: "",
        category: "",
        vatriety: "",
        brand: "",
        sku: '',
        wholePrice: null,
        template: null,
        modelName: null,
        mainImage: null,
        pictures: null,
        videoObj: [{
            _id: '',
            videoUrl: '',
            name: ''
        }],
        descriptionPoints: []
    })
    let [selectedvideos, setselectvideos] = useState([])
    let [modelobj, setmodelobj] = useState()
    const handleChanges = (e) => {
        setchanges(true)
        let { value, name } = e.target
        if (name == 'mainImage')
            value = e.target.files
        if (name == 'pictures')
            value = e.target.files
        setProduct((prev) => ({
            ...prev, [name]: value
        }))
    }
    let validateForm = () => {
        if (product.productName == "") {
            document.getElementById('producterror').innerHTML = "* Enter the Product name"
        }
        else {
            document.getElementById('producterror').innerHTML = ""

        }
        if (product.price == 0) {
            document.getElementById('priceerror').innerHTML = "* Enter the price"
        }
        else {
            document.getElementById('priceerror').innerHTML = ""

        }
    }
    let postvalue = (e) => {
        e.preventDefault()
        if (changes) {
            if (product.productName != "" && product.price != 0) {
                let newarry = [...product.videoObj.map((obj) => obj._id)]
                videoDB.forEach((obj) => {
                    selectedvideos.forEach((x) => {
                        if (obj.name == x) {
                            newarry.push(obj._id)
                        }
                    })
                })

                console.log(newarry);
                console.log(product.mainImage);
                product.videoObj = newarry
                document.getElementById('producterror').innerHTML = ""
                document.getElementById('priceerror').innerHTML = ""
                document.getElementById('quantityerror').innerHTML = ""
                document.getElementById('mainimageerror').innerHTML = ""
                axios.put(`${hostName}/api/product/${id}`, product).then((response) => {
                    console.log(response.data);
                    // alert("Product has been Updated")
                    setselectvideos([])
                    toast.success('Product changes has been updated', {
                        theme: 'colored',
                        position: 'top-center',
                        autoClose: 5000
                    })
                    fetchdata()
                    getParticularProduct()
                }).catch((error) => {
                    console.log(error.response);
                    toast.error('Product not updated ,Error aquired', {
                        theme: 'colored',
                        position: 'top-center',
                        autoClose: 5000
                    })
                })
            }
            else
                validateForm()
        }
        else {
            toast.warning('No changes has been made', {
                theme: 'colored',
                position: 'top-center',
                autoClose: 5000
            })
        }

    }
    let [filterModel, setFilterModel] = useState()
    useEffect(() => {
        setFilterModel(modelDb)
    }, [modelDb])
    let navigate = useNavigate()
    let [showPreivew, setShowPreview] = useState(false)

    return (
        <div className='poppins min-h-[90vh] bg-slate-50'>
            <NavbarCom />
            <div className='container mx-auto'>
                <button onClick={() => navigate('/product')} className='p-2 ms-auto flex my-2 px-3 bg-black text-white rounded '>Back </button>

            </div>

            {
                product != undefined && product.imageUrl != undefined ? <>
                    {showPreivew &&
                        <Modal size='xl' centered show={showPreivew} onHide={() => setShowPreview(false)}>
                            <Modal.Header closeButton>
                                <h4>Privew of the Editable Layout For the Product</h4>
                            </Modal.Header>
                            <Modal.Body>
                                <PreviewModel modelData={product.modelName} templateData={product.template} />
                            </Modal.Body>

                        </Modal>
                    }

                    <div className='container bg-white mx-auto p-3 rounded '>
                        <div className='flex justify-between items-center'>
                            <p className='fw-medium text-lg'>Update Product</p>
                            <button onClick={postvalue} className='ms-auto mx-3 rounded bg-violet-600 text-white p-3'>Update</button>
                            {product.clipPaths &&
                                product.clipPaths.map((x, index) => {
                                    // console.log(x);
                                    return (
                                        <button onClick={() => { setClipId(x._id); setShowPath(true) }}
                                            className='p-3 mx-2 px-3 rounded bg-slate-600 text-white'>
                                            View Clipath {index + 1}
                                        </button>
                                    )
                                }
                                )}
                        </div>
                        <article className='flex my-4 flex-wrap gap-3 justify-between'>

                            {product && <div className=' w-[400px]'>
                                Product Name <span id='producterror' className='text-red-600'>*</span>
                                <span className='text-blue-500'> {product.brand + " "}
                                    {modelDb && product.modelName && modelDb.find((obj) => obj._id == product.modelName._id) && modelDb.find((obj) => obj._id == product.modelName._id).modelName + " "}
                                    ({varietyDB && product.vatriety && varietyDB.find((obj) => obj._id == product.vatriety).variety}) </span>
                                <input name='productName' value={product.productName} onChange={handleChanges} type="text" placeholder='Enter the Product name' style={{ backgroundColor: "#f6f6f6" }} className='p-3 w-full  block my-2 px-3 border-0  rounded outline-none' />
                            </div>}
                            <div className=' w-[400px]'>
                                SKU <span id='producterror' className='text-red-600'>*</span>
                                <span className='text-blue-600'>{varietyDB && product.vatriety && varietyDB.find((obj) => obj._id == product.vatriety).variety.replace(/\s/g, '').toLowerCase() + '-'}{modelDb && product.modelName && modelDb.find((obj) => obj._id == product.modelName._id).modelName.replace(/\s/g, '').toLowerCase()}  </span>
                                <input name='sku' value={product.sku} onChange={handleChanges} type="text" placeholder='Enter the SKU' style={{ backgroundColor: "#f6f6f6" }} className='p-3 w-full  block my-2 px-3 border-0  rounded outline-none' />
                            </div>

                            <div className='w-[400px]'>
                                Price <span id='priceerror' className='text-red-600'>*</span>
                                <input type="number" value={product.price} onChange={handleChanges} name='price' placeholder='Enter the price ' style={{ backgroundColor: "#f6f6f6" }} className='p-3 w-full block my-2 px-3 border-0  rounded outline-none' />
                            </div>
                            <div className='w-[400px]'>
                                MRP <span className=''>(Price before discount) </span>
                                <input value={product.wholePrice} type="number" onChange={handleChanges} name='wholePrice' placeholder='Enter the MRP ' style={{ backgroundColor: "#f6f6f6" }} className='p-3 w-full block my-2 px-3 border-0  rounded outline-none' />
                            </div>
                            <div className='w-[400px] '>
                                Quantity <span id='quantityerror' className='text-red-600'>*</span>
                                <input type="number" value={product.quantity} onChange={handleChanges} name='quantity' placeholder='Enter the Product name ' style={{ backgroundColor: "#f6f6f6" }} className='p-3 block w-full my-2 px-3 border-0  rounded outline-none' />
                            </div>
                            <div className=' w-[400px]'>
                                Model Name
                                {/* <input name='modelName' disabled value={product.modelName.modelName} onChange={handleChanges} type="text" placeholder='Enter the Model name' style={{ backgroundColor: "#f6f6f6" }} className='p-3 w-full  block my-2 px-3 border-0  rounded outline-none' /> */}
                                <select value={product.modelName._id} name='modelName' onChange={(e) => {
                                     setProduct((prev) => ({
                                        ...prev, modelName : modelDb.find((obj) => obj._id == e.target.value)
                                    }))
                                    setchanges(true)
                                    setmodelobj(modelDb.find((obj) => obj._id == e.target.value))
                                }} type="text"
                                
                                    placeholder='Enter the Model name' style={{ backgroundColor: "#f6f6f6" }}
                                    className='p-3 w-full  block my-2 px-3 border-0  rounded outline-none' >
                                    <option value="">Select model</option>
                                    {
                                        filterModel && filterModel.map((x) => {
                                            return (
                                                <option value={x._id}>{x.brandName} {x.modelName} </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            {/* <div className='w-[400px] '>
                                Template
                                <select value={product.template._id} onChange={(e) => {
                                    handleChanges(e);
                                    setTemplateobj(templatesDB.find((obj) => obj._id == e.target.value))
                                }} name='template' type="text" style={{ backgroundColor: "#f6f6f6" }} className='p-3 w-full  block my-2 px-3 border-0  rounded outline-none' >
                                    <option value="">select </option>
                                    {
                                        templatesDB != undefined ? <>{
                                            templatesDB.map((x) => {
                                                return (
                                                    <option value={x._id}>{x.templateName} </option>
                                                )
                                            })
                                        } </> : ""
                                    }
                                </select>
                            </div> */}
                            <div className='w-[400px] '>
                                Variety
                                <select onChange={handleChanges} value={product.vatriety} name='vatriety' type="text" placeholder='Enter the Product name' style={{ backgroundColor: "#f6f6f6" }} className='p-3 w-full  block my-2 px-3 border-0  rounded outline-none' >
                                    <option value="">Select</option>
                                    {
                                        varietyDB != undefined ? <>{
                                            varietyDB.map((x) => {
                                                return (
                                                    <option value={x._id}>{x.variety} </option>
                                                )
                                            })
                                        } </> : ""
                                    }
                                </select></div>
                            <div className='w-[400px]'>
                                Category
                                <select onChange={handleChanges} value={product.category} name='category' type="text" placeholder='Enter the Product name'
                                    style={{ backgroundColor: "#f6f6f6" }} className='p-3 w-full  block my-2 px-3 border-0  rounded outline-none' >
                                    <option value="">Select</option>
                                    {
                                        categoryDB != undefined ? <>{
                                            categoryDB.map((x) => {
                                                return (
                                                    <option value={x.category}>{x.category} </option>
                                                )
                                            })
                                        } </> : ""
                                    }
                                </select>
                            </div>
                            <div className='w-[400px]'>
                                Brand
                                <select name='brand' onChange={handleChanges} value={product.brand} placeholder='Enter the Product name ' style={{ backgroundColor: "#f6f6f6" }} className='p-3 w-full block my-2 px-3 border-0  rounded outline-none' >
                                    <option value={null}>select </option>
                                    {
                                        brandDB != undefined ? <>{
                                            brandDB.map((x) => {
                                                return (
                                                    <option value={x.brand}>{x.brand} </option>
                                                )
                                            })
                                        } </> : ""
                                    }

                                </select>
                            </div>

                            <div className='w-[400px] '>
                                Main Image <span id='mainimageerror' className='text-red-600'>* </span>

                                <img onClick={() => { seturl(product.mainImage); setname('MI'); setMainImagemodal(true) }} className='w-40 hover:scale-105 object-contain border-1 transition duration-500 h-40 cursor-pointer' src={product.mainImage} alt="product" />
                            </div>
                            <div className='w-[400px]' >
                                Video
                                {
                                    <select onChange={(e) => {
                                        if (e.target.value != '') {
                                            setchanges(true)
                                            if (selectedvideos.indexOf(e.target.value) == -1) {
                                                setselectvideos((prev) => [...prev, e.target.value])
                                            }
                                            else {
                                                setselectvideos((prev) => prev.filter((name) => name != e.target.value))
                                            }
                                        }
                                    }}
                                        style={{ backgroundColor: "#f6f6f6" }} className='p-3 w-full  block my-2 px-3 border-0  rounded outline-none'
                                        name="" id="">
                                        <option value="">select</option>
                                        {
                                            videoDB && videoDB.map((obj) => (
                                                <option value={obj.name}>{obj.name} </option>
                                            ))
                                        }
                                    </select>
                                }
                                <div className='flex flex-wrap gap-2'>
                                    {
                                        selectedvideos && selectedvideos.map((x) => (
                                            <p className='rounded p-3 relative bg-slate-50 px-4 ' > {x}
                                                <span onClick={() => setselectvideos((name) => name.filter((y) => y != x))}
                                                    className='border-1 top-1 right-1 cursor-pointer absolute border-red-500 text-red-500 rounded-full p-1 '>
                                                    <PlusIcon size={10} /> </span> </p>
                                        ))
                                    }

                                </div>

                            </div>
                            <section>
                                Video Added for products
                                <div className='w-full my-2 flex flex-wrap '>
                                    {
                                        product.videoObj && product.videoObj.map((x, index) => {
                                            return (
                                                <div className='w-36 sm:w-40 border-1 m-1 mx-2 '>
                                                    <video autoPlay={true} muted controls
                                                        className='m-auto  object-contain '
                                                        src={x.videoUrl && x.videoUrl} alt="image" />
                                                    <div className='text-center my-2 relative'> {x.name && x.name}
                                                        <button onClick={() => {
                                                            setchanges(true)
                                                            let newarry = product.videoObj.filter((obj, index1) => index1 != index)
                                                            console.log(newarry);
                                                            setProduct((prev) => {

                                                                return {
                                                                    ...prev,
                                                                    videoObj: newarry
                                                                }
                                                            })
                                                        }} className='text-red-500 right-1 absolute'> <DustBin /> </button>  </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </section>
                            <div className='w-full '>
                                Sub Images <span id='suberror' className='text-red-600' >*</span>
                                <div className='w-full my-2 flex flex-wrap '>
                                    {
                                        product.imageUrl && product.imageUrl.map((x) => {
                                            return (
                                                <img onClick={() => { setname('SI'); seturl(x); setMainImagemodal(true) }} className='w-36 sm:w-40 hover:scale-105 transition duration-500 border-1 m-1 mx-2 h-40 object-contain cursor-pointer ' src={x} alt="image" />
                                            )
                                        })
                                    }
                                    <img onClick={() => { setname('add'); setMainImagemodal(true) }} className='w-36 sm:w-40 hover:scale-105 transition duration-500 border-1 m-1 h-40 object-contain cursor-pointer ' src={require('../../Assest/addimage.jpg')} alt="image" />
                                </div>

                            </div>
                        </article>
                        <div className=' w-[400px] ' >
                            <p>Description points </p>
                            <div className=' flex gap-2 items-start w-full' >
                                <div className='w-full '>
                                    {
                                        product.descriptionPoints && product.descriptionPoints.map((x, index) => (
                                            <div style={{ backgroundColor: "#f6f6f6" }}
                                                className='p-3 w-full  my-2 px-3 border-0 flex justify-between rounded'>

                                                <input type="text" name='description' value={x}

                                                    className='bg-transparent outline-none'
                                                    onChange={(e) => {
                                                        setProduct((prev) => {
                                                            setchanges(true)
                                                            const desarry = [...prev.descriptionPoints]
                                                            desarry[index] = e.target.value
                                                            return {
                                                                ...prev,
                                                                descriptionPoints: desarry
                                                            }
                                                        })
                                                    }} />
                                                <button onClick={() => {
                                                    setchanges(true)
                                                    setProduct((prev) => {

                                                        const arry = prev.descriptionPoints
                                                        const newarry = arry.filter((y, index1) => index != index1)
                                                        return {
                                                            ...prev,
                                                            descriptionPoints: newarry
                                                        }
                                                    })
                                                }} className='text-red-600  '>
                                                    <DustBin />
                                                </button>
                                            </div>
                                        ))
                                    }
                                </div>
                                <button onClick={() => setProduct((prev) => {
                                    const desarry = [...prev.descriptionPoints]
                                    desarry.push('')
                                    return {
                                        ...prev,
                                        descriptionPoints: desarry
                                    }
                                })} className=''><PlusIcon /> </button>
                            </div>
                        </div>
                        <div className=''>
                            <p>Description</p>
                            <textarea value={product.description} style={{ backgroundColor: "#f6f6f6" }} name="description" onChange={handleChanges} placeholder='Enter the Description for the Product'
                                className='border-slate-100 outline-none w-full border-1 p-3' id="" cols="30" rows="10"></textarea>

                        </div>

                    </div>

                    <ClipPath product={product} id={id} clipId={clipId} setProduct={setProduct} show={showClipath} setShowPath={setShowPath} />

                    <MainImageModal url={url} type={name} fn={getParticularProduct} show={editMainImagemodal} pid={id} setshow={setMainImagemodal} />
                </> : <Loading />
            }
        </div >


    )
}

export default EditProduct