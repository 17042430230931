import React, { useContext, useState } from 'react';
import { hostName, mobileFrame } from '../../App';
import NavbarCom from '../../Components/Navbar';
import axios from 'axios';
import { StateStore } from '../../Context/StateProvider';
import { ToastContainer, toast } from 'react-toastify';

const ModelCreation = () => {
    let [errorMessage,setErrorMessage]=useState('')
    const {brandDB}=useContext(StateStore)
    console.log(brandDB);
    const [modelObj, setCameraPositioning] = useState({
        brandName: '',
        modelName: '',
        imagefile: null,
        width: 120,
        x: 10,
        y: 10
    });
    const [cameraImageUrl, setImageUrl] = useState(null);

    const handleCamera = (e) => {
        const file = e.target.files[0];
        if (file.size<= 1024 * 1024) {

            setCameraPositioning((prev) => ({
                ...prev,
                imagefile: file
            }))
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    setImageUrl(reader.result);
                };
                reader.readAsDataURL(file);
            }
        }else{
            e.target.value=''
            setErrorMessage(`* Upload a photo less than 1MB, Uploaded file is ${(file.size/(1024*1024)).toFixed(2)}`)
        }
    };

    const handleInput = (e) => {
        let { value, name } = e.target;
        if (name == 'width' || name == 'x' || name == 'y') { value = Number(value) < 0 ? 0 : Number(value); }// Convert value to number and ensure it's not negative
        setCameraPositioning((prev) => ({
            ...prev,
            [name]: value
        }));
    };
    const handleSave=(e)=>{
        e.preventDefault()
        const formData=new FormData()
        formData.append('image',modelObj.imagefile)
        formData.append('brandName',modelObj.brandName)
        formData.append('modelName',modelObj.modelName)
        formData.append('width',modelObj.width)
        formData.append('x',modelObj.x)
        formData.append('y',modelObj.y)

        console.log({...modelObj,...formData});
        axios.post(`${hostName}/api/mobileModel`,formData).then((response)=>{
            console.log(response.data);
            toast.success('Model Has been saved',{
                position:'top-center',
                autoClose:5000,
                // closeOnClick,
                // pauseOnFocusLoss,
                // pauseOnHover,
                theme:'colored'

            })
            
        }).catch((error)=>{
            console.log(error);
            toast.error(' Error Acquired check the Input',{
                position:'top-center',
                autoClose:5000,
                // closeOnClick,
                // pauseOnFocusLoss,
                // pauseOnHover,
                theme:'colored'

            })
        })
    }
    return (
        <div>
            <ToastContainer/>
            
          <NavbarCom />
            <div className='row container mx-auto'>
                <h4 className='font-serif text-center '>Mobile Model Creation</h4>
                <div className='col-lg-6 flex min-h-[85vh]'>
                    <div className={`${mobileFrame} relative overflow-hidden m-auto rounded-2xl border-5 border-slate-950  `}>
                        {cameraImageUrl && <img style={{
                            position: 'absolute',
                            left: `${modelObj.x}px`,
                            top: `${modelObj.y}px`
                        }}
                            width={modelObj.width} className=' '
                            src={cameraImageUrl} alt="Uploaded Image" />}
                    </div>
                </div>
                <div className='col-lg-6 min-h-[85vh] flex'>
                    <div className='m-auto '>
                        {/* Modal Details */}
                        <div className='my-3 p-3 border-1 border-slate-900 rounded'>
                            <p className='text-2xl font-serif '>Model Details : </p>

                            <div>
                                Brand :
                                <select name="brandName" onChange={handleInput} value={modelObj.brand} className='outline-none mx-2 p-1 ' id="">
                                    <option value="">Select</option>
                                    {brandDB&& brandDB.map((x)=>{
                                        return <option value={x.brand}>{x.brand} </option>
                                    })}

                                </select>
                            </div>
                            <div>
                                Model Name :
                                <input type="text" value={modelObj.modelName} className='p-1 mx-1 outline-none bg-slate-50' name='modelName' onChange={handleInput} placeholder='A56' />
                            </div>

                        </div>
                        {/* Image Upload */}
                        <div className='my-3 p-3 border-1 border-slate-900 rounded'>
                            <p className='text-2xl font-serif '>Camera Upload : </p>
                            <input type="file" accept='image/*' name='imagefile' onChange={(e) => { handleCamera(e); }} />
                        </div>
                        {/* Image Editing */}
                        <div className='border-1 border-slate-900 rounded p-3'>
                            <p className='text-2xl font-serif '>Placement of the Camera</p>
                            <div className='my-2'>
                                Width :
                                <input type="number" name='width' value={modelObj.width} className='w-28 bg-slate-50 mx-3 outline-none p-1 ' onChange={handleInput} />
                            </div>
                            <div className='my-2'>
                                X position :
                                <input type="number" name='x' value={modelObj.x} className='w-28 bg-slate-50 mx-3 outline-none p-1 ' onChange={handleInput} />
                            </div>
                            <div className='my-2'>
                                Y position :
                                <input type="number" name='y' value={modelObj.y} className='w-28 bg-slate-50 mx-3 outline-none p-1 ' onChange={handleInput} />
                            </div>
                        </div>
                        {/* Error Message */}
                        <p className='text-red-600 h-[30px] w-[250px] '>{errorMessage} </p>
                        {/* Saving the Image */}
                        <button onClick={handleSave} className='p-2 px-3 rounded bg-slate-600 text-white my-3 '> Save Model </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ModelCreation;
