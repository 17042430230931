import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { hostName } from '../App'
import { StateStore } from '../Context/StateProvider'
import { useNavigate } from 'react-router'
import { Modal } from 'react-bootstrap'
import VideoUpload from '../Pages/Extras/VideoUpload'
import { toast } from 'react-toastify'

const VideoComponent = ({ type }) => {
    let { videoDB, setVideoDb, getVideodb, } = useContext(StateStore)
    let [editModal, setEditModal] = useState(false)
    let [loading, setloading] = useState('')
    let [editObj, setEditObj] = useState({
        _id: '',
        name: '',
        videoUrl: ''
    })
    let [videoupload, setvideoUpload] = useState()
    let navigate = useNavigate()
    useEffect(() => {
        getVideodb()
    }, [])
    let successResult = (str) => {
        toast.success(str)
        setEditModal(false)
        setEditObj({
            _id: '',
            name: '',
            videoUrl: ''
        })
        getVideodb()
        setloading('')
    }
    let handleUpdate = () => {
        setloading('upload')
        if (videoupload) {

            const formdata = new FormData()
            formdata.append('name', editObj.name)
            formdata.append('video', videoupload)
            axios.put(`${hostName}/api/videoUpload/${editObj._id}`, formdata).then((response) => {
                successResult("Video updated successfully")
            }).catch((error) => {
                console.log(error);
                toast.error('Error acquired')
                setloading('')

            })
        }
        else {
            axios.put(`${hostName}/api/videoName`, editObj).then((response) => {
                successResult("Video updated successfully")

            }).catch((err) => {
                console.log(err);
                toast.error('Error acquired')
                setloading('')
            })
        }
    }
    let handledelte = () => {
        setloading('delete')
        axios.delete(`${hostName}/api/videoObjDelete/${editObj._id}`).then((response) => {
            successResult('video deleted successfully')
            setloading('')
        }).catch((error) => {
            console.log(error);
            setloading('')

        })
    }
    return (
        <div>

            <div className='flex flex-wrap justify-between gap-3' >
                {
                    videoDB && type != 'front' && videoDB.map((obj) => (
                        <div className=' border-1 w-[18rem] '>
                            <video src={obj.videoUrl} controls muted autoPlay  ></video>
                            <button onClick={() => { setEditModal(true); setEditObj(obj) }} className='text-center w-full py-2'>
                                {obj.name}
                            </button>
                        </div>
                    ))
                }
                {
                    videoDB && type == 'front' && [...videoDB].slice(0, 3).map((obj) => (
                        <div className=' border-1 w-[18rem] '>
                            <video src={obj.videoUrl} controls muted autoPlay  ></video>
                            <button onClick={() => { setEditModal(true); setEditObj(obj) }} className='text-center w-full py-2'>
                                {obj.name}
                            </button>
                        </div>
                    ))
                }
                {
                    videoDB && type == 'front' && videoDB.length > 3 &&
                    <button onClick={() => navigate('/features/videoUpload')} className='w-[18rem] uppercase shadow bg-slate-300  bg-opacity-50' >
                        see more
                    </button>
                }
            </div>
            {
                editModal && <Modal className='' centered show={editModal} onHide={() => setEditModal(false)} >
                    <Modal.Header closeButton>
                        Edit the Video Details
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            Name :
                            <input type="text" onChange={(e) => setEditObj((prev) => ({
                                ...prev,
                                name: e.target.value
                            }))} className='p-2 px-3 bg-slate-50 rounded outline-none mx-2' value={editObj.name} />
                        </div>
                        <div className='my-3'>
                            Video Upload :
                            <input type="file" onChange={(e) => setvideoUpload(e.target.files[0])} className='p-2 px-3 bg-slate-50 rounded outline-none mx-2' />
                        </div>
                        <div className='flex justify-end'>
                            <button disabled={loading == 'upload'} onClick={handleUpdate} className='p-2 px-3 mx-1 rounded bg-slate-500 text-white'>{loading == 'upload' ? "loading" : "Upload"} </button>
                            <button disabled={loading == 'delete'} onClick={handledelte} className='p-2 px-3 mx-1 rounded bg-red-500 text-white'>{loading == 'delete' ? 'loading' : "Delete"} </button>

                        </div>
                    </Modal.Body>

                </Modal>
            }
        </div>
    )
}

export default VideoComponent