import axios from 'axios'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useParams } from 'react-router'
import { hostName } from '../../App'
import { Spinner } from 'react-bootstrap'
import Layout from '../Layouts/Layout'

const EditTemplate = () => {
  const {id}=useParams()
  let [templatedata,settemplatedata]=useState()
  const getTemplateByid=()=>{
       axios.get(`${hostName}/api/template/${id}`).then((response)=>{
     settemplatedata(response.data);
       }).catch((error)=>{
        console.log(error);
       })
  }
  useEffect(()=>{
     getTemplateByid()
  },[])

  return (
    <div>
       {
        templatedata ? <>
          <Layout data={templatedata} />
        </> : <div className='absolute w-[100vw] bg-slate-900 opacity-15  h-[100vh]'>
          <Spinner animation='border' className='m-auto flex' />
        </div>
       }
      
    </div>
  )
}

export default EditTemplate
