import React, { useEffect, useState } from 'react'
import { Offcanvas } from 'react-bootstrap'
import PlusIcon from '../Svg_Icons/PlusIcon'
import axios from 'axios'
import { hostName } from '../App'

const Notification = (props) => {
    let { show, setshow, setcount } = props
    let [notificationArry, setNotificationArry] = useState()
    let getNotification = () => {
        // alert('hello')
        axios.get(`${hostName}/api/notification`).then((response) => {
            console.log(response.data);
            setNotificationArry(response.data)
            setcount(response.data.length)
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        getNotification()
    }, [])
    let clearNotification = () => {
        axios.put(`${hostName}/api/notification`,{
            id:notificationArry.map((x)=>x._id)
        }).then((response)=>{
            console.log(response.data);
            getNotification()
        }).catch((error)=>{
            console.log(error);
        })
    }
    return (
        <Offcanvas show={show} onHide={() => setshow(false)} placement='end'  >
            <Offcanvas.Header closeButton >
                Notifications
            </Offcanvas.Header>
            <Offcanvas.Body>
                {notificationArry &&notificationArry.length>0 &&
                    <div className=''>

                        {/* Button */}
                        {[...notificationArry].reverse().map((x) => (
                            <div className='my-2'>
                                {x.message}
                                <hr />
                            </div>
                        ))}
                        <div className=''>
                            <button onClick={() => clearNotification()}
                                className='rotate-45 text-black flex mx-auto p-2 border rounded-full'>
                                <PlusIcon />
                            </button>
                        </div>

                    </div>
                }


            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default Notification