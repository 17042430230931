import React, { useContext, useEffect, useState } from 'react'
import NavbarCom from '../../Components/Navbar'
import { StateStore } from '../../Context/StateProvider'
import axios from 'axios'
import { hostName } from '../../App'
import { ToastContainer, toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import PreviewModel from '../../Components/PreviewModel'
import ClipPath from '../Layouts/ClipPath'
import PlusIcon from '../../Svg_Icons/PlusIcon'
import DustBin from '../../Svg_Icons/DustBin'
import LeftArrow from '../../Svg_Icons/LeftArrow'
import { useNavigate } from 'react-router'

const AddProduct = () => {
  let { brandDB, varietyDB, categoryDB, modelDb, videoDB, getProduct, getVideodb, } = useContext(StateStore)
  let admin = JSON.parse(sessionStorage.getItem('admin'))
  let [modelobj, setmodelobj] = useState()
  let [showClipath, setShowPath] = useState()
  let [clipPathIndex, setClipPathIndex] = useState()
  let [templateobj, setTemplateobj] = useState()
  let [showPreivew, setShowPreview] = useState()
  const [product, setProduct] = useState({
    productName: "",
    price: 0,
    quantity: 0,
    description: "",
    category: "",
    vatriety: "",
    brand: "",
    modelName: "",
    clipPaths: [''],
    sku: '',
    template: null,
    wholePrice: null,
    mainImage: null,
    pictures: null,
    video: null,
    videoObj: [],
    descriptionPoints: [
      ''
    ]
  })
  let { templatesDB, setTemplatesDB, getTemplates } = useContext(StateStore)
  useEffect(() => {
    getTemplates()
    getVideodb()
  }, [])
  const handleChanges = (e) => {
    let { value, name } = e.target

    // console.log(name + " " + value);
    if (name == 'mainImage') {
      if (e.target.files[0].size > 20 * 1024 * 1024) {
        e.preventDefault();
        document.getElementById('mainimageerror').innerHTML = "* Exceeded 7MB "
        e.target.value = ''
        value = null
      }
      else {
        document.getElementById('mainimageerror').innerHTML = "* "
        value = e.target.files
      }
    }
    if (name == 'pictures') {
      const files = e.target.files
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 20 * 1024 * 1024) {
          document.getElementById('suberror').innerHTML = `* ${i == 0 ? '1st' : i == 1 ? '2nd' : i == 2 ? `3rd` : `${i + 1}th`} image Exceeds 7MB`
          e.target.value = ''
          value = null
        }
        else {
          document.getElementById('suberror').innerHTML = ''
          value = e.target.files
        }
      }
    }
    if (name == 'video') {
      const files = e.target.files
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 10 * 1024 * 1024) {
          document.getElementById('vioderror').innerHTML = `* ${i == 0 ? '1st' : i == 1 ? '2nd' : i == 2 ? `3rd` : `${i + 1}th`} image Exceeds 10MB`
          e.target.value = ''
          value = null
        }
        else {
          document.getElementById('vioderror').innerHTML = ''

          value = e.target.files
        }
      }
    }

    setProduct((prev) => ({
      ...prev, [name]: value
    }))
    // console.log(name + " " + value);
  }
  let validateForm = () => {
    if (product.mainImage == null) {
      document.getElementById('mainimageerror').innerHTML = "* upload the image"
    }
    else {
      document.getElementById('mainimageerror').innerHTML = ""

    }
    if (product.pictures == null) {
      document.getElementById('suberror').innerHTML = "* upload the image"
    }
    else {
      document.getElementById('suberror').innerHTML = ""

    }
    if (product.productName == "") {
      document.getElementById('producterror').innerHTML = "* Enter the Product name"
    }
    else {
      document.getElementById('producterror').innerHTML = ""

    }
    if (product.price == 0) {
      document.getElementById('priceerror').innerHTML = "* Enter the price"
    }
    else {
      document.getElementById('priceerror').innerHTML = ""

    }
    if (product.quantity == 0) {
      document.getElementById('quantityerror').innerHTML = "* Enter the quantity"
    }
    else {
      document.getElementById('quantityerror').innerHTML = ""

    }
    if (product.modelName == '') {
      document.getElementById('modelerror').innerHTML = "* Enter the Model Name "
    }
    else {
      document.getElementById('modelerror').innerHTML = "*"

    }
  }
  let postvalue = (e) => {
    e.preventDefault()

    if (product.productName != "" && product.price != 0 && product.quantity != 0 && product.mainImage != null && product.modelName != '') {
      const formData = new FormData()
      let arryid = []
      videoDB.forEach((obj) => {
        product.videoObj.forEach((name) => {
          if (obj.name == name) {
            arryid.push(obj._id)
          }
        })
      })

      formData.append('adminId', admin._id)
      //append mainimage
      for (let i = 0; i < product.mainImage.length; i++) {
        formData.append('mainImage', product.mainImage[i]);
      }
      // Append pictures files
      if (product.pictures != null && product.pictures.length > 0) {
        for (let i = 0; i < product.pictures.length; i++) {
          formData.append('pictures', product.pictures[i]);
        }
      }
      // Append video files
      if (product.video != null && product.video.length > 0) {
        for (let i = 0; i < product.video.length; i++) {
          formData.append('video', product.video[i]);
        }
      }

      if (product.descriptionPoints != null && product.descriptionPoints.length > 0) {
        for (let i = 0; i < product.descriptionPoints.length; i++) {
          formData.append('descriptionPoints', product.descriptionPoints[i]);
        }
      }
      if (arryid != null && arryid.length > 0) {
        for (let i = 0; i < arryid.length; i++) {
          formData.append('videoObj', arryid[i]);
        }
      }
      if (product.clipPaths != null && product.clipPaths.length > 0) {
        for (let i = 0; i < product.clipPaths.length; i++) {
          formData.append('clipPaths', product.clipPaths[i]);
        }
      }
      console.log(arryid);
      for (const key in product) {
        if (key != 'mainImage' && key != 'pictures' && key != 'descriptionPoints' && key != 'clipPaths' && key != 'videoObj')
          formData.append(key, product[key])
      }
      console.log(product.mainImage);
      document.getElementById('producterror').innerHTML = ""
      document.getElementById('priceerror').innerHTML = ""
      document.getElementById('quantityerror').innerHTML = ""
      document.getElementById('mainimageerror').innerHTML = ""

      axios.post(`${hostName}/api/product`, formData).then((response) => {
        console.log(response.data);
        // alert("Product has been added")
        toast.success('Product has successFully added', {
          theme: 'colored',
          position: 'top-center',
          autoClose: 5000
        })
        setProduct({
          productName: "",
          price: 0,
          quantity: 0,
          description: "",
          category: "",
          vatriety: "",
          brand: "",
          clipPaths: [''],
          wholePrice: 0,
          modelName: "",
          sku: '',
          mainImage: null,
          pictures: null,
          video: null,
          descriptionPoints: [
            ''
          ]

        })
        getProduct()
        // window.location.reload()
      }).catch((error) => {
        console.log(error);
        toast.error(error.response.data, {
          theme: 'colored',
          position: 'top-center',
          autoClose: 5000
        })
      })
    }
    else
      validateForm()
  }
  let [filterModel, setFilterModel] = useState()
  useEffect(() => {
    setFilterModel(modelDb)
  }, [modelDb])
  useEffect(() => {
    if (product.brand) {
      let filterData = modelDb.filter((obj) => obj.brandName == product.brand)
      let sortAsending = filterData.sort((a, b) => {
        if (a.modelName > b.modelName) return 1
        if (a.modelName < b.modelName) return -1
        return 0
      })
      console.log(sortAsending);
      setFilterModel(filterData)

    }

  }, [product.brand])
  let navigate=useNavigate()
  return (
    <div className='poppins min-h-[90vh] bg-slate-50'>
      <ToastContainer />
      <NavbarCom />

      <div className='container bg-white mx-auto p-3 rounded '>
        <button onClick={()=>navigate('/product') } className='p-3 flex ms-auto my-3 rounded text-white bg-black '>
          <LeftArrow /> </button>
        <div className='flex justify-between items-center'>
          <p className='fw-medium text-lg'>Create Product</p>
          <button onClick={postvalue} className='rounded bg-violet-600 text-white p-3'>Save</button>
        </div>
        <article className='flex my-4 flex-wrap gap-3 justify-between'>

          <div className=' w-[400px]'>
            Product Name <span id='producterror' className='text-red-600'>*</span>
            <span className='text-blue-500'> {product.brand + " "} {modelDb && product.modelName && modelDb.find((obj) => obj._id == product.modelName).modelName + " "}
              ({varietyDB && product.vatriety && varietyDB.find((obj) => obj._id == product.vatriety).variety}) </span>
            <input value={product.productName} name='productName' onChange={handleChanges} type="text" placeholder='Enter the Product name' style={{ backgroundColor: "#f6f6f6" }} className='p-3 w-full  block my-2 px-3 border-0  rounded outline-none' />
          </div>
          <div className=' w-[400px]'>
            SKU <span id='producterror' className='text-red-600'>*</span>
            <span className='text-blue-600'>{varietyDB && product.vatriety && varietyDB.find((obj) => obj._id == product.vatriety).variety.replace(/\s/g, '').toLowerCase() + '-'}{modelDb && product.modelName && modelDb.find((obj) => obj._id == product.modelName).modelName.replace(/\s/g, '').toLowerCase()}  </span>
            <input value={product.sku} name='sku' onChange={handleChanges} type="text" placeholder='Enter the Product name' style={{ backgroundColor: "#f6f6f6" }} className='p-3 w-full  block my-2 px-3 border-0  rounded outline-none' />
          </div>
          <div className='w-[400px]'>
            Price <span id='priceerror' className='text-red-600'>*</span>
            <input value={product.price} type="number" onChange={handleChanges} name='price' placeholder='Enter the price ' style={{ backgroundColor: "#f6f6f6" }} className='p-3 w-full block my-2 px-3 border-0  rounded outline-none' />
          </div>
          <div className='w-[400px]'>
            MRP <span className=''>(Price before discount) </span>
            <input value={product.wholePrice} type="number" onChange={handleChanges} name='wholePrice' placeholder='Enter the MRP ' style={{ backgroundColor: "#f6f6f6" }} className='p-3 w-full block my-2 px-3 border-0  rounded outline-none' />
          </div>

          <div className='w-[400px] '>
            Quantity <span id='quantityerror' className='text-red-600'>*</span>
            <input value={product.quantity} type="number" onChange={handleChanges} name='quantity' placeholder='Enter the Product name ' style={{ backgroundColor: "#f6f6f6" }} className='p-3 block w-full my-2 px-3 border-0  rounded outline-none' />
          </div>
          <div className='w-[400px]'>
            Category
            <select value={product.category} onChange={handleChanges} name='category' type="text" placeholder='Enter the Product name' style={{ backgroundColor: "#f6f6f6" }} className='p-3 w-full  block my-2 px-3 border-0  rounded outline-none' >
              <option value="">Select category</option>
              {
                categoryDB != undefined ? <>{
                  categoryDB.map((x) => {
                    return (
                      <option value={x.category}>{x.category} </option>
                    )
                  })
                } </> : ""
              }
            </select>
          </div>
          <div className='w-[400px]'>
            Brand
            <select value={product.brand} name='brand' onChange={handleChanges} placeholder='Enter the Product name '
              style={{ backgroundColor: "#f6f6f6" }} className='p-3 w-full block my-2 px-3 border-0  rounded outline-none' >
              <option value={null}>select brand</option>
              {
                brandDB != undefined ? <>{
                  brandDB.map((x) => {
                    return (
                      <option value={x.brand}>{x.brand} </option>
                    )
                  })
                } </> : ""
              }
            </select>
          </div>
          <div className=' w-[400px]'>
            Model Name <span id='modelerror' className='text-red-600'>*</span>
            {/* <input value={product.modelName} type="text" onChange={handleChanges} name='modelName' placeholder='Enter the Model name ' 
            style={{ backgroundColor: "#f6f6f6" }} className='p-3 block w-full my-2 px-3 border-0  rounded outline-none' />
           */}
            <select value={product.modelName} name='modelName' onChange={(e) => {
              handleChanges(e);
              setmodelobj(modelDb.find((obj) => obj._id == e.target.value))
            }} type="text"
              placeholder='Enter the Model name' style={{ backgroundColor: "#f6f6f6" }}
              className='p-3 w-full  block my-2 px-3 border-0  rounded outline-none' >
              <option value="">Select model</option>
              {
                filterModel && filterModel.map((x) => {
                  return (
                    <option value={x._id}>{x.brandName} {x.modelName} </option>
                  )
                })
              }
            </select>
          </div>
          <div className='w-[400px] '>
            Variety
            <select value={product.vatriety} onChange={handleChanges} name='vatriety' type="text" placeholder='Enter the Product name' style={{ backgroundColor: "#f6f6f6" }} className='p-3 w-full  block my-2 px-3 border-0  rounded outline-none' >
              <option value="">Select variety</option>
              {
                varietyDB != undefined ? <>{
                  varietyDB.map((x) => {
                    return (
                      <option value={x._id}>{x.variety} </option>
                    )
                  })
                } </> : ""
              }
            </select>
          </div>
          {/* Clipath */}
          <div className='w-[400px] relative '>
            Mask Image <span id='modelerror' className='text-red-600'>*</span>
            <button onClick={() => {
              if (product.clipPaths && product.clipPaths.length < 2) {
                setProduct((prev) => ({
                  ...prev,
                  clipPaths: [...prev.clipPaths, '']
                }))
              }
              else if (product.clipPaths == undefined) {
                setProduct((prev) => ({
                  ...prev,
                  clipPaths: ['']
                }))
              }
              else {
                toast.warning('Mask Image reached MAX of 2 Images')
              }
            }} className='absolute right-0'> <PlusIcon /> </button>
            {
              product.clipPaths && product.clipPaths.map((obj, index) => {
                return (
                  <div style={{ backgroundColor: "#f6f6f6" }}
                    className='p-3 w-full my-2 px-3 border-0 flex items-center justify-between rounded'>
                    {obj}
                    {/* <input disabled type="text" name='clipPaths' placeholder={`Create the Clipath ${index+1}`}
                      style={{ backgroundColor: "#f6f6f6" }} className=' outline-none' /> */}
                    <button onClick={() => { setShowPath(true); setClipPathIndex(index) }} className=''> Create MaskImage </button>
                    {product.clipPaths.length > 1 && <button onClick={() => {
                      setProduct((prev) => ({
                        ...prev,
                        clipPaths: [...prev.clipPaths].filter((obj, index1) => index1 != index)
                      }))
                    }} className='text-red-500'><DustBin /> </button>}
                  </div>
                )
              })
            }

          </div>
          {/* Template */}
          {/* <div className='w-[400px] '>
            Template <span id='templateerror' className='text-red-600'>*</span>
            <select value={product.template}  onChange={(e) => {
              handleChanges(e);
              setTemplateobj(templatesDB.find((obj) =>obj._id==e.target.value ))
            }} name='template' type="text" placeholder='Enter the Product name' style={{ backgroundColor: "#f6f6f6" }} className='p-3 w-full  block my-2 px-3 border-0  rounded outline-none' >
              <option value="">Select template</option>
              {
                templatesDB != undefined ? <>{
                  templatesDB.map((x) => {
                    return (
                      <option value={x._id}>{x.templateName} </option>
                    )
                  })
                } </> : ""
              }
            </select>
          </div> */}



          <div className='w-[400px] '>
            Videos :  <span id='vioderror' className='text-red-600' ></span>
            <select name="" id="" onChange={(e) => {
              const selectedValue = e.target.value;
              let newArry;
              if (product.videoObj.includes(selectedValue)) {
                newArry = product.videoObj.filter(obj => obj !== selectedValue);
              } else {
                newArry = [...product.videoObj, selectedValue];
              }
              setProduct((prev) => ({
                ...prev,
                videoObj: newArry
              }))
              console.log(newArry);
            }}
              style={{ backgroundColor: "#f6f6f6" }} className='p-3 w-full block my-2 px-3 border-0  rounded outline-none' >
              <option value="">select</option>
              {
                videoDB && videoDB.map((obj) => (
                  <option value={obj.name}> {obj.name} </option>
                ))
              }
            </select>
            <div className='flex flex-wrap gap-2 '>
              {
                product.videoObj && product.videoObj.map((vdobj) => (
                  <p className='p-2 px-4 rounded bg-slate-50 relative'>{vdobj}
                    <span onClick={() => {
                      setProduct((prev) => ({
                        ...prev,
                        videoObj: prev.videoObj.filter((o) => o != vdobj)
                      }))
                    }} className='border-1 p-1 right-1 rotate-45 border-red-600 text-red-600 cursor-pointer top-1 rounded-full absolute  '><PlusIcon size={10} /> </span> </p>
                ))
              }

            </div>
          </div>
          <div className='w-[400px] '>
            Main Image <span id='mainimageerror' className='text-red-600'>* </span>
            <input name='mainImage' onChange={handleChanges} type="file" placeholder='Enter the Product name ' style={{ backgroundColor: "#f6f6f6" }} className='p-3 block w-full my-2 px-3 border-0  rounded outline-none' />
          </div>
          <div className='w-[400px] '>
            Sub Images <span id='suberror' className='text-red-600'></span>
            <input name='pictures' onChange={handleChanges} type="file" multiple style={{ backgroundColor: "#f6f6f6" }}
              className='p-3 block w-full my-2 px-3 border-0  rounded outline-none' />
          </div>


          {/* Show Privew */}
          {modelobj && templateobj && <div className='w-[400px] flex items-center' >
            <button onClick={() => setShowPreview(true)} className='rounded text-white p-2 px-3 bg-blue-600'> Show Preview</button>

          </div>}
        </article>
        <div className=' w-[400px] ' >
          <p>Description points </p>
          <div className=' flex gap-2 items-start w-full' >
            <div className='w-full '>
              {
                product.descriptionPoints && product.descriptionPoints.map((x, index) => (
                  <div style={{ backgroundColor: "#f6f6f6" }}
                    className='p-3 w-full  my-2 px-3 border-0 flex justify-between rounded'>

                    <input type="text" name='description' value={x}

                      className='bg-transparent outline-none'
                      onChange={(e) => {
                        setProduct((prev) => {
                          const desarry = [...prev.descriptionPoints]
                          desarry[index] = e.target.value
                          return {
                            ...prev,
                            descriptionPoints: desarry
                          }
                        })
                      }} />
                    <button onClick={() => {
                      setProduct((prev) => {

                        const arry = prev.descriptionPoints
                        const newarry = arry.filter((y, index1) => index != index1)
                        return {
                          ...prev,
                          descriptionPoints: newarry
                        }
                      })
                    }} className='text-red-600  '>
                      <DustBin />
                    </button>
                  </div>
                ))
              }
            </div>
            <button onClick={() => setProduct((prev) => {
              const desarry = [...prev.descriptionPoints]
              desarry.push('')
              return {
                ...prev,
                descriptionPoints: desarry
              }
            })} className=''><PlusIcon /> </button>
          </div>
        </div>
        <div className=''>
          <p>Description</p>
          <textarea value={product.description} style={{ backgroundColor: "#f6f6f6" }} name="description"
            onChange={handleChanges} placeholder='Enter the Description for the Product'
            className='border-slate-100 outline-none w-full border-1 p-3' id="" cols="30" rows="10"></textarea>

        </div>
      </div>
      {showPreivew &&
        <Modal size='xl' centered show={showPreivew} onHide={() => setShowPreview(false)}>
          <Modal.Header closeButton>
            <h4>Privew of the Editable Layout For the Product</h4>
          </Modal.Header>
          <Modal.Body>
            <PreviewModel modelData={modelobj} templateData={templateobj} />
          </Modal.Body>

        </Modal>
      }
      <ClipPath index={clipPathIndex} product={product} clipId={product.clipPaths != undefined ? product.clipPaths._id != undefined : null} setProduct={setProduct} show={showClipath} setShowPath={setShowPath} />
    </div>
  )
}


export default AddProduct