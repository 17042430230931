import React, { useContext, useEffect, useState } from 'react'
import NavbarCom from '../../Components/Navbar'
import { useNavigate, useParams } from 'react-router'
import axios from 'axios'
import { hostName } from '../../App'
import { StateStore } from '../../Context/StateProvider'

const ParticularOrder = () => {
    const { downloadImage, getOrders } = useContext(StateStore)
    const { id } = useParams()
    const [orderDetails, setOrderDetails] = useState()
    const [productDetails, setProductDetails] = useState()
    const [shippingAddress, setShippingAddress] = useState()
    let particularDetails = () => {
        axios.get(`${hostName}/api/particularOrder/${id}`).then((response) => {
            // console.log(response.data);
            setOrderDetails(response.data)
            setProductDetails(response.data.productDetails)
            setShippingAddress(response.data.shippingAddress)
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        particularDetails()
    }, [id])
    let navigate = useNavigate()
    let handleStatusChange = (value, id) => {
        axios.put(`${hostName}/api/orderStatus`, {
            uid: id,
            status: value
        }).then((response) => {
            console.log(response.data);
            getOrders()
            particularDetails()
        }).catch((error) => {
            console.log(error);
        })
    }
    return (
        <div className='poppins'>
            <NavbarCom />
            {orderDetails && <div className='container mx-auto '>
                <button onClick={() => navigate('/orders')} className='p-2 px-3 bg-black text-white rounded  ms-auto flex '>Back </button>

                <h3>Order Details </h3>
                <div className='flex flex-wrap gap-3 justify-between'>
                    <p className='text-lg fw-semibold'>Order Id : <span className='text-slate-400 fw-normal text-base '>{orderDetails.orderId} </span></p>

                    <p className='text-lg fw-semibold'>Amount Paid : <span className='text-slate-400'>{orderDetails.amountPaid} </span></p>
                    <p className=' fw-semibold'>
                        Shipping Address :
                        {shippingAddress &&
                            <>
                                <span className='text-slate-500 fw-normal'>{shippingAddress.fullName}</span><br />
                                <span className='text-slate-500 fw-normal'> {shippingAddress.line1}</span><br />
                                <span className='text-slate-500 fw-normal'>{shippingAddress.line2 + ' ,' + shippingAddress.district} </span><br />
                                <span className='text-slate-500 fw-normal'>{shippingAddress.state + '-' + shippingAddress.pincode}</span> <br />
                                <span className='text-slate-500 fw-normal'>Phone:  {shippingAddress.phone}</span><br />
                            </>
                        }
                    </p>
                    <p className='texl-lg fw-semibold'>Total Quantity : <span className='text-slate-500'>{orderDetails.totalQuantity} </span> </p>
                    <p className='texl-lg fw-semibold'>Status : <span className='text-slate-500'>

                        {/* {x.status}  */}
                        <select onChange={(e) => handleStatusChange(e.target.value, orderDetails._id)} className='bg-transparent outline-none ' name="" value={orderDetails.status} id="">
                            {
                                [
                                    'order placed',
                                    'process',
                                    'shipped',
                                    'out for delivery',
                                    'delivered'
                                ].map((x) => {
                                    return (
                                        <option value={x}>{x}</option>
                                    )
                                })
                            }</select>
                    </span> </p>
                </div>
                {productDetails && productDetails.map((x, index) => {
                    console.log(x);
                    return (
                        <div className='p-3 border-1 rounded'>
                            <p className='fw-semibold text-lg'>Product Details  </p>

                            <div className='flex flex-wrap gap-3 '>
                                <div key={index} className='h-fit'>
                                    <p className='fw-semibold'> Priview Image :</p>
                                    {
                                        x.previewImage && x.previewImage.map((img,index)=>(

                                        
                                    <a href={img} download={`${orderDetails.orderId.slice(6)}_${x.productId && x.productId.productName}_Privewimage${index+1}.png`}>
                                        <img src={img} className='' width={200} alt="Privew Image" />
                                    </a>))
                                    }
                                </div>
                                <div className='h-fit'>
                                    User Uploaded Images : <span className='block'>(Click the Image to dowload) </span>
                                    {
                                        x.uploadedImage && x.uploadedImage.map((y, index1) => {
                                            return (
                                                <img src={y} onClick={() => downloadImage(y, `${orderDetails.orderId.slice(6)}_${x.productId && x.productId.productName}_Upload_${index1 + 1}`)} className='cursor-pointer m-2' alt="User Uploads" width={150} />
                                            )
                                        })
                                    }
                                </div>
                                {/* Text Details */}
                                <div className='h-fit'>
                                    {x.textDetails && x.textDetails.length > 0 && <p className='fw-semibold text-lg'>   Text Details</p>}
                                    {
                                        x.textDetails && x.textDetails.map((y, index) => {
                                            return (
                                                <div key={index}>
                                                    <p className='fw-semibold '>Details of text {index + 1}</p>
                                                    <p>Text : <span>{y.text} </span></p>
                                                    <p>Font Family : <span>{y.fontFamily} </span></p>
                                                    <p>Font Color : <span>{y.color} </span></p>
                                                    <p>Font SIze : <span>{y.size} </span></p>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                                {/* Message */}
                                <p className='fw-semibold h-fit' >Message from the user : <span className='fw-normal block'>{x.message}</span> </p>
                                {/* Quantity */}
                                <p className='fw-semibold h-fit' >Quantity on this Style : <span className='fw-normal'>{x.quantity}</span> </p>
                                {/* Product details */}
                                <p className='fw-semibold h-fit' >Product Name : <span className='fw-normal block'>{x.productId ? x.productId.productName : 'Product deleted'}</span> </p>

                            </div>
                        </div>)
                })}
            </div>}

        </div>
    )
}

export default ParticularOrder