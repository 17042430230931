import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { hostName } from '../../App'
import { toast } from 'react-toastify'
import { StateStore } from '../../Context/StateProvider'
import PlusIcon from '../../Svg_Icons/PlusIcon'
import BestSellersProducts from '../../Components/BestSellersProducts'
import LeftArrow from '../../Svg_Icons/LeftArrow'
import { useNavigate } from 'react-router'

const BestSellers = () => {
    const { bestselllerDB, setbestsellerDB, getBestSellers } = useContext(StateStore)
    const [nonbs, setnonBS] = useState([])
    const [id, setid] = useState()
    const getNonbestsellers = () => {
        axios.get(`${hostName}/api/bestseller/false`).then((response) => {
            setnonBS(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        getNonbestsellers()
        getBestSellers()
    }, [])
    let handleSubmit = () => {
        axios.post(`${hostName}/api/bestseller/${id}`).then((response) => {
            getNonbestsellers()
            getBestSellers()
            toast.success('Product marked as a BestSeller', {
                autoClose: 3000,
                theme: 'colored',
                position: 'top-center'
            })
            setid('')
        }).catch((error) => {
            console.log(error);
        })
    }
    let navigate=useNavigate()
    return (
        <div className=' p-3'>
            <button onClick={()=>navigate('/features')} className='p-2 rounded bg-black text-white my-2 ms-auto flex '><LeftArrow/> </button>
            <h4>Make the Product as the Best Seller</h4>
            Select the product :
            <select value={id} onChange={(e) => setid(e.target.value)} className='outline-none p-2 rounded bg-slate-50 mx-2' name="" id="">
                <option value="">Select</option>
                {
                    nonbs && nonbs.map((x, index) => {
                        return (
                            <option key={index} value={x._id}>{x.productName} </option>
                        )
                    })
                }

            </select>
            <button onClick={handleSubmit} className='block p-2 px-3 my-2 bg-slate-500 text-white rounded'>Submit </button>
            {/* <h4>Best Sellers </h4>
            <div className={` flex gap-3 ${bestselllerDB && bestselllerDB.length > 0 && 'justify-between'} flex-wrap `}>

                {
                    bestselllerDB && [...bestselllerDB].map((x, index) => {
                        console.log(x);
                        return (
                            <div className={`w-[12rem] h-[15rem] border-1 relative rounded `} key={index}>
                               {bestselllerDB.length>5&& <button className={` absolute rotate-45 text-red-500 border-1 border-red-500 rounded-full top-2 right-2 `}>
                                   <PlusIcon/>  </button>}
                                <img src={x.mainImage} className='h-[12rem] object-contain mx-auto rounded' alt="Best Sellers" />
                                <p className='text-center'>{x.productName} </p>


                            </div>
                        )
                    })
                }
            </div> */}
            <BestSellersProducts/>
        </div>
    )
}

export default BestSellers